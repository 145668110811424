import { getPaginationString, getParamString } from '@utils';
import { ArgumentsCompaniesApi, ArgumentsCompaniesRolesByIdApi } from '../../args';
import { IApiPath } from '@interfaces';

export const companiesApi = ({ pagination, ...params }: ArgumentsCompaniesApi) =>
  `companies/?sorting=name&${getParamString(params)}${getPaginationString(pagination)}`;

export const companiesRolesByIdApi = ({
  pagination,
  role_id,
  ...params
}: ArgumentsCompaniesRolesByIdApi) => `companies/roles/${role_id}/?${getParamString(params)}`;

export const companiesRoleApi = ({ pagination, ...params }: IApiPath) =>
  `companies/roles/?${getParamString(params)}${getPaginationString(pagination)}`;
