import { useState } from 'react';

import { ControllerInterface } from './interface';
import { getArrayFromObject } from '@utils';
import { serviceOrderStatusMap } from '@constants';
import { useStringFieldModel } from '@models';

export const useServiceOrderField = (
  initialValue: string,
  fieldKey: string,
): ControllerInterface => {
  const [statusValue, setStatusValue] = useState(initialValue);

  const getOptions = () => {
    if (fieldKey === 'status') {
      return getArrayFromObject(serviceOrderStatusMap, 'value', 'label') as {
        value: string;
        label: string;
      }[];
    }
    return [];
  };

  const providerStatusField = useStringFieldModel({
    initValue: initialValue || '',
  });

  const providerServiceCostField = useStringFieldModel({
    initValue: initialValue || '',
  });

  const transactionIdField = useStringFieldModel({
    initValue: initialValue || '',
  });

  const providerOrderIdField = useStringFieldModel({
    initValue: initialValue || '',
  });

  return {
    options: getOptions(),
    statusValue,
    setStatusValue,
    providerStatusField,
    providerServiceCostField,
    transactionIdField,
    providerOrderIdField,
  };
};
