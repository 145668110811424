import React, { FC } from 'react';
import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import * as Controller from './controller';
import {
  BorrowerInformation,
  ButtonWithTooltip,
  ConfirmationModal,
  Stepper,
  SuccessModal,
} from '@components';
import { General, LoanDetails, PropertyDetails } from './components';
import { colors } from '@theme';
import { ArrowBackIcon } from '@svgAsComponents';
import { PopupTypeEnum } from '@interfaces';
import { LoadingButton } from '@mui/lab';

const NewProjectV2: FC = () => {
  const {
    activeStep,
    steps,
    nextStep,
    prevStep,
    goBack,
    isSubmitting,
    mainRef,
    generalFields,
    loanDetailsFields,
    borrowerDetailsFields,
    getLeavePageConfirmModal,
    error,
    setError,
    borrowerEquityLocal,
    isNewBorrower,
    setIsNewBorrower,
    isProductionBuildProject,
    setIsProductionBuildProject,
    borrowerUser,
    setBorrowerUser,
    createProjectWithoutBorrowerModal,
    editPaymentConfigurationData,
    inviteBorrowers,
    setInviteBorrowers,
    loan_servicing_statuses,
    loan_types,
    propertyDetails,
    propertyRows,
    setPropertyRows,
    updateProject,
    handleProjectCreationClick,
    projectId,
    unitsNumber,
    openSuccessModal,
    setOpenSuccessModal,
    isCreateByModels,
  } = Controller.useCreateProject();

  const stepperButtons = () => (
    <>
      <Button variant="text" disabled={activeStep === 0} onClick={prevStep} sx={{ mr: 1 }}>
        Back
      </Button>
      {activeStep === 0 && !projectId && (
        <LoadingButton
          loading={isSubmitting}
          variant="new"
          color="secondary"
          onClick={handleProjectCreationClick}
          data-cy="project__create__add_button"
        >
          Add project
        </LoadingButton>
      )}
      {activeStep === steps.length - 1 && (
        <ButtonWithTooltip
          onClick={
            borrowerUser?.id || isNewBorrower
              ? nextStep
              : createProjectWithoutBorrowerModal.askConfirm
          }
          dataTestName="project__create__submit__button"
          loading={isSubmitting}
        >
          {isSubmitting ? 'Updating...' : 'Update project'}
        </ButtonWithTooltip>
      )}

      {activeStep !== steps.length - 1 && (activeStep !== 0 || projectId) && (
        <LoadingButton
          loading={isSubmitting}
          variant="new"
          color="secondary"
          onClick={nextStep}
          data-cy="project__create__next_button"
        >
          Next
        </LoadingButton>
      )}
    </>
  );

  return (
    <Stack direction="column" sx={{ height: '100%', scrollMargin: '64px' }} ref={mainRef}>
      <Stack sx={{ p: 2 }} direction="row" alignItems="center">
        <IconButton onClick={goBack} data-cy="project__create__go_back_button">
          <ArrowBackIcon />
        </IconButton>
        <Typography sx={{ ml: 2 }} variant="h1">
          Add new project
        </Typography>
      </Stack>
      <Stepper activeStep={activeStep} steps={steps} buttons={stepperButtons()}>
        <Box
          sx={{
            backgroundColor: colors.background.gray,
            flex: 1,
            ml: -1.5,
            mr: -1.5,
            mt: 2,
            p: 3,
          }}
        >
          {activeStep === 0 && (
            <General
              generalFields={generalFields}
              isProductionBuildProject={isProductionBuildProject}
              setIsProductionBuildProject={setIsProductionBuildProject}
            />
          )}
          {activeStep === 1 && (
            <PropertyDetails
              propertyDetails={propertyDetails}
              isCreateByModels={isCreateByModels}
              isProductionBuildProject={isProductionBuildProject}
              propertyRows={propertyRows}
              setPropertyRows={setPropertyRows}
              unitsNumber={+unitsNumber}
            />
          )}
          {activeStep === 2 && (
            <LoanDetails
              loanDetailsFields={loanDetailsFields}
              borrowerEquityLocal={borrowerEquityLocal}
              editPaymentConfigurationData={editPaymentConfigurationData}
              loan_servicing_statuses={loan_servicing_statuses}
              loan_types={loan_types}
            />
          )}
          {activeStep === 3 && (
            <BorrowerInformation
              borrowerDetailsFields={borrowerDetailsFields}
              borrowerUser={borrowerUser}
              setBorrowerUser={setBorrowerUser}
              isNewBorrower={isNewBorrower}
              setIsNewBorrower={setIsNewBorrower}
              inviteBorrowers={inviteBorrowers}
              setInviteBorrowers={setInviteBorrowers}
            />
          )}
        </Box>
      </Stepper>
      {getLeavePageConfirmModal()}
      <ConfirmationModal
        title="Create project error"
        text={`There was a problem creating a project.
        ${error}`}
        hideConfirmButton
        open={Boolean(error)}
        onClose={() => setError(null)}
        type={PopupTypeEnum.ERROR}
      />
      <ConfirmationModal
        open={createProjectWithoutBorrowerModal.isConfirmModalOpened}
        title="Confirmation"
        text="You want to add project without Borrower company."
        onClose={createProjectWithoutBorrowerModal.closeConfirmModal}
        confirmCallback={updateProject}
        type={PopupTypeEnum.CONFIRMATION}
      />
      {openSuccessModal && (
        <SuccessModal
          title="Project added"
          open={openSuccessModal}
          text="Project has been added successfully."
          onClose={() => setOpenSuccessModal(false)}
        />
      )}
    </Stack>
  );
};

export default NewProjectV2;
