import React, { useState, useMemo, useEffect } from 'react';
import { Button, Typography, CircularProgress, Stack, Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { useFormik } from 'formik';
import { emailValidationSchema, getErrorText } from '@utils';
import { InputField, LoginWireframe } from '@components';
import { BackToLoginButton, ThirdPartyLoginButtons } from '../components';
import * as Controller from './controller';
import { ErrorDual } from '@interfaces';
import FirstTimeAccess from '@assets/first_time_access.svg';

const ForgotPasswordComponent = () => {
  const { handleForgotPassword } = Controller.useForgotPassword();
  const [emailSent, setEmailSent] = useState(false);
  const [initialEmail, setInitialEmail] = useState('');
  const [searchParams] = useSearchParams();
  const set_password_email = searchParams.get('set_password_email');

  useEffect(() => {
    if (set_password_email) {
      setInitialEmail(decodeURIComponent(set_password_email));
      formik.setFieldValue('email', decodeURIComponent(set_password_email));
    }
  }, [set_password_email]);

  const formik = useFormik({
    initialValues: { email: initialEmail },
    validationSchema: emailValidationSchema,
    onSubmit: async (values) => {
      try {
        await handleForgotPassword(values.email.trim());
        setEmailSent(true);
      } catch (error) {
        formik.setFieldError('email', getErrorText(error as ErrorDual));
      }
    },
  });

  const getTitle = useMemo(() => {
    if (emailSent) {
      return <Typography variant="h1">All set!</Typography>;
    }

    return (
      <Typography variant="h1">
        {initialEmail ? 'First-time access' : 'Forgot your password?'}
      </Typography>
    );
  }, [emailSent, initialEmail]);

  const getFormContent = useMemo(() => {
    if (emailSent) {
      return (
        <Typography variant="body2" sx={{ mt: '16px' }}>
          {initialEmail
            ? 'You will receive an email with instructions on your next steps. See you soon!'
            : 'If your email is associated with an account on file, you will receive an email with instructions on your next steps. See you soon!'}
        </Typography>
      );
    }

    if (initialEmail)
      return (
        <>
          <Typography variant="body2" sx={{ mt: 2 }}>
            This is your first time accessing TrustPoint directly.
          </Typography>
          <Typography variant="body2" sx={{ mt: 3 }}>
            Get the link on your email <b>{initialEmail}</b> and proceed with setting up your
            password.
          </Typography>
        </>
      );
    return (
      <>
        <Typography variant="body2" sx={{ mt: '16px' }}>
          Enter the email address associated with your account.
        </Typography>
        <InputField name="email" label="Email" type="email" variant="outlined" size="small" />
      </>
    );
  }, [emailSent, initialEmail]);

  const getFormButtons = useMemo(() => {
    if (emailSent) {
      return (
        <>
          <BackToLoginButton variant="contained" />
          <Box mt={2} />
          <ThirdPartyLoginButtons />
        </>
      );
    }

    return (
      <Stack spacing={2}>
        <Button type="submit" fullWidth disabled={formik.isSubmitting}>
          {formik.isSubmitting ? (
            <CircularProgress color="primary" size={20} />
          ) : initialEmail ? (
            'Get link'
          ) : (
            'Request reset link'
          )}
        </Button>
        <BackToLoginButton />
        <Box mt={2} />
        <ThirdPartyLoginButtons />
      </Stack>
    );
  }, [emailSent, formik, initialEmail]);

  return (
    <>
      {initialEmail && (
        <Stack justifyContent="center" alignItems="center" sx={{ mb: 4 }}>
          <img src={FirstTimeAccess} style={{ width: '80px' }} alt="first time access" />
        </Stack>
      )}

      <LoginWireframe
        formik={formik}
        getTitle={getTitle}
        getFormContent={getFormContent}
        getFormButtons={getFormButtons}
      />
    </>
  );
};

export default ForgotPasswordComponent;
