import React, { useContext } from 'react';
import { useIsMutating } from 'react-query';
import { Stack, Typography } from '@mui/material';

import { ButtonWithTooltip, Fees, StyledBox, TabWrapperWithLeaveWarning } from '@components';
import * as Controller from './controller';
import { SettingsContext } from '@context';
import { getTooltipText } from '@utils';

const Payments = ({ project }) => {
  const isMutating = useIsMutating();
  const { isCurrentProjectArchived } = useContext(SettingsContext);
  const controller = Controller.usePayments({ project });
  const { exitPath, isSubmitting, handleSubmitClick, feesController, isUpdated, canEditFees } =
    controller;

  const { addFee, fees, deleteFee, updateFee } = feesController;

  return (
    <TabWrapperWithLeaveWarning
      onSave={handleSubmitClick}
      tabValue="payments"
      path={exitPath}
      isSubmitting={isSubmitting}
      currentPagePathname={'/projects/*/settings/payments'}
      isUpdated={isUpdated}
    >
      <Stack spacing={2}>
        <Stack sx={{ pb: 3 }}>
          <StyledBox>
            <Stack direction="row" justifyContent="space-between" align-items="center">
              <Typography variant="h3">Fees</Typography>
              {canEditFees && (
                <ButtonWithTooltip
                  tooltipText={getTooltipText({ isCurrentProjectArchived })}
                  disabled={isCurrentProjectArchived || Boolean(isMutating)}
                  onClick={addFee}
                  size="small"
                  dataTestName="project__settings__fees__add__button"
                >
                  Add fee
                </ButtonWithTooltip>
              )}
            </Stack>
            <Stack
              spacing={2}
              sx={{
                width: { md: '50%', xs: '100%' },
                pt: 3,
              }}
            >
              <Fees
                fees={fees}
                deleteFee={deleteFee}
                updateFee={updateFee}
                canEdit={canEditFees}
                source="project__settings"
              />
            </Stack>
          </StyledBox>
        </Stack>
      </Stack>
    </TabWrapperWithLeaveWarning>
  );
};

export default Payments;
