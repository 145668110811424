import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import MilestoneColumns, { MilestoneListColumnType } from './columns';
import { useColumnFilterV2, useCommentsAndDocumentsPreview, useRightMenu } from '@hooks';
import { PermissionsContext, SettingsContext } from '@context';
import {
  HookInterface,
  ITableRightDrawer,
  TableContextInterface,
  UpdateRequestedChangesArgs,
} from './interface';
import {
  currencyFormatter,
  formatError,
  getMilestoneGroupsTagsIds,
  getSortedByIndexMilestones,
  isCreditInRequest,
  isDrawRequest,
  isAllowed,
} from '@utils';
import {
  IDrawRequest,
  IMilestone,
  IMilestoneColumn,
  IMilestoneTotal,
  IProject,
  IProjectMilestone,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
  QueryNamesEnums,
  RequestTableTabsEnum,
  RequestTableTabTypesEnum,
  TableKeyEnum,
} from '@interfaces';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  getDrawRequest,
  getDrawRequestMilestoneListColumns,
  getProject,
  getProjectMilestoneListColumns,
  getProjectMilestonesList,
} from '@globalService';
import { ApiUpdatePayload } from './columns/common';
import { commentsPreviewQueryFields } from '@constants';
import { CommonRowType } from '@interfaces';
export const TableContext = createContext<TableContextInterface>({ getLineItemError: () => '' });

export const useMilestoneList = ({
  tableKey,
  milestones,
  initColumns,
  withProgress,
  patchMilestone,
  withColumnIndication,
  refetch,
  resetMutation,
  requestStatus,
  deleteMilestone,
  openEditMilestoneModal,
  contingencyMode,
  onExpandTable,
  source,
  withCredit,
  groupByFilter,
}: HookInterface) => {
  const tableRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const { projectId, requestId, inspectionId } = useParams();
  const { isPHBProject } = useContext(SettingsContext);

  const [rightDrawerParams, setRightDrawerParams] = useState<{
    milestoneId?: string;
    tab: RequestTableTabsEnum;
    type?: RequestTableTabTypesEnum;
    creditAvailable?: boolean;
    requestId?: string;
    inspectionId?: string;
  }>({
    milestoneId: '',
    tab: RequestTableTabsEnum.COMMENTS,
    creditAvailable: false,
    requestId,
    inspectionId,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    milestoneId: rightDrawerParams.milestoneId,
    drawRequestId: requestId,
    inspectionId,
  });
  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: !isPHBProject && updateCommentsPreviewInfo,
  });

  const [highlightCells, setHighlightCells] = useState<Record<number, Record<number, boolean>>>({});
  const [cellTooltipTexts, setCellTooltipTexts] = useState<
    Record<number, Record<number, { open: boolean; text: string }>>
  >({});
  const [activeCell, setactiveCell] = useState<{
    column: number | null;
    row: number | null;
  }>({ column: -1, row: -1 });

  const updateRightDrawer =
    ({
      milestoneId,
      milestoneName,
      tab,
      type,
      milestoneSubmitId,
      creditAvailable,
    }: ITableRightDrawer) =>
    () => {
      handleRightDrawerOpenerClick({ title: milestoneName });
      setRightDrawerParams({
        tab,
        type,
        creditAvailable,
        ...(milestoneId ? { milestoneId } : {}),
        ...(milestoneSubmitId ? { milestoneSubmitId } : {}),
        ...(inspectionId ? { inspectionId } : {}),
        ...(requestId ? { requestId } : {}),
      });
    };

  const projectQuery = useQuery<IProject, Error>(
    [QueryNamesEnums.GET_PROJECT, { projectId }],
    getProject.bind(this, projectId),
  );

  const query = commentsPreviewQueryFields;
  const commentsPreviewQuery = useQuery<
    { results: Partial<IProjectMilestone>[]; totals: IMilestoneTotal },
    Error
  >(
    [QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId, query, filterKey: 'all' }],
    getProjectMilestonesList.bind(this, { projectId, query, filterKey: 'all' }),
    { enabled: Boolean(milestones?.length) && !isPHBProject },
  );
  const commentsPreviewInfo = useMemo(() => {
    const result = {};
    if (commentsPreviewQuery.data?.results?.length) {
      commentsPreviewQuery.data?.results.forEach(
        (item) => (result[item.id] = item.comments_preview),
      );
    }
    return result;
  }, [commentsPreviewQuery?.data?.results]);

  const drawRequestErrorsQuery = useQuery<IDrawRequest, Error>(
    [
      QueryNamesEnums.GET_DRAW_REQUEST,
      {
        projectId,
        drawRequestId: requestId,
        ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
        query: '{errors}',
      },
    ],
    getDrawRequest.bind(this, {
      projectId,
      drawRequestId: requestId,
      ...(groupByFilter ? { groupByKeys: groupByFilter } : {}),
      query: '{errors,type,credit_amount}',
    }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const milestoneColumnsQuery = requestId
    ? useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS, { projectId, requestId }],
        getDrawRequestMilestoneListColumns.bind(this, projectId, requestId),
        { enabled: withColumnIndication },
      )
    : useQuery<IMilestoneColumn[], Error>(
        [QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS, { projectId }],
        getProjectMilestoneListColumns.bind(this, projectId),
        { enabled: withColumnIndication },
      );

  const { permissions } = useContext(PermissionsContext);

  const permittedColumns = useMemo(
    () =>
      initColumns.filter((item: string) => {
        const column = MilestoneColumns[item];
        if (!column) return false;
        if (!column.permissionKey) return true;
        return isAllowed(column.permissionKey, permissions);
      }),
    [permissions, initColumns],
  );

  const { hiddenColumns, changeFieldVisibility, isColumnFilterUpdating } = useColumnFilterV2(
    tableKey || TableKeyEnum.REQUEST_LINE_ITEMS,
  );

  const columns = useMemo<Array<MilestoneListColumnType>>(
    () => permittedColumns.map((item: string) => MilestoneColumns[item]),
    [permittedColumns],
  );

  const apiUpdate = useCallback(
    async (props: ApiUpdatePayload) => {
      if (props.value === null || (!props.isNonNumber && isNaN(+props.value))) return;
      if (!props.isEqualInit) {
        await patchMilestone({
          milestone: props.milestoneId,
          json: { [props.name]: props.value },
          ...props,
        });
      }
      setCellTooltipTexts((data) => {
        delete data[props.milestoneId];
        return data;
      });
    },
    [patchMilestone],
  );
  const sortedMilestones: CommonRowType[] | ProductionBuildCommonRowType[] = useMemo(() => {
    const sortedMs = isPHBProject
      ? milestones || []
      : getSortedByIndexMilestones(milestones as IMilestone[]) || [];
    return [{}, ...sortedMs, {}];
  }, [milestones, isPHBProject]);

  const getCoordinatesByKey = (milestoneId: string, key: string) => {
    const x = renderColumns.findIndex((item) => item.name === key);
    const y = sortedMilestones.findIndex((item) => item?.id === milestoneId);
    return { x, y };
  };

  const highLightCellByData = (milestoneId: string, key: string) => {
    const { x, y } = getCoordinatesByKey(milestoneId, key);
    setHighlightCells((data) => ({
      ...data,
      [y]: {
        ...data[y],
        [x]: true,
      },
    }));
    setTimeout(() => {
      setHighlightCells((data) => ({
        ...data,
        [y]: {
          ...data[y],
          [x]: false,
        },
      }));
    }, 5000);
  };

  const drawRequestErrors = useMemo(() => {
    const transformedObject = {};
    if (!drawRequestErrorsQuery.data?.errors) return transformedObject;

    Object.keys(drawRequestErrorsQuery.data.errors).forEach((key) => {
      transformedObject[key] = {};
      drawRequestErrorsQuery.data.errors[key]?.forEach((item) => {
        transformedObject[key][item.id] = item.error;
      });
    });

    return transformedObject;
  }, [drawRequestErrorsQuery.data]);

  const getLineItemError = useCallback(
    (milestoneId: string, metricKey: string) => drawRequestErrors?.[metricKey]?.[milestoneId],
    [drawRequestErrors],
  );

  const updateRequestedChanges = useCallback(
    ({ value, requestedAmount, milestoneId, key }: UpdateRequestedChangesArgs) => {
      const firstLine = `The requested change was updated to ${formatError(
        currencyFormatter(value),
      )} to meet the requested amount of ${formatError(currencyFormatter(requestedAmount))}.`;
      const secondLine = hiddenColumns?.includes('requested_adjustments')
        ? `We've made the ${formatError(
            'requested +/- change',
          )} column visible for you to continue reallocating between your line items.`
        : `Continue reallocating in the ${formatError(
            'requested +/- change',
          )} column to avoid changing the construction holdback.`;
      setCellTooltipTexts((data) => ({
        ...data,
        [milestoneId]: {
          ...data[milestoneId],
          [key]: { open: true, text: `${firstLine}\n\n${secondLine}` },
        },
      }));
      setTimeout(() => {
        setCellTooltipTexts((data) => ({
          ...data,
          [milestoneId]: {
            ...data[milestoneId],
            [key]: { open: false, text: `${firstLine}\n\n${secondLine}` },
          },
        }));
      }, 5000);

      if (hiddenColumns?.includes('requested_adjustments') && changeFieldVisibility) {
        changeFieldVisibility('requested_adjustments');
      }
    },
    [hiddenColumns, changeFieldVisibility],
  );

  const tableContext = useMemo<TableContextInterface>(
    () => ({
      withProgress,
      tableKey,
      ...(resetMutation ? { resetMutation } : {}),
      ...(refetch ? { refetch } : {}),
      ...(deleteMilestone ? { deleteMilestone } : {}),
      ...(patchMilestone ? { apiUpdate } : {}),
      ...(openEditMilestoneModal ? { openEditMilestoneModal } : {}),
      ...(onExpandTable ? { onExpandTable } : {}),
      highLightCellByData,
      updateRightDrawer,
      requestStatus,
      commentsPreviewInfo,
      contingencyMode,
      source,
      getLineItemError,
      updateRequestedChanges,
      cellTooltipTexts,
    }),
    [
      commentsPreviewInfo,
      onExpandTable,
      source,
      getLineItemError,
      updateRequestedChanges,
      cellTooltipTexts,
    ],
  );

  const reCalculateSize = () => {
    setContainerWidth(tableRef.current.clientWidth);
  };

  useEffect(() => {
    return () => {
      setHighlightCells({});
      setactiveCell({ column: -1, row: -1 });
    };
  }, [milestones?.length]);

  useEffect(() => {
    const footer = document.getElementById('templateFooter') as HTMLElement;
    reCalculateSize();
    window.addEventListener('resize', reCalculateSize);

    if (footer?.style && tableRef.current) {
      const isHeightMore = tableRef.current.clientHeight > document.body.clientHeight - 100;
      footer.style.display = isHeightMore ? 'none' : 'flex';
    }
    return () => window.removeEventListener('resize', reCalculateSize);
  }, []);

  const isCellActive = useCallback(
    (columnIndex: number, rowIndex: number) =>
      activeCell.column === columnIndex && activeCell.row === rowIndex,
    [activeCell],
  );

  const renderColumns = useMemo(
    () => columns.filter((cl) => cl && !hiddenColumns.includes(cl.name)),
    [columns, hiddenColumns],
  );
  // SIMPLE CHECK FOR
  const isEditableMilestones = useMemo(
    () => milestones?.some((item) => item.activeToEdit),
    [milestones],
  );

  const isEditableFieldsExist = useMemo(
    () => isEditableMilestones || renderColumns.some((item) => item.isEditable),
    [isEditableMilestones, renderColumns],
  );

  const columnsWidth = useMemo(() => {
    const minSpace = renderColumns.reduce(
      (sum, column) =>
        sum +
        column.minWidth(
          isEditableFieldsExist &&
            (typeof column.isEditable === 'function'
              ? column.isEditable?.(permissions)
              : column.isEditable),
        ),
      0,
    );
    const maxSpace = renderColumns.reduce(
      (sum, column) =>
        sum +
        column.maxWidth(
          isEditableFieldsExist &&
            (typeof column.isEditable === 'function'
              ? column.isEditable?.(permissions)
              : column.isEditable),
        ),
      0,
    );

    if (containerWidth <= minSpace) {
      return renderColumns.map((column) =>
        column?.isSpacer
          ? 0
          : column.minWidth(
              isEditableFieldsExist &&
                (typeof column.isEditable === 'function'
                  ? column.isEditable?.(permissions)
                  : column.isEditable),
            ),
      );
    } else if (containerWidth >= maxSpace) {
      return renderColumns.map((column) =>
        column.maxWidth(
          isEditableFieldsExist &&
            (typeof column.isEditable === 'function'
              ? column.isEditable?.(permissions)
              : column.isEditable),
        ),
      );
    } else {
      const factor = (containerWidth - minSpace) / (maxSpace - minSpace);
      return renderColumns.map(
        (column) =>
          column.minWidth(
            isEditableFieldsExist &&
              (typeof column.isEditable === 'function'
                ? column.isEditable?.(permissions)
                : column.isEditable),
          ) +
          factor *
            (column.maxWidth(
              isEditableFieldsExist &&
                (typeof column.isEditable === 'function'
                  ? column.isEditable?.(permissions)
                  : column.isEditable),
            ) -
              column.minWidth(
                isEditableFieldsExist &&
                  (typeof column.isEditable === 'function'
                    ? column.isEditable?.(permissions)
                    : column.isEditable),
              )),
      );
    }
  }, [renderColumns, containerWidth, isEditableFieldsExist, permissions]);

  const getColumnWidth = ({ index }) => columnsWidth[index];

  const pastUpdate = useCallback(
    (pastData: string, isNonNumber = false) => {
      const pastDataArray = pastData.split('\n');
      pastDataArray.slice(0, 20).forEach((data, index) => {
        const pathIdex = activeCell.row + index;
        const input = document.querySelector(
          `[data-cell="${pathIdex}_${activeCell.column}"] input`,
        );
        if (pathIdex < sortedMilestones.length - 1 && input && data.replace('\r', '')) {
          highLightCellByData(sortedMilestones[pathIdex].id, renderColumns[activeCell.column].name);
          const tags = getMilestoneGroupsTagsIds(sortedMilestones[pathIdex]['tags']);
          apiUpdate({
            value: isNonNumber ? data.replace('\r', '') : data.replace(/,/g, ''),
            milestoneId: sortedMilestones[pathIdex].id,
            name: renderColumns[activeCell.column].name,
            isNonNumber,
            needToCompare: false,
            ...(tags ? { milestoneTags: tags } : {}),
          });
        }
      });
    },
    [sortedMilestones, renderColumns, activeCell, apiUpdate],
  );

  const bottomLine = useMemo(
    () => tableRef.current?.getBoundingClientRect()?.top,
    [tableRef.current],
  );

  const onPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    const pastData = e.clipboardData.getData('Text');
    const input = e.target as HTMLInputElement;
    const isNonNumber = input?.inputMode !== 'numeric';
    const pastDataArray = pastData.split('\n');
    if (pastData && e.target?.['nodeName'] === 'INPUT' && pastDataArray.length > 1) {
      e.stopPropagation();
      e.preventDefault();
      pastUpdate(pastData, isNonNumber);
    }
  };

  const keyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const newActive = { ...activeCell };
      switch (e.code) {
        case 'ArrowUp':
          if (activeCell.row > 0) {
            e.stopPropagation();
            e.preventDefault();
            newActive.row = activeCell.row - 1;
          }
          break;
        case 'ArrowDown':
          if (activeCell.row < sortedMilestones.length - 1) {
            e.stopPropagation();
            e.preventDefault();
            newActive.row = activeCell.row + 1;
          }
          break;
        case 'ArrowRight': {
          if (activeCell.column < renderColumns.length - 1 && !e.target?.['value']) {
            const step = renderColumns[activeCell.column + 1].isSpacer ? 2 : 1;
            newActive.column = activeCell.column + step;
          }
          break;
        }
        case 'ArrowLeft': {
          if (activeCell.column > 0 && !e.target?.['value']) {
            const step = renderColumns[activeCell.column - 1].isSpacer ? 2 : 1;
            newActive.column = activeCell.column - step;
          }
          break;
        }
        case 'NumpadEnter':
        case 'Enter':
        case 'Tab': {
          if (activeCell.column < renderColumns.length + 1) {
            e.stopPropagation();
            e.preventDefault();
            newActive.row = activeCell.row + 1;
          }
          break;
        }
        default:
      }
      const newInput: HTMLInputElement = document.querySelector(
        `[data-cell="${newActive.row}_${newActive.column}"] input`,
      );
      const existInput: HTMLInputElement = document.querySelector(
        `[data-cell="${activeCell.row}_${activeCell.column}"] input`,
      );
      if (
        existInput &&
        (activeCell.row !== newActive.row || activeCell.column !== newActive.column)
      ) {
        existInput.blur();
        tableRef.current.focus();
      }
      if (newInput) {
        newInput.focus();
      }
      setactiveCell(newActive);
    },
    [activeCell],
  );

  const showCreditButton = useMemo(
    () =>
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREDIT_APPROVE, permissions) &&
      isEditableMilestones &&
      withCredit &&
      isDrawRequest(drawRequestErrorsQuery.data) &&
      isCreditInRequest(sortedMilestones),
    [withCredit, drawRequestErrorsQuery, sortedMilestones, isEditableMilestones, permissions],
  );

  return {
    columns,
    tableContext,
    hiddenColumns,
    isColumnFilterUpdating,
    changeFieldVisibility,
    sortedMilestones,
    tableRef,
    milestoneColumns: milestoneColumnsQuery.data || [],
    rightDrawerParams: { ...rightDrawerParams, projectId },
    rightMenu,
    getColumnWidth,
    isCellActive,
    setactiveCell,
    activeCell,
    renderColumns,
    apiUpdate,
    pastUpdate,
    containerWidth,
    onPaste,
    keyDown,
    highlightCells,
    project: projectQuery.data,
    bottomLine,
    updateRightDrawer,
    showCreditButton,
  };
};
