import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter, isAllowed } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const prefundingCost: MilestoneListColumnTypeV2 = {
  field: 'prefunding_cost',
  valueFormatter: (value) => currencyFormatter(value, ''),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Prefunding cost ($)',
  description: 'Project costs spent prior to loan closing',
  editableByMilestone: (row) => row.isEditableV2 && row.localNew,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  minWidth: 120,
};

export default prefundingCost;
