import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezonePlugin from 'dayjs/plugin/timezone';
import { useQuery } from 'react-query';

import { ICompanyFull, QueryNamesEnums } from '@interfaces';
import { getMyCompany } from '@globalService';

dayjs.extend(utc);
dayjs.extend(timezonePlugin);

interface DateFormatterOptions {
  date: string | Date | null;
  withTime?: boolean;
}

export const useDayJsFormatter = () => {
  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const timezone = companyQuery?.data?.timezone || 'US/Pacific';

  const dateFormatter = ({ date, withTime = false }: DateFormatterOptions) => {
    if (!date) return null;

    // date regex for 'yyyy-mm-dd'
    const singleDateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;

    if (singleDateRegex.test(date.toString())) {
      const formattedDate = dayjs(date).format('MMM DD, YYYY');
      return formattedDate;
    }

    const parsedDate = dayjs(date).tz(timezone);
    if (withTime) {
      return parsedDate.format('MMM DD, YYYY HH:mm zzz');
    }

    return parsedDate.format('MMM DD, YYYY');
  };

  const getInitialValue = (date?: Date | string | null | undefined): Date => {
    const singleDateRegex = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12]\d|3[01])$/;

    if (!date) {
      // No input: return the start of the current day in the specified timezone
      return dayjs().tz(timezone).startOf('day').toDate();
    }

    if (singleDateRegex.test(date.toString())) {
      // Input is a "date only" (YYYY-MM-DD): return the start of that day in the specified timezone
      return dayjs.tz(date.toString(), timezone).startOf('day').toDate();
    }

    // Input is a full ISO string or Date object: return the equivalent Date
    return dayjs(date).toDate();
  };
  const getFormattedToTimezoneStringValue = (utcDate: string | Date | null): string | null => {
    if (!utcDate) return null;
    return dayjs(utcDate).tz(timezone).format('YYYY-MM-DD');
  };

  const areSameDays = (date1: Date | null | undefined, date2: Date | null | undefined) => {
    if (!date1 || !date2) return false;
    return dayjs(date1).isSame(dayjs(date2), 'day');
  };

  return {
    dateFormatter,
    getInitialValue,
    areSameDays,
    getFormattedToTimezoneStringValue,
  };
};
