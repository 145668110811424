import { useCallback, useState } from 'react';
import { useQuery, useMutation } from 'react-query';
import {
  EnumTypeForList,
  IProjectChecklist,
  PoliciesTypeEnum,
  PolicyListSourceEnums,
  QueryNamesEnums,
} from '@interfaces';
import { applyBulkPolicies, getCompanyPoliciesTemplates } from '@globalService';
import { DropdownFieldModel, useDropdownFieldModel } from '@models';
import { useSafeSnackbar } from '@hooks';
import { PoliciesTypeMap } from '@constants';

interface BulkApplyPoliciesController {
  getFilteredTemplates: (source: string, type: PoliciesTypeEnum) => EnumTypeForList[];
  selectedTemplates: Record<PolicyListSourceEnums, Record<PoliciesTypeEnum, DropdownFieldModel>>;
  applyPolicies: () => Promise<void>;
  isLoading: boolean;
  showSuccessPopup: string;
  setShowSuccessPopup: (value: string) => void;
}

export const useBulkApplyPolicies = ({
  projectIds,
  companyId,
}: {
  projectIds: string[];
  companyId: string;
}): BulkApplyPoliciesController => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const [showSuccessPopup, setShowSuccessPopup] = useState<string>('');

  const selectedTemplates = {
    [PolicyListSourceEnums.SUBMISSION]: {
      [PoliciesTypeEnum.PROJECT]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FIRST_DRAW]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FUTURE_DRAWS]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FUTURE_CHANGES]: useDropdownFieldModel({ initValue: null }),
    },
    [PolicyListSourceEnums.APPROVAL]: {
      [PoliciesTypeEnum.PROJECT]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FIRST_DRAW]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FUTURE_DRAWS]: useDropdownFieldModel({ initValue: null }),
      [PoliciesTypeEnum.FUTURE_CHANGES]: useDropdownFieldModel({ initValue: null }),
    },
  };

  const companyPoliciesTemplateQuery = useQuery<IProjectChecklist[], Error>(
    [QueryNamesEnums.GET_COMPANY_POLICIES_TEMPLATES, { companyId }],
    getCompanyPoliciesTemplates.bind(this, companyId),
    { enabled: Boolean(companyId) },
  );

  const applyBulkPoliciesMutation = useMutation<Response, Error, string[]>(
    (templates: string[]) =>
      applyBulkPolicies({
        projectIds,
        policies: templates,
      }),
    {
      onSuccess: () => {
        setShowSuccessPopup('Policies applied successfully');
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const applyPolicies = async () => {
    const selectedTemplateIds = Object.values(selectedTemplates).flatMap((templates) =>
      Object.values(templates)
        .filter((template) => template.value)
        .map((template) => template.value.value),
    );

    await applyBulkPoliciesMutation.mutateAsync(selectedTemplateIds);
  };

  const getFilteredTemplates = useCallback(
    (source: string, type: PoliciesTypeEnum) =>
      companyPoliciesTemplateQuery.data
        ?.filter(
          (template) =>
            template.assignee_role === source && template.type === PoliciesTypeMap[type],
        )
        .map((template) => ({
          value: template.id,
          name: template.name,
          name_display: `${template.name}${template.is_default ? ' (default)' : ''}`,
        })) || [],
    [companyPoliciesTemplateQuery.data],
  );

  return {
    selectedTemplates,
    applyPolicies,
    getFilteredTemplates,
    isLoading: applyBulkPoliciesMutation.isLoading,
    showSuccessPopup,
    setShowSuccessPopup,
  };
};
