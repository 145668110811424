import React from 'react';
import { GridColDef } from '@mui/x-data-grid';
import { StyledLink } from '@components';
import { DrNumberColumnProps } from '../interface';

export const drNumberColumn = ({ projectId }: DrNumberColumnProps): GridColDef => ({
  field: 'draw_request',
  headerName: 'Linked request #',
  minWidth: 100,
  groupable: false,
  aggregable: false,
  sortable: false,
  renderCell: (params) => {
    const { draw_request, project } = params.row;

    return (
      <span>
        <StyledLink
          onClick={(e) => e.stopPropagation()}
          to={`/projects/${project?.id || projectId}/draws/draw-requests/${draw_request?.id}`}
          variant="body3Semibold"
        >
          {draw_request?.number}
        </StyledLink>
      </span>
    );
  },
});
