import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import {
  DocumentContentTypeEnum,
  DocumentTypeEnum,
  IDocumentType,
  QueryNamesEnums,
} from '@interfaces';
import { getProjectDocumentTypeByName } from '@globalService';
import { useCustomUppy } from '@hooks';
import { createTuple, generateCSV } from '@utils';

interface ControllerInterface {
  isLoading: boolean;
  onButtonClick: () => Promise<void>;
}

export const useGenerateCSVButton = ({
  onClose,
  transloaditSignature,
  csvData,
}): ControllerInterface => {
  const { projectId } = useParams();
  const [file, setFile] = useState<null | Blob>(null);
  const [isLoading, setIsLoading] = useState(false);

  const documentTypeQuery = useQuery<IDocumentType, Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TEMPLATE_TYPE],
    getProjectDocumentTypeByName.bind(this, DocumentTypeEnum.PROJECT_IMPORT_TEMPLATE),
  );

  const [uppy] = useState(() =>
    useCustomUppy({
      params: transloaditSignature?.params,
      signature: transloaditSignature?.signature,
    }),
  );

  const addFileToUppy = (file: Blob) => {
    uppy.addFile({
      name: 'CSVBoxSample.csv',
      type: 'text/csv',
      data: file,
      meta: {
        linked_objects: createTuple(DocumentContentTypeEnum.PROJECT, projectId),
        document_type_id: documentTypeQuery.data?.id,
      },
    });
  };

  const uploadUppyFile = () => {
    uppy.upload().then(() => {
      setIsLoading(false);
      setFile(null);
      onClose();
    });
  };

  useEffect(() => {
    if (!file || !transloaditSignature?.params) return;
    setIsLoading(true);
    addFileToUppy(file);
    uploadUppyFile();
  }, [file, transloaditSignature?.params]);

  const onButtonClick = async () => {
    const file = await generateCSV(csvData);
    setFile(file);
  };

  return {
    onButtonClick,
    isLoading,
  };
};
