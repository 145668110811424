import React, { Dispatch, SetStateAction } from 'react';
import {
  Box,
  FormControlLabel,
  Stack,
  Switch,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import { DotIcon } from '@svgAsComponents';
import { ITeam } from '@interfaces';
import { useDragAndDrop } from '@hooks';
import { RoleIconWithLink } from '@components';

const DND_ITEM_TYPE = 'team';

interface ComponentProps {
  team: ITeam;
  index: number;
  setItems: Dispatch<SetStateAction<ITeam[]>>;
  handleBulkUpdateTeam: () => void;
  hasCompanyTeamsEditPermission: boolean;
  handleTeamApprovalFlow: (value: boolean, index: number) => void;
  excludedFromApproval?: boolean;
  listLength?: number;
  isApprovalFlowMutating: boolean;
}

const TeamRow = ({
  team,
  index,
  setItems,
  handleBulkUpdateTeam,
  hasCompanyTeamsEditPermission,
  handleTeamApprovalFlow,
  excludedFromApproval,
  listLength,
  isApprovalFlowMutating,
}: ComponentProps) => {
  const { opacity, boxShadow, getDragItemIcon, dropRef } =
    hasCompanyTeamsEditPermission && listLength > 1
      ? useDragAndDrop({
          dndItemType: DND_ITEM_TYPE,
          setItems,
          index,
          onEnd: handleBulkUpdateTeam,
        })
      : { opacity: 1, boxShadow: 'none', getDragItemIcon: () => null, dropRef: null };

  const { name, members } = team || {};

  return (
    <TableRow
      ref={dropRef}
      sx={{
        opacity,
        boxShadow,
        '& td': { borderBottom: 'none' },
      }}
    >
      <TableCell>{!excludedFromApproval ? `${index + 1}. ` : ''}</TableCell>
      {hasCompanyTeamsEditPermission && (
        <TableCell sx={{ p: 0, mx: 5, alignItems: 'flex-end' }}>
          {!excludedFromApproval ? getDragItemIcon() : null}
        </TableCell>
      )}
      <TableCell>
        <Stack direction="row" alignItems="center">
          <Typography variant="body1SemiBold">{name}</Typography>
          <Box sx={{ mr: 2, ml: 2 }}>
            <DotIcon />
          </Box>
          <Stack direction="row" alignItems="center">
            <Typography variant="body1" mr={1}>
              Members:
            </Typography>
            <Typography variant="body1SemiBold">{members?.length}</Typography>
          </Stack>
        </Stack>
      </TableCell>
      <TableCell>
        <FormControlLabel
          control={
            <Switch
              checked={!excludedFromApproval}
              onChange={(_, value) => handleTeamApprovalFlow(value, index)}
              data-cy="company_teams__workflow__include_in_approval_flow__switch"
              disabled={isApprovalFlowMutating}
            />
          }
          componentsProps={{ typography: { variant: 'body3' } }}
          name="includeInApprovalFlow"
          label="Include in approval flow"
        />
      </TableCell>
      <TableCell>
        <RoleIconWithLink roleId={team.permission_role?.id} />
      </TableCell>
    </TableRow>
  );
};

export default TeamRow;
