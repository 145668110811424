import React, { FC, memo, useContext } from 'react';
import { Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { currencyFormatter } from '@utils';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const OriginalConstructionBudget: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__original_construction_budget__value__index_${row?.index}`}
    >
      {currencyFormatter(row.prefunding_cost + row.original_estimate)}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__original_construction_budget__value`}
    >
      {currencyFormatter(row.prefunding_cost + row.original_estimate)}
    </Typography>
  );
};

const Memoized = memo(OriginalConstructionBudget);

const originalConstructionBudget: MilestoneListColumnType = {
  name: 'original_construction_budget',
  columnText: 'Original construction budget ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Original amount to be spent on construction inclusive of holdback and borrower equity pre and post funding before any changes or reallocations of funds">
      <ColumnLabel>Original construction</ColumnLabel>
      <ColumnLabel> budget ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default originalConstructionBudget;
