import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import {
  approximationEqual,
  canBeEdited,
  checkIsAdmin,
  checkIsInvestor,
  checkIsLender,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  isPercentAllowed,
  percentFormatter,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { AuthContext, SettingsContext } from '@context';
import { TableContext } from '../controller';
import { TextInputWithTooltip } from '@components';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { useDebounceInput, useStringFieldModel } from '@models';
import {
  ColumnV2Width,
  CommonRowType,
  ColumnWidth,
  IMilestoneTotal,
  TableKeyEnum,
} from '@interfaces';

const LenderAllowancePercentDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, tableKey, resetMutation, contingencyMode, source, getLineItemError } =
    useContext(TableContext);
  const error = getLineItemError(row.id, 'lender_allowance_rate');

  const lender_allowance_rate = useStringFieldModel({
    initError: error,
    initValue: (row['lender_allowance_rate'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'lender_allowance_rate',
  });

  const { isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'lender_allowance_rate',
    tags,
  });

  const isEditable = useMemo(
    () =>
      canBeEdited({ row, contingencyMode, adjustments_field: 'approved_adjustments', error }) &&
      tableKey !== TableKeyEnum.CHANGE_REQUEST &&
      (checkIsLender(teamRole) || checkIsInvestor(teamRole) || checkIsAdmin(teamRole)),
    [teamRole, row, contingencyMode],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: lender_allowance_rate.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(
          row.lender_allowance_rate,
          lender_allowance_rate.floatValue,
        ),
        name: 'lender_allowance_rate',
        ...props,
      }),
    [lender_allowance_rate.floatValue, row.lender_allowance_rate],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: lender_allowance_rate.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { lender_allowance_rate: 0 } }),
    afterEffect: () => lender_allowance_rate.changeNavigationBlockContext(false),
    delay: isPHBProject ? 1500 : 500,
  });

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable ? (
    <TextInputWithTooltip
      inputProps={{
        isAllowed: isPercentAllowed,
      }}
      onBlur={onBlur}
      value={lender_allowance_rate.value}
      onChange={onChange}
      error={Boolean(error)}
      errorTip={error}
      type="percent"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      dataTestName={`${source}__body__lender_allowance_rate__value__index_${row?.project_milestone?.index}`}
      iconDataTestName={`${source}__body__lender_allowance_rate__error_icon__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <Typography
      variant="body3"
      data-cy={`${source}__body__lender_allowance_rate__value__index_${row?.project_milestone?.index}`}
    >
      {percentFormatter({ value: row.lender_allowance_rate })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__lender_allowance_rate__value`}>
      {percentFormatter({ value: row.lender_allowance_rate })}
    </Typography>
  );
};

const lenderAllowanceRate: MilestoneListColumnType = {
  name: 'lender_allowance_rate',
  initDataField: 'lender_allowance_rate',
  columnText: 'Lender allowance (%)',
  width: ColumnWidth.INPUT_MONEY,
  isEditable: true,
  renderCell: ({ row }) => <LenderAllowancePercentDetails row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Cumulative % approved to fund per Lender">
      <ColumnLabel>Lender</ColumnLabel>
      <ColumnLabel> allowance (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default lenderAllowanceRate;
