import React from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import { BulkApplyPolicies, IconButtonWithTooltip } from '@components';
import { CloseIcon, PermitIcon, VisibilityOffIcon, VisibilityOnIcon } from '@svgAsComponents';
import { useSafeSnackbar } from '@hooks';
import { useProjectsBulkPanel } from './controller';

interface ProjectsBulkPanelProps {
  selectedProjectsIds: string[];
  selectedProjectCompanyIds?: string[];
  unSelectAllProjects: () => void;
  onWatchComplete?: () => void;
  isLender: boolean;
  canApplyBulkPolicies: boolean;
}

const ProjectsBulkPanel: React.FC<ProjectsBulkPanelProps> = ({
  selectedProjectsIds,
  selectedProjectCompanyIds,
  unSelectAllProjects,
  onWatchComplete,
  isLender,
  canApplyBulkPolicies,
}) => {
  const { enqueueSnackbar } = useSafeSnackbar();

  const { startWatchingProjects, unWatchProjects, setOpenApplyPolicies, openApplyPolicies } =
    useProjectsBulkPanel({
      selectedProjectsIds,
      unSelectAllProjects,
      onComplete: onWatchComplete,
    });

  return (
    <Stack
      direction="row"
      sx={{
        position: 'fixed',
        backgroundColor: colors.main.primary.main,
        bottom: 24,
        left: '50%',
        transform: 'translate(-50%, 0)',
        px: 3,
        py: 1,
      }}
      alignItems="center"
    >
      <Stack direction="row" alignItems="center">
        <IconButtonWithTooltip onClick={unSelectAllProjects} sx={{ p: 0 }} tooltipText="Close">
          <CloseIcon size={16} color={colors.neutral.white} />
        </IconButtonWithTooltip>
        <Typography variant="body2SemiBold" sx={{ ml: 2, color: colors.neutral.white }}>
          {`${selectedProjectsIds.length} projects selected`}
        </Typography>
      </Stack>
      <Divider
        orientation="vertical"
        sx={{ mx: '32px', color: colors.neutral.white, height: '32px' }}
      />

      {isLender && (
        <Stack direction="row" alignItems="center" spacing="40px">
          <IconButtonWithTooltip
            onClick={startWatchingProjects}
            sx={{ p: 0 }}
            tooltipText="Watch selected projects"
          >
            <>
              <VisibilityOnIcon size={24} color={colors.neutral.white} />
              <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
                Watch
              </Typography>
            </>
          </IconButtonWithTooltip>
          <IconButtonWithTooltip
            onClick={unWatchProjects}
            sx={{ p: 0 }}
            tooltipText="Unwatch selected projects"
          >
            <>
              <VisibilityOffIcon size={24} color={colors.neutral.white} />
              <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
                Unwatch
              </Typography>
            </>
          </IconButtonWithTooltip>
        </Stack>
      )}

      {canApplyBulkPolicies && (
        <>
          <Divider
            orientation="vertical"
            sx={{ mx: '32px', color: colors.neutral.white, height: '32px' }}
          />
          <Stack direction="row" alignItems="center" spacing="40px">
            <IconButtonWithTooltip
              onClick={() => {
                if (selectedProjectCompanyIds.length > 1) {
                  enqueueSnackbar('Please select projects from only one company', {
                    variant: 'error',
                  });
                  return;
                }
                setOpenApplyPolicies(true);
              }}
              sx={{ p: 0 }}
              tooltipText="Apply policies to selected projects"
            >
              <>
                <PermitIcon size={24} color={colors.neutral.white} />
                <Typography variant="body2SemiBold" sx={{ ml: 1, color: colors.neutral.white }}>
                  Apply policies
                </Typography>
              </>
            </IconButtonWithTooltip>
          </Stack>
          <BulkApplyPolicies
            open={openApplyPolicies}
            onClose={() => {
              setOpenApplyPolicies(false);
              unSelectAllProjects();
            }}
            projectIds={selectedProjectsIds}
            companyId={selectedProjectCompanyIds[0]}
          />
        </>
      )}
    </Stack>
  );
};

export default ProjectsBulkPanel;
