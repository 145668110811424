/* eslint-disable no-console */
/* eslint-disable dot-notation */
import React, { useContext, useMemo } from 'react';
import { Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { useNavigate } from 'react-router-dom';
import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';

import { AuthProvider, NotistackProvider, SSOContext, TransloaditProvider } from '@context';
import { ErrorBoundary } from '@components';
import { useCacheBoost } from '@hooks';
import { IdentityServiceTypeEnum } from '@interfaces';
import { ThemeConfig } from '@theme';
import { isLocalhost, packageJsonVersion } from '@utils';

import Router from './navigation/routes';
import { CustomNavigationClient } from './NavigationClient';

function App() {
  if (!isLocalhost) useCacheBoost({ version: packageJsonVersion });

  const navigate = useNavigate();
  const { ssoConfig, ssoType } = useContext(SSOContext);

  const router = useMemo(() => {
    const restoreOriginalUri = async () => {
      navigate('/login-with-sso', { state: { fromRedirect: true } });
    };

    if (ssoConfig?.issuer && ssoType === IdentityServiceTypeEnum.OKTA) {
      return (
        <Security oktaAuth={new OktaAuth(ssoConfig)} restoreOriginalUri={restoreOriginalUri}>
          <Router />
        </Security>
      );
    }
    if (ssoConfig?.authority && ssoType === IdentityServiceTypeEnum.MICROSOFT) {
      const navigationClient = new CustomNavigationClient(navigate);

      const msalInstance = new PublicClientApplication({
        auth: ssoConfig,
        cache: {
          cacheLocation: 'sessionStorage',
          storeAuthStateInCookie: false,
        },
      });
      msalInstance.initialize();
      msalInstance.setNavigationClient(navigationClient);

      return (
        <MsalProvider instance={msalInstance}>
          <Router />
        </MsalProvider>
      );
    }
    return <Router />;
  }, [ssoConfig, ssoType, navigate]);

  return (
    <ThemeConfig>
      <ErrorBoundary>
        <AuthProvider>
          <TransloaditProvider>
            <NotistackProvider>{router}</NotistackProvider>
          </TransloaditProvider>
        </AuthProvider>
      </ErrorBoundary>
    </ThemeConfig>
  );
}

export default App;
