import { useContext, useMemo } from 'react';
import { PermissionsContext } from '@context';
import { UseFilterData } from './interface';

interface UseStaticFilterProps {
  initValues: string[];
  getStaticValues: (params: any) => any;
}

export const useStaticFilterData = ({
  initValues,
  getStaticValues,
}: UseStaticFilterProps): UseFilterData => {
  const { permissions } = useContext(PermissionsContext);
  const valuesWithExistingData = getStaticValues({ permissions }) || [];

  const stringToShow = useMemo(
    () =>
      valuesWithExistingData
        ?.filter((item) => initValues.includes(item.value))
        ?.map((item) => item.label || item.id)
        ?.join(','),
    [valuesWithExistingData, initValues],
  );

  return {
    isLoading: false,
    setQ: () => null,
    setPage: () => null,
    nextPageCallback: () => null,
    valuesWithExistingData,
    stringToShow,
  };
};
