import { GridEditInputCell } from '@mui/x-data-grid';
import React from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { percentFormatter } from '@utils';
import { PercentsInput } from '@components';
import { InputBaseComponentProps } from '@mui/material';

const adjustmentsRate: MilestoneListColumnTypeV2 = {
  field: 'approved_adjustments_rate',
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Approved +/- change (%)',
  description: '% of approved changes to revised scheduled value for this request',
  valueParser: (value) => +value,
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputComponent={
        PercentsInput as unknown as React.ElementType<InputBaseComponentProps> as unknown as React.ElementType<InputBaseComponentProps>
      }
    />
  ),
  editable: false,
  valueFormatter: (value) => percentFormatter({ returnValue: ' ', value }),
  minWidth: 120,
};

export default adjustmentsRate;
