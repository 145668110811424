import React, { FC, memo, useContext } from 'react';
import { percentFormatter, useBlockerCell } from '@utils';
import { Skeleton, Stack, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { CommonRowType, ColumnV2Width, ColumnWidth, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const BalanceToFinishRate: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__balance_to_finish_rate__index_${row?.index}`}
    >
      {percentFormatter({ value: row.balance_to_finish_rate })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Stack>
      <Typography
        variant="body3SemiBold"
        data-cy={`${source}__footer__balance_to_finish_rate__value`}
      >
        {percentFormatter({ value: row.balance_to_finish_rate })}
      </Typography>
    </Stack>
  );
};

const Memoized = memo(BalanceToFinishRate);

const balanceToFinishRate: MilestoneListColumnType = {
  name: 'balance_to_finish_rate',
  columnText: 'Balance to finish (%)',
  width: ColumnWidth.PERCENTS,
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="% remaining of the revised scheduled value">
      <ColumnLabel>Balance</ColumnLabel>
      <ColumnLabel> to finish (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default balanceToFinishRate;
