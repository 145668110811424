import { useCallback, useContext, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { postProjectsWatchers, removeProjectsWatchers } from '@globalService';
import { IProjectsPortfolioItem, PostProjectsWatchersParam, QueryNamesEnums } from '@interfaces';
import { useSafeSnackbar } from '@hooks';
import { AuthContext } from '@context';
import { replaceItemInPaginatedResponse } from '@utils';

interface UseProjectsBulkPanelProps {
  selectedProjectsIds: string[];
  unSelectAllProjects: () => void;
  onComplete?: () => void;
}

interface ControllerInterface {
  startWatchingProjects: () => void;
  unWatchProjects: () => void;
  openApplyPolicies: boolean;
  setOpenApplyPolicies: (value: boolean) => void;
}

export const useProjectsBulkPanel = ({
  selectedProjectsIds,
  unSelectAllProjects,
  onComplete,
}: UseProjectsBulkPanelProps): ControllerInterface => {
  const [openApplyPolicies, setOpenApplyPolicies] = useState(false);
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { user } = useContext(AuthContext);

  const invalidateWatchQueries = ({ isWatching }: { isWatching: boolean }) => {
    selectedProjectsIds.forEach((projectId) => {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_WATCHERS,
        { projectId: projectId },
      ]);
      queryClient.setQueriesData<{ results: IProjectsPortfolioItem[] }>(
        {
          queryKey: [QueryNamesEnums.GET_PROJECTS_LIST],
          exact: false,
        },
        (old) =>
          replaceItemInPaginatedResponse({
            old,
            updatedItem: { id: projectId, is_watching: isWatching },
          }),
      );
    });

    unSelectAllProjects();
  };

  const addProjectsWatchersMutation = useMutation<Response, Error, PostProjectsWatchersParam>(
    postProjectsWatchers,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const removeProjectsWatchersMutation = useMutation<Response, Error, PostProjectsWatchersParam>(
    removeProjectsWatchers,
    {
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const startWatchingProjects = useCallback(() => {
    addProjectsWatchersMutation.mutateAsync({
      projectIds: selectedProjectsIds,
      userIds: [user.id],
    });
    invalidateWatchQueries({ isWatching: true });
    onComplete();
  }, [user.id, addProjectsWatchersMutation, selectedProjectsIds, invalidateWatchQueries]);

  const unWatchProjects = useCallback(() => {
    removeProjectsWatchersMutation.mutateAsync({
      projectIds: selectedProjectsIds,
      userIds: [user.id],
    });
    invalidateWatchQueries({ isWatching: false });
    onComplete();
  }, [user.id, removeProjectsWatchersMutation, selectedProjectsIds, invalidateWatchQueries]);

  return {
    startWatchingProjects,
    unWatchProjects,
    openApplyPolicies,
    setOpenApplyPolicies,
  };
};
