import { useState } from 'react';
import { useQuery } from 'react-query';
import { HookState, IPermission, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getProjectPermissions } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { getHookState, isAllowed } from '@utils';

interface ControllerInterface {
  closeErrorCallback: (event: React.MouseEvent<HTMLElement>) => void;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  permissionNameClicked: string;
  isOpenErrorModal: boolean;
  state: HookState;
}

export const useIconWithProjectPermission = (
  projectId: string,
  permissionKey: PermissionNamesEnums,
  openPopupCallback: () => void,
): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const [persmissions, setPermissions] = useState([]);
  const [permissionNameClicked, setPermissionNameClicked] = useState('');
  const [isOpenErrorModal, setOpenErrorModal] = useState(false);

  const projectPermissionsQuery = useQuery<IPermission[], Error>(
    [QueryNamesEnums.GET_PROJECT_SETTINGS, { projectId }],
    getProjectPermissions.bind(this, projectId),
    {
      enabled: false,
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const closeErrorCallback = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setOpenErrorModal(false);
  };

  const handleClick = async (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setPermissionNameClicked(permissionKey);
    const projectPermissions = [...persmissions];
    if (!projectPermissions.length) {
      const res = await projectPermissionsQuery.refetch();
      res.data?.forEach((item) => {
        if (item.enabled) projectPermissions.push(item);
      });

      setPermissions(projectPermissions);
    }

    setPermissionNameClicked('');
    if (!isAllowed(permissionKey, projectPermissions)) {
      setOpenErrorModal(true);
      return;
    }

    openPopupCallback();
  };

  return {
    closeErrorCallback,
    handleClick,
    permissionNameClicked,
    isOpenErrorModal,
    state: getHookState(projectPermissionsQuery),
  };
};
