import { useCallback, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { IDrawRequest, PermissionNamesEnums, RequestActions } from '@interfaces';
import { checkIsCreator, getTeamRole, isRequestDraft, isAllowed } from '@utils';
import { AuthContext, PermissionsContext } from '@context';

interface HookInterface {
  continueDraftButtonLabel: string;
  openDraft: () => void;
}

export const useContinueDraftButton = ({
  projectId,
  drawRequestId,
  drawRequest,
}: {
  projectId: string;
  drawRequestId: string;
  drawRequest: IDrawRequest;
}): HookInterface => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);
  const teamRole = getTeamRole(user);
  const navigate = useNavigate();

  const continueDraftButtonLabel = useMemo(() => {
    if (
      !isRequestDraft(drawRequest?.status) ||
      !isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
      !checkIsCreator(drawRequest, teamRole)
    )
      return '';

    return drawRequest?.is_resubmit ? 'Edit request' : 'Continue draft request';
  }, [drawRequest, permissions, teamRole]);

  const openDraft = useCallback(() => {
    if (isRequestDraft(drawRequest?.status) && drawRequestId)
      navigate(`/projects/${projectId}/requests/${drawRequestId}/${RequestActions.SUBMISSION}/`);
  }, [projectId, drawRequestId, drawRequest?.is_resubmit, drawRequest?.status]);

  return {
    continueDraftButtonLabel,
    openDraft,
  };
};
