import { useMutation, useQueries, useQueryClient } from 'react-query';
import {
  HookState,
  IDrawRequest,
  PatchDrawRequestParam,
  PermissionNamesEnums,
  QueryNamesEnums,
} from '@interfaces';
import { getDrawRequest, patchDrawRequest } from '@globalService';
import { useParams } from 'react-router-dom';
import {
  currencyFormatter,
  getHookState,
  getRequestRetainageRate,
  isRequestActive,
  isRequestApproved,
  isRequestCompleted,
  isRequestInReview,
  isAllowed,
  percentFormatter,
} from '@utils';
import { Dispatch, useContext, useEffect, useMemo, useState } from 'react';
import { PermissionsContext } from '@context';
import { useDateFormatter, useSafeSnackbar } from '@hooks';
import { DateFieldModel, StringFieldModel, useDateFieldModel, useStringFieldModel } from '@models';

type TransactionDateIdPopupData = {
  external_transaction_id?: string;
  disbursed_at?: Date;
};

export interface ControllerInterface {
  state: HookState;
  borrowerEquity: number;
  constructionHoldback: number;
  feesAmount: number;
  requestProportionText: string;
  drawRequest: IDrawRequest;
  paymentSummaryModalData: PaymentSummaryModalDataEnum | null;
  setPaymentSummaryModalData: Dispatch<React.SetStateAction<PaymentSummaryModalDataEnum | null>>;
  showDisbursement: boolean;
  setShowDisbursement: Dispatch<React.SetStateAction<boolean>>;
  retainageRate: number;
  progressValues: {
    prevRetainageBalance: string;
    retainageBalance: string;
    requestedAmount: string;
    approvedAmount: string;
  };
  isInReview: boolean;
  transactionDate: string;
  transactionId: string;
  canEditFees: boolean;
  canEditProportion: boolean;
  isRequestApprovedOrCompleted: boolean;
  handleSubmit: ({ external_transaction_id, disbursed_at }: TransactionDateIdPopupData) => void;
  transactionDateField: DateFieldModel;
  transactionIdField: StringFieldModel;
  isLoading: boolean;
}

export enum PaymentSummaryModalDataEnum {
  TRANSACTION_DATE = 'transaction_date',
  TRANSACTION_ID = 'transaction_id',
  FEES = 'fees',
  PROPORTION = 'proportion',
}

export const useRequestPaymentSummary = (): ControllerInterface => {
  const { projectId, drawRequestId } = useParams();
  const [paymentSummaryModalData, setPaymentSummaryModalData] =
    useState<PaymentSummaryModalDataEnum | null>(null);

  const [showDisbursement, setShowDisbursement] = useState(false);
  const { permissions } = useContext(PermissionsContext);
  const { dateFormatter } = useDateFormatter();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const requestedProjectQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
      queryFn: getDrawRequest.bind(this, { projectId, drawRequestId }),
      enabled: Boolean(drawRequestId),
    },
  ]);

  const drawRequest = useMemo(
    () => requestedProjectQueries[0]?.data,
    [requestedProjectQueries[0]?.data],
  );

  const borrowerEquity = useMemo(
    () => drawRequest?.borrower_equity,
    [drawRequest?.borrower_equity],
  );

  const constructionHoldback = useMemo(
    () => drawRequest?.construction_holdback,
    [drawRequest?.construction_holdback],
  );

  const feesAmount = useMemo(() => drawRequest?.fees_amount || 0, [drawRequest]);

  const requestProportionText = useMemo(() => {
    return `${percentFormatter({ value: drawRequest?.construction_holdback_rate })}/${percentFormatter({ value: drawRequest?.borrower_equity_rate })} Loan to equity`;
  }, [drawRequest]);

  const progressValues = useMemo(
    () => ({
      prevRetainageBalance: currencyFormatter(
        drawRequest?.totals?.all?.previous_retainage_balance_to_date || 0,
      ),
      retainageBalance: currencyFormatter(drawRequest?.totals?.all?.retainage_balance_to_date || 0),
      requestedAmount: currencyFormatter(drawRequest?.requested_amount || 0),
      approvedAmount: currencyFormatter(drawRequest?.approved_amount || 0),
    }),
    [drawRequest],
  );

  const patchDrawRequestMutation = useMutation<Response, Error, PatchDrawRequestParam>(
    patchDrawRequest,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmit = ({ external_transaction_id, disbursed_at }: TransactionDateIdPopupData) => {
    patchDrawRequestMutation.mutateAsync({
      id: projectId,
      drawRequest: drawRequestId,
      ...(external_transaction_id && { external_transaction_id }),
      ...(disbursed_at && { disbursed_at: disbursed_at.toISOString() }),
    });
    setPaymentSummaryModalData(null);
  };

  const retainageRate = useMemo(() => getRequestRetainageRate(drawRequest), [drawRequest]);

  const isInReview = useMemo(() => isRequestInReview(drawRequest?.status), [drawRequest]);

  const isActiveDrawRequest = useMemo(
    () => isRequestActive(drawRequest?.status),
    [drawRequest?.status],
  );

  const transactionDate = useMemo(
    () => dateFormatter({ date: drawRequest?.disbursed_at }),
    [drawRequest],
  );

  const transactionId = useMemo(() => drawRequest?.external_transaction_id, [drawRequest]);

  const canEditFees = useMemo(
    () => isActiveDrawRequest && isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [isActiveDrawRequest, permissions],
  );

  const canEditProportion = useMemo(
    () => isActiveDrawRequest && isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [isActiveDrawRequest, permissions],
  );

  const isRequestApprovedOrCompleted = useMemo(
    () => isRequestApproved(drawRequest?.status) || isRequestCompleted(drawRequest?.status),
    [drawRequest?.status],
  );

  const transactionDateField = useDateFieldModel({
    initValue: transactionDate ? new Date(transactionDate) : null,
  });

  const transactionIdField = useStringFieldModel({
    initValue: transactionId,
  });

  useEffect(() => {
    transactionDateField.setValue(transactionDate ? new Date(transactionDate) : null);
  }, [transactionDate]);

  useEffect(() => {
    transactionIdField.setValue(transactionId);
  }, [transactionId]);

  return {
    state: getHookState(requestedProjectQueries),
    borrowerEquity,
    constructionHoldback,
    feesAmount,
    requestProportionText,
    drawRequest,
    paymentSummaryModalData,
    setPaymentSummaryModalData,
    retainageRate,
    progressValues,
    isInReview,
    transactionDate,
    transactionId,
    canEditFees,
    canEditProportion,
    showDisbursement,
    setShowDisbursement,
    isRequestApprovedOrCompleted,
    handleSubmit,
    transactionDateField,
    transactionIdField,
    isLoading: patchDrawRequestMutation.isLoading,
  };
};
