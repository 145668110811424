import React from 'react';
import { Stack, Typography } from '@mui/material';

import {
  CenteredStyledBox,
  CustomAutocomplete,
  CustomTextField,
  IsProductionBuildCheckbox,
} from '@components';
import * as Controller from './controller';
import { states } from '@constants';

const PropertyDetails = ({
  propertyDetailsFields,
  isProductionBuildProject,
  setIsProductionBuildProject,
}) => {
  const {
    address_1,
    city,
    state,
    zip_code,
    project_type,
    property_existing_type,
    property_proposed_type,
    scope_of_work,
    exit_strategy,
    sqft_existing_value,
    sqft_proposed_value,
  } = propertyDetailsFields;
  const { projectTypes, propertyTypes, exitStrategies } =
    Controller.usePropertyDetailsCreateProject();

  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Typography variant="h3">Address</Typography>
          <CustomTextField
            field={address_1}
            label="Street address"
            required
            inputProps={{
              'data-cy': 'project__create__street_address__input',
            }}
          />
          <CustomTextField
            field={city}
            label="City"
            required
            inputProps={{
              'data-cy': 'project__create__city__input',
            }}
          />

          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              field={state}
              label="State"
              options={states}
              required
              inputProps={{
                'data-cy': 'project__create__state__input',
              }}
            />

            <CustomTextField
              field={zip_code}
              label="ZIP code"
              validationText="Please enter a valid 5-digit zip code"
              required
              inputProps={{
                'data-cy': 'project__create__zip_code__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Typography variant="h3">General</Typography>
          <CustomAutocomplete
            field={project_type}
            label="Project type"
            options={projectTypes}
            required
            inputProps={{
              'data-cy': 'project__create__project_type__input',
            }}
          />
          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              field={property_existing_type}
              label="Existing property type"
              options={propertyTypes}
              inputProps={{
                'data-cy': 'project__create__existing_property_type__input',
              }}
            />
            <CustomAutocomplete
              field={property_proposed_type}
              label="Property proposed type"
              options={propertyTypes}
              required
              inputProps={{
                'data-cy': 'project__create__proposed_property_type__input',
              }}
            />
          </Stack>
          <IsProductionBuildCheckbox
            checked={isProductionBuildProject}
            wrapperPadding={0}
            onChange={() => {
              setIsProductionBuildProject(!isProductionBuildProject);
            }}
          />
          <Stack>
            <Stack spacing={2}>
              <CustomAutocomplete
                field={exit_strategy}
                label="Exit strategy (optional)"
                options={exitStrategies}
              />

              <CustomTextField
                field={scope_of_work}
                label="Scope of work (optional)"
                rows={3}
                multiline
              />
            </Stack>
            <Typography variant="label">
              Description of the renovation / other work to be performed.
            </Typography>
          </Stack>
        </Stack>
      </CenteredStyledBox>
      {!isProductionBuildProject && (
        <CenteredStyledBox>
          <Stack spacing={2}>
            <Typography variant="h3">Property details</Typography>
            <Stack direction="row" spacing={2}>
              <CustomTextField
                field={sqft_existing_value}
                label="Existing square footage (optional)"
                inputProps={{
                  'data-cy': 'project__create__existing_sqft__input',
                }}
              />

              <CustomTextField
                field={sqft_proposed_value}
                label="Proposed square footage (optional)"
                inputProps={{
                  'data-cy': 'project__create__proposed_sqft__input',
                }}
              />
            </Stack>
          </Stack>
        </CenteredStyledBox>
      )}
    </Stack>
  );
};

export default PropertyDetails;
