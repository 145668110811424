import { useQuery } from 'react-query';
import { IProjectProperty, MilestoneTag, QueryNamesEnums } from '@interfaces';
import { getProjectBuildings, getProjectMilestoneTags, getProjectModels } from '@globalService';

export const useProjectQueries = (projectId: string, isPHBProject: boolean) => {
  const projectModelsQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
    getProjectModels.bind(this, projectId),
    { enabled: isPHBProject },
  );

  const projectBuildingQuery = useQuery<{ results: IProjectProperty[]; count: number }, Error>(
    [QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }],
    getProjectBuildings.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  const projectMilestoneTagsQuery = useQuery<{ results: MilestoneTag[] }, Error>(
    [QueryNamesEnums.GET_PROJECT_MILESTONE_TAGS, { projectId }],
    getProjectMilestoneTags.bind(this, projectId),
    { enabled: Boolean(projectId) },
  );

  return {
    projectModelsQuery,
    projectBuildingQuery,
    projectMilestoneTagsQuery,
  };
};
