import React, { useContext, useCallback, useMemo } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { PermissionsContext } from '@context';
import { checkIsCustomerSuccess, isAllowed } from '@utils';
import { PermissionNamesEnums } from '@interfaces';

const uploadedByColumn = ({ isLoading, source }) => ({
  Header: 'Uploaded by',
  accessor: 'created_by.full_name',
  disableFilters: true,
  disableSortBy: true,
  Cell: ({ cell: { value }, row }) => {
    const { permissions } = useContext(PermissionsContext);
    const { created_by_service_name, created_by } = row?.original || {};

    const isUploadedByCustomerSuccess = useMemo(
      () => checkIsCustomerSuccess(created_by?.primary_role),
      [created_by?.primary_role],
    );

    const hasCSPermission = useMemo(
      () => isAllowed(PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, permissions),
      [permissions],
    );

    const getUploadedBy = useCallback(() => {
      if (isUploadedByCustomerSuccess) {
        return hasCSPermission ? value : 'Trustpoint';
      }
      return created_by_service_name || value;
    }, [isUploadedByCustomerSuccess, hasCSPermission, value, created_by_service_name]);

    if (isLoading) {
      return <Skeleton />;
    }

    return (
      <Typography variant="body3" data-cy={`${source}__uploaded_by__value`}>
        {getUploadedBy()}
      </Typography>
    );
  },
});

export default uploadedByColumn;
