import { useContext, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { HookState, ICompany, IUser, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { getHookState, isAllowed } from '@utils';
import { getMyCompany, getCompanyUsers } from '@globalService';
import { PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  users: IUser[];
  columns: string[];
  handleSearchSubmit: (value: string) => void;
  clearSearch: () => void;
  search: string;
};

export const useCompanyUsers = (): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const [search, setSearch] = useState('');
  const handleSearchSubmit = setSearch;
  const clearSearch = () => Boolean(search) && setSearch('');

  const querySearch = useMemo(() => {
    return search ? `${new URLSearchParams({ q: search }).toString()}` : '';
  }, [search]);

  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const companyUsersQuery = useQuery<{ results: IUser[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_USERS, { companyId: companyQuery?.data?.id, querySearch }],
    getCompanyUsers.bind(this, { companyId: companyQuery?.data?.id, querySearch }),
    { enabled: Boolean(companyQuery?.data?.id) },
  );

  const columns = useMemo(() => {
    const initColumns = ['icon', 'name', 'email', 'teams', 'lastOnline', 'status'];
    if (
      isAllowed(PermissionNamesEnums.COMPANY_USERS_EDIT, permissions) ||
      isAllowed(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions)
    )
      initColumns.push('optionsMenu');
    return initColumns;
  }, [permissions]);

  return {
    state: getHookState(companyUsersQuery),
    users: companyUsersQuery.data?.results?.map((x) => ({ ...x, company: companyQuery?.data })),
    columns,
    handleSearchSubmit,
    clearSearch,
    search,
  };
};
