import React, { FC } from 'react';
import { Divider, Stack, Typography } from '@mui/material';
import { IDocument, IProjectDocument, MediaFile, ITablePagination } from '@interfaces';
import { DocumentSummary, LabelAndValue, TablePaginationNew } from '@components';
import { documentCanBeProcessed } from '@utils';
import NoDocuments from '@assets/no_documents.svg';

const DocumentSummaryList: FC<{
  projectName?: string;
  source: string;
  rows?: IProjectDocument[] | IDocument[];
  documentId?: string;
  openPdfViewer?: (file: IProjectDocument | MediaFile) => void;
  tablePagination?: ITablePagination;
  documentsCount?: number;
}> = ({
  projectName,
  source,
  rows = [],
  documentId,
  openPdfViewer,
  tablePagination,
  documentsCount,
}) => {
  const docsToDisplay = documentId ? rows.filter((doc) => doc.id === documentId) : rows;
  return (
    <Stack sx={{ height: '100%', px: 2 }}>
      {projectName && (
        <LabelAndValue
          label="Project:"
          text={projectName}
          size="body2"
          justifyContent="flex-start"
          marginRightAfterLabel={false}
        />
      )}
      {!docsToDisplay.length && (
        <Stack
          sx={{ height: '100%', justifyContent: 'center', alignItems: 'center', mb: 12 }}
          spacing={3}
        >
          <img src={NoDocuments} alt="no files" />
          <Typography variant="body2SemiBold">No files yet</Typography>
        </Stack>
      )}
      {!!docsToDisplay.length && documentsCount && (
        <Stack alignItems="flex-end">
          <TablePaginationNew
            page={tablePagination.page}
            rowsPerPage={tablePagination.rowsPerPage}
            rowsPerPageOptions={tablePagination.rowsPerPageOptions}
            itemsCount={documentsCount}
            onPageChange={tablePagination.onPageChange}
            onRowsPerPageChange={tablePagination.onRowsPerPageChange}
            source={source}
          />
        </Stack>
      )}
      {docsToDisplay?.map((row: IProjectDocument | IDocument) => {
        return (
          <Stack key={row.id}>
            <DocumentSummary
              row={row as IProjectDocument}
              source={`${source}__item`}
              canBeProcessed={documentCanBeProcessed(row)}
              openPdfViewer={openPdfViewer}
            />
            <Divider sx={{ pt: 2 }} />
          </Stack>
        );
      })}
    </Stack>
  );
};

export default DocumentSummaryList;
