import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Grid2, Stack, Tooltip, Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { isMobileOnly } from 'react-device-detect';
import { colors } from '@theme';
import { CompanyLink, RealTimeUsers, ServiceMessage, StatusChip } from '@components';
import { HookState, PermissionNamesEnums } from '@interfaces';
import * as Controller from './controller';
import { isAllowed } from '@utils';
import { PermissionsContext } from '@context';

const Bullet = styled('span')({
  margin: '8px',
  fontSize: '1.25rem',
  color: colors.text.link,
});

const ProjectHeader = () => {
  const { permissions } = useContext(PermissionsContext);
  const {
    state,
    projectName,
    statusValue,
    projectBorrowerCompany,
    projectInvestorCompany,
    projectCustomerCompany,
    isCurrentProjectActive,
    status_change_reason,
    teamsIsLoading,
  } = Controller.useProjectHeader();
  switch (state) {
    case HookState.FETCHING: {
      return (
        <Box pt={2}>
          <Skeleton width="40%" variant="rectangular" height={40} />
          <Skeleton width="20%" />
          <Skeleton width="20%" />
        </Box>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="project" />;
    }

    case HookState.SUCCESS: {
      return (
        <Box>
          <Stack
            direction={isMobileOnly ? 'column' : 'row'}
            spacing={2}
            sx={{ ...(isMobileOnly && { mb: 1 }) }}
          >
            <Typography
              variant="h1"
              style={{ color: colors.text.heading }}
              data-cy="project__overview__name__value"
            >
              {projectName}
            </Typography>

            {!isCurrentProjectActive && statusValue && (
              <Tooltip
                title={
                  <>
                    Reason:{' '}
                    <Typography
                      variant="body3"
                      dangerouslySetInnerHTML={{ __html: status_change_reason }}
                    />
                  </>
                }
              >
                <span>
                  <StatusChip
                    color={statusValue.color}
                    backgroundColor={statusValue.backgroundColor}
                    label={statusValue.label}
                    size="medium"
                  />
                </span>
              </Tooltip>
            )}
          </Stack>

          {!teamsIsLoading && (
            <Grid2 container alignItems="baseline">
              {projectBorrowerCompany?.name && (
                <>
                  <CompanyLink
                    name={projectBorrowerCompany?.name}
                    companyId={projectBorrowerCompany?.id}
                    source="project__overview__borrower"
                  />

                  <Bullet>&#8226;</Bullet>
                </>
              )}
              <CompanyLink
                name={projectCustomerCompany?.name}
                companyId={projectCustomerCompany?.id}
                source="project__overview__lender"
              />
              {isAllowed(PermissionNamesEnums.INVESTOR_VIEW, permissions) &&
                projectInvestorCompany?.id && (
                  <>
                    <Bullet>&#8226;</Bullet>
                    <CompanyLink
                      name={projectInvestorCompany?.name}
                      companyId={projectInvestorCompany?.id}
                      source="project__overview__investor"
                    />
                  </>
                )}
              <RealTimeUsers />
            </Grid2>
          )}
        </Box>
      );
    }

    default:
      return null;
  }
};

export default ProjectHeader;
