import { useCallback, useContext, useMemo } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { ILocalFee, IProject, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { isCreatedProject, isAllowed } from '@utils';
import { updateProjectFields } from '@globalService';
import { PermissionsContext } from '@context';
import { IFeesControllerInterface, useEditPaymentConfigurationType, useFees } from '@hooks';
import { useLocation } from 'react-router-dom';

interface ControllerInterface {
  exitPath: string;
  isSubmitting: boolean;
  handleSubmitClick: (fees: ILocalFee[]) => void;
  feesController: IFeesControllerInterface;
  isUpdated: boolean;
  canEditFees: boolean;
}

export const usePayments = ({ project }: { project: IProject }): ControllerInterface => {
  const queryClient = useQueryClient();
  const { permissions } = useContext(PermissionsContext);
  const { enqueueSnackbar } = useSnackbar();
  const { state: locationState } = useLocation();
  const { configurationType } = useEditPaymentConfigurationType({
    initialConfigurationType: project?.payment_configuration_type,
  });
  const feesController = useFees();

  const updateProjectPaymentConfig = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: Partial<IProject>;
    }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT);
      queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
      // set initial value if mutation failed
    },
  });

  const isEditable = useMemo(
    () =>
      isCreatedProject(project.status) &&
      isAllowed(PermissionNamesEnums.PROJECTS_DETAILS_EDIT, permissions),
    [project.status, permissions],
  );

  const exitPath = useMemo(
    () => locationState?.['prevUrl'] || `/projects/${project.id}/overview`,
    [locationState, project.id],
  );

  const handleSubmitClick = useCallback(async () => {
    await updateProjectPaymentConfig.mutateAsync({
      projectId: project.id,
      json: {
        payment_configuration_type: configurationType,
        fees: feesController.fees.filter((fee) => fee.amount && fee.name.trim()),
      },
    });
    return true;
  }, [updateProjectPaymentConfig, project.id, project, configurationType, feesController.fees]);

  const canEditFees = useMemo(
    () => isAllowed(PermissionNamesEnums.PAYMENTS_MARK_AS_PAID, permissions),
    [permissions],
  );

  const isUpdated = useMemo(
    () =>
      (isEditable || canEditFees) &&
      (Boolean(project?.payment_configuration_type !== configurationType) ||
        feesController.isFeesUpdated),
    [
      project?.payment_configuration_type,
      configurationType,
      feesController.isFeesUpdated,
      project?.payment_configuration_comment,
      isEditable,
      canEditFees,
    ],
  );

  return {
    exitPath,
    isSubmitting: updateProjectPaymentConfig.isLoading,
    handleSubmitClick,
    feesController,
    isUpdated,
    canEditFees,
  };
};
