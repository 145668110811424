import React, { FC, memo, useContext } from 'react';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { Typography, Stack, Skeleton } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const RetainageApprovedHoldback: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3"
        data-cy={`${source}__body__retainage_approved_amount_holdback__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(row.retainage_approved_amount_holdback, '-')}
      </Typography>
    </Stack>
  );
};

const Memoized = memo(RetainageApprovedHoldback);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3SemiBold"
        data-cy={`${source}__footer__retainage_approved_amount_holdback__value`}
      >
        {currencyFormatter(row.retainage_approved_amount_holdback)}
      </Typography>
    </Stack>
  );
};

const retainageApprovedHoldback: MilestoneListColumnType = {
  name: 'retainage_approved_amount_holdback',
  columnText: 'Retainage heldback for this draw',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount retainage heldback for this draw ($)">
      <ColumnLabel>Retainage heldback</ColumnLabel>
      <ColumnLabel>for this draw</ColumnLabel>
    </HeaderText>
  ),
};

export default retainageApprovedHoldback;
