import React, { FC, useCallback, useContext, useMemo } from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { IconButtonWithTooltip } from '@components';
import { UnitsContext } from '../../../sections/NewProjectV2/components/PropertyDetails';
import { CommonRowType } from '@interfaces';

const DeleteIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const { deleteProperty } = useContext(UnitsContext);

  const isEditable = useMemo(() => row.isEditableV2, [row]);
  const deleteCallback = useCallback(() => deleteProperty(row.id), [row.id]);
  if (row.parentId) return null;
  return (
    <IconButtonWithTooltip disabled={!isEditable} onClick={deleteCallback} tooltipText="Delete">
      <DeleteIcon size={24} color={colors.status.error.medium} />
    </IconButtonWithTooltip>
  );
};

const deleteItem: MilestoneListColumnTypeV2 = {
  field: 'delete_unit',
  headerAlign: 'right',
  headerClassName: 'icon',
  headerName: 'Actions',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <DeleteIconRow row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default deleteItem;
