import React, { FC } from 'react';
import {
  Autocomplete,
  Button,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import snakeCase from 'lodash/snakeCase';
import { StyledBox } from '@components';
import { IPropertyDetailName, IPropertyDetailsFields } from '@interfaces';
import { ArrowRightIcon, DeleteIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { isSQFTDetail } from '@utils';
import { CreateByModelsTable, CreateByUnitsTable } from '../../sections';

const PropertyDetailsTable: FC<{
  isEditable: boolean;
  propertyDetails: IPropertyDetailsFields;
  isCreateByModels?: boolean;
  isPHBProject: boolean;
  source: string;
  showAddPropertyDetailButton?: boolean;
}> = ({
  isEditable,
  propertyDetails,
  isCreateByModels,
  isPHBProject,
  source,
  showAddPropertyDetailButton = true,
}) => {
  return (
    <>
      {!isPHBProject && (
        <StyledBox width="100%">
          <Stack direction="row" justifyContent="space-between" sx={{ mb: 2 }}>
            <Typography variant="h3">Property details</Typography>

            {showAddPropertyDetailButton && (
              <Button
                size="small"
                onClick={propertyDetails.add}
                disabled={!isEditable}
                data-cy={`${source}__add_property_detail__button`}
                sx={{ ml: 2 }}
              >
                Add property detail
              </Button>
            )}
          </Stack>

          {propertyDetails?.list?.map((detail, index) => (
            <Stack direction="row" spacing={2} sx={{ mb: 2 }} key={index} alignItems="center">
              <Autocomplete
                value={{ name: detail?.name, id: detail?.id } as IPropertyDetailName}
                id={detail?.id?.toString()}
                options={propertyDetails.names as IPropertyDetailName[]}
                onChange={(_: React.SyntheticEvent, newValue: IPropertyDetailName) => {
                  propertyDetails.update(index, {
                    ...detail,
                    name: newValue?.name,
                    id: newValue?.id,
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Property detail name"
                    size="small"
                    sx={{ width: 260 }}
                    inputProps={{
                      ...params.inputProps,
                      'data-cy': `${source}__property_detail_name__input`,
                    }}
                  />
                )}
                getOptionLabel={(option: IPropertyDetailName) => option?.name}
                clearIcon={null}
                isOptionEqualToValue={(option: IPropertyDetailName, value: IPropertyDetailName) =>
                  option.name === value.name
                }
                disabled={!isEditable || isSQFTDetail(detail.name)}
                renderOption={renderOption}
              />

              <TextField
                fullWidth
                variant="outlined"
                label="Existing value"
                value={detail.existing_value || ' '}
                sx={{ width: 260 }}
                size="small"
                onChange={(e) =>
                  propertyDetails.update(index, { ...detail, existing_value: e.target.value })
                }
                data-cy={`${source}__property_detail_existing__input`}
                disabled={!isEditable}
              />
              <ArrowRightIcon size={20} />
              <TextField
                fullWidth
                variant="outlined"
                label="Proposed value"
                value={detail.proposed_value || ' '}
                sx={{ width: 260 }}
                size="small"
                onChange={(e) =>
                  propertyDetails.update(index, { ...detail, proposed_value: e.target.value })
                }
                data-cy={`${source}__property_detail_proposed__input`}
                disabled={!isEditable}
              />
              {!isSQFTDetail(detail.name) && (
                <IconButton
                  onClick={() => propertyDetails.delete(index)}
                  data-cy={`${source}__property_detail__delete__button`}
                  disabled={!isEditable}
                >
                  <DeleteIcon size={20} color={colors.status.error.medium} />
                </IconButton>
              )}
            </Stack>
          ))}
        </StyledBox>
      )}
      {isPHBProject && (
        <StyledBox>
          <Typography sx={{ ml: 2 }} variant="h2">
            Budget naming & Structure
          </Typography>
          {isCreateByModels ? (
            <CreateByModelsTable isEditable={false} />
          ) : (
            <CreateByUnitsTable isEditable={false} />
          )}
        </StyledBox>
      )}
    </>
  );
};

export default PropertyDetailsTable;

const renderOption = (props, option) => {
  // Check if the option is not a string and has a `disabled` property set to true
  if (option.disabled) {
    // Modify the props to prevent selection
    const newProps = {
      ...props,
      style: {
        ...props.style,
        color: 'grey', // Visual cue for disabled option.
      },
      onClick: (event) => {
        // Prevent the selection
        event.stopPropagation();
      },
    };

    return (
      <Tooltip key={option.id} title="This option is already choosen">
        <li {...newProps} data-cy={`dropdown_option_${snakeCase(option.name)}`}>
          {option.name}
        </li>
      </Tooltip>
    );
  }

  // Render normal options
  return (
    <li {...props} data-cy={`dropdown_option_${snakeCase(option.name)}`} key={option.id}>
      {option.name}
    </li>
  );
};
