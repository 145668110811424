import {
  deleteProjectDrawRequestMilestoneByIdV2,
  patchProjectDrawRequestMilestoneByIdV2,
  postProjectDrawRequestMilestonesV2,
  postProjectMilestoneV2,
} from '@globalService';
import { QueryNamesEnums } from '@interfaces';

const postProjectMilestoneMutation = (projectId) => ({
  api: postProjectMilestoneV2,
  invalidation: {
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
  },
});

const addToRequest = (projectId, requestId) => ({
  api: postProjectDrawRequestMilestonesV2,
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS]: { projectId, requestId },
  },
});

const editInRequest = (projectId, requestId) => ({
  api: patchProjectDrawRequestMilestoneByIdV2,
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS]: { projectId, requestId },
  },
});

const deleteInRequest = (projectId, requestId) => ({
  api: deleteProjectDrawRequestMilestoneByIdV2,
  invalidation: {
    [QueryNamesEnums.GET_DRAW_REQUEST]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES]: { projectId, drawRequestId: requestId },
    [QueryNamesEnums.GET_PROJECT_MILESTONES]: { projectId },
  },
});

export default {
  addToRequest,
  editInRequest,
  deleteInRequest,
  postProjectMilestoneMutation,
};
