enum ProjectNamesEnum {
  GET_PROJECT = 'GET_PROJECT',
  GET_PROJECT_COMMENTS = 'GET_PROJECT_COMMENTS',
  GET_COMMENT_THREAD = 'GET_COMMENT_THREAD',
  GET_PROJECT_FUNDS = 'GET_PROJECT_FUNDS',
  GET_PROJECT_MILESTONES_COLUMNS = 'GET_PROJECT_MILESTONES_COLUMNS',
  GET_PROJECT_PROGRESS = 'GET_PROJECT_PROGRESS',
  GET_PROJECT_BUILDING = 'GET_PROJECT_BUILDING',
  GET_PROJECT_SCORES = 'GET_PROJECT_SCORES',
  GET_PROJECT_SCORES_NEW = 'GET_PROJECT_SCORES_NEW',
  GET_PROJECT_SETTINGS = 'GET_PROJECT_SETTINGS',
  GET_PROJECT_DOCUMENT_TYPES = 'GET_PROJECT_DOCUMENT_TYPES',
  GET_PROJECT_DOCUMENT_TYPES_BY_NAME = 'GET_PROJECT_DOCUMENT_TYPES_BY_NAME',
  GET_PROJECT_DOCUMENT_TEMPLATE_TYPE = 'GET_PROJECT_DOCUMENT_TEMPLATE_TYPE',
  GET_PROJECT_MILESTONES = 'GET_PROJECT_MILESTONES',
  GET_PROJECT_MILESTONE = 'GET_PROJECT_MILESTONE',
  GET_PROJECT_MILESTONE_DOCS = 'GET_PROJECT_MILESTONE_DOCS',
  GET_PROJECT_MILESTONE_GROUP_DOCS = 'GET_PROJECT_MILESTONE_GROUP_DOCS',
  GET_PROJECT_DOCUMENTS = 'GET_PROJECT_DOCUMENTS',
  GET_PROJECT_DOCUMENT_BY_ID = 'GET_PROJECT_DOCUMENT_BY_ID',
  GET_PROJECT_TYPES = 'GET_PROJECT_TYPES',
  GET_PROJECT_PROPERTY_TYPES = 'GET_PROJECT_PROPERTY_TYPES',
  GET_PROJECT_PROPERTY_DETAILS_NAMES = 'GET_PROJECT_PROPERTY_DETAILS_NAMES',
  GET_PROJECT_EXIT_STRATEGIES = 'GET_PROJECT_EXIT_STRATEGIES',
  GET_PROJECT_COMPANIES = 'GET_PROJECT_COMPANIES',
  GET_PROJECT_TEAMS = 'GET_PROJECT_TEAMS',
  GET_PROJECT_PERMISSIONS = 'GET_PROJECT_PERMISSIONS',
  GET_PROJECT_PHOTOS = 'GET_PROJECT_PHOTOS',
  GET_PROJECT_CHECKLIST_TEMPLATES = 'GET_PROJECT_CHECKLIST_TEMPLATES',
  GET_PROJECTS_TEAMS = 'GET_PROJECTS_TEAMS',
  GET_PROJECTS_USERS = 'GET_PROJECTS_USERS',
  GET_PROJECT_AUDIT_LOG = 'GET_PROJECT_AUDIT_LOG',
  GET_PROJECT_BUILDING_MODELS = 'GET_PROJECT_BUILDING_MODELS',
  GET_PROJECT_MILESTONE_TAGS = 'GET_PROJECT_MILESTONE_TAGS',
  GET_PROJECT_WATCHERS = 'GET_PROJECT_WATCHERS',
  GET_PROJECT_ROLES = 'GET_PROJECT_ROLES',
  GET_PROJECT_FUNDING_SOURCES = 'GET_PROJECT_FUNDING_SOURCES',
}

enum DrawRequestNamesEnum {
  GET_DRAW_REQUEST_FOR_APPROVAL = 'GET_DRAW_REQUEST_FOR_APPROVAL',
  GET_PROJECT_DRAW_REQUEST_LIST = 'GET_PROJECT_DRAW_REQUEST_LIST',
  GET_DRAW_REQUEST = 'GET_DRAW_REQUEST',
  GET_DRAW_REQUEST_TOTALS = 'GET_DRAW_REQUEST_TOTALS',
  GET_DRAW_REQUEST_MILESTONES = 'GET_DRAW_REQUEST_MILESTONES',
  GET_DRAW_REQUEST_MILESTONE_GROUP = 'GET_DRAW_REQUEST_MILESTONE_GROUP',
  GET_DRAW_REQUEST_MILESTONE = 'GET_DRAW_REQUEST_MILESTONE',
  GET_DRAW_REQUEST_MILESTONES_COLUMNS = 'GET_DRAW_REQUEST_MILESTONES_COLUMNS',
  GET_DRAW_REQUEST_DOCUMENTS_TYPES = 'GET_DRAW_REQUEST_DOCUMENTS_TYPES',
  GET_DRAW_REQUEST_DOCUMENTS = 'GET_DRAW_REQUEST_DOCUMENTS',
  GET_DRAW_REQUEST_LIST = 'GET_DRAW_REQUEST_LIST',
  GET_DRAW_REQUEST_LIST_STATS = 'GET_DRAW_REQUEST_LIST_STATS',
  GET_DRAW_REQUEST_PHOTOS = 'GET_DRAW_REQUEST_PHOTOS',
  GET_DRAW_REQUEST_AUDIT_LOG = 'GET_DRAW_REQUEST_AUDIT_LOG',
  GET_DRAW_REQUEST_REVIEWS = 'GET_DRAW_REQUEST_REVIEWS',
  GET_DRAW_REQUEST_MILESTONE_DOCS = 'GET_DRAW_REQUEST_MILESTONE_DOCS',
  GET_DRAW_REQUEST_MILESTONE_GROUP__DOCS = 'GET_DRAW_REQUEST_MILESTONE_GROUP__DOCS',
  GET_DRAW_REQUEST_FUNDING_SOURCES = 'GET_DRAW_REQUEST_FUNDING_SOURCES',
}

enum InspectionNamesEnum {
  GET_DRAW_REQUEST_INSPECTIONS = 'GET_DRAW_REQUEST_INSPECTIONS',
  GET_PROJECT_INSPECTIONS = 'GET_PROJECT_INSPECTIONS',
  GET_PROJECT_INSPECTION_BY_ID = 'GET_PROJECT_INSPECTION_BY_ID',
  GET_INSPECTION_AGENCIES = 'GET_INSPECTION_AGENCIES',
  GET_INSPECTION_AGENCIES_BY_PROJECT = 'GET_INSPECTION_AGENCIES_BY_PROJECT',
  GET_INSPECTIONS_LIST = 'GET_INSPECTIONS_LIST',
  GET_INSPECTION_MILESTONES = 'GET_INSPECTION_MILESTONES',
  GET_INSPECTION_DOCUMENTS = 'GET_INSPECTION_DOCUMENTS',
  GET_INSPECTION_MILESTONE_DOCS = 'GET_INSPECTION_MILESTONE_DOCS',
  GET_INSPECTION_MILESTONE_GROUP_DOCS = 'GET_INSPECTION_MILESTONE_GROUP_DOCS',
}

enum ChecklistNamesEnum {
  GET_PROJECT_CHECKLIST = 'GET_PROJECT_CHECKLIST',
  GET_DRAW_REQUEST_ITEM_CHECKLIST = 'GET_DRAW_REQUEST_ITEM_CHECKLIST',
  GET_CHECKLIST_REASONS = 'GET_CHECKLIST_REASONS',
}

enum UISettingsNamesEnum {
  GET_UI_SETTINGS = 'GET_UI_SETTINGS',
  GET_UI_SETTINGS_INITIAL = 'GET_UI_SETTINGS_INITIAL',
  GET_TRANSLOADIT_TEMPLATE = 'GET_TRANSLOADIT_TEMPLATE',
  GET_TRANSLOADIT_SIGNATURE = 'GET_TRANSLOADIT_SIGNATURE',
  GET_UI_SETTINGS_SERVICES_TYPE = 'GET_UI_SETTINGS_SERVICES_TYPE',
  GET_UI_SETTINGS_LOANS_TYPE = 'GET_UI_SETTINGS_LOANS_TYPE',
}

enum SystemConfigEnum {
  GET_SYSTEM_CONFIG = 'GET_SYSTEM_CONFIG',
  GET_WORKFLOW_REASONS = 'GET_WORKFLOW_REASONS',
  GET_PLATFORM_POLICIES_TEMPLATES = 'GET_PLATFORM_POLICIES_TEMPLATES',
}

enum UserEnum {
  GET_USER = 'GET_USER',
  GET_USER_NOTIFICATIONS = 'GET_USER_NOTIFICATIONS',
  GET_ROLES_WITH_PERMISSIONS = 'GET_ROLES_WITH_PERMISSIONS',
}

enum CompanyEnum {
  GET_COMPANY = 'GET_COMPANY',
  GET_MY_COMPANY = 'GET_MY_COMPANY',
  GET_COMPANY_USERS = 'GET_COMPANY_USERS',
  GET_COMPANY_USER = 'GET_COMPANY_USER',
  GET_COMPANY_PROJECTS = 'GET_COMPANY_PROJECTS',
  GET_COMPANY_DASHBOARDS = 'GET_COMPANY_DASHBOARDS',
  GET_COMPANY_POLICIES_TEMPLATES = 'GET_COMPANY_POLICIES_TEMPLATES',
  GET_COMPANY_POLICIES_PROJECTS_LIST = 'GET_COMPANY_POLICIES_PROJECTS_LIST',
  GET_COMPANY_TEAMS = 'GET_COMPANY_TEAMS',
  GET_COMPANY_TEAM_BY_ID = 'GET_COMPANY_TEAM_BY_ID',
  GET_COMPANY_SETTINGS = 'GET_COMPANY_SETTINGS',
  GET_COMPANY_ROLES = 'GET_COMPANY_ROLES',
}

enum PortfolioEnum {
  GET_COMPANIES_BY_ROLE = 'GET_COMPANIES_BY_ROLE',
  GET_COMPANIES = 'GET_COMPANIES',
  GET_COORDINATORS = 'GET_COORDINATORS',
  GET_PROJECTS_STATS = 'GET_PROJECTS_STATS',
  GET_PROJECTS_LIST = 'GET_PROJECTS_LIST',
}

enum ServicesEnum {
  GET_SERVICE_AGENCIES = 'GET_SERVICE_AGENCIES',
  GET_SERVICE_ORDER_DOCUMENTS = 'GET_SERVICE_ORDER_DOCUMENTS',
  GET_PROJECT_SERVICE_ORDER_BY_ID = 'GET_PROJECT_SERVICE_ORDER_BY_ID',
  GET_PROJECT_SERVICE_ORDERS = 'GET_PROJECT_SERVICE_ORDERS',
  GET_DRAW_REQUEST_SERVICE_ORDERS = 'GET_DRAW_REQUEST_SERVICE_ORDERS',
  GET_SERVICE_ORDERS_LIST = 'GET_SERVICE_ORDERS_LIST',
  GET_SERVICE_ORDER_DOCUMENT_TYPES = 'GET_SERVICE_ORDER_DOCUMENT_TYPES',
}

export const QueryNamesEnums = {
  ...ProjectNamesEnum,
  ...DrawRequestNamesEnum,
  ...InspectionNamesEnum,
  ...ChecklistNamesEnum,
  ...UISettingsNamesEnum,
  ...UserEnum,
  ...CompanyEnum,
  ...PortfolioEnum,
  ...SystemConfigEnum,
  ...ServicesEnum,
};
