import { MilestoneListColumnTypeV2 } from './common';

const description: MilestoneListColumnTypeV2 = {
  field: 'description',
  headerName: 'Description',
  description: '',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 200,
  editableByMilestone: (row) => row.isEditableV2 && !row?.parentId,
};

export default description;
