import React, { FC, useContext } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__approved_to_date__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(row.previous_approved_amount_cumulative, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__approved_to_date__value`}>
      {currencyFormatter(row.previous_approved_amount_cumulative, '-')}
    </Typography>
  );
};

const previousApprovedAmountCumulative: MilestoneListColumnType = {
  name: 'previous_approved_amount_cumulative',
  columnText: 'Approved to date ($)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Implied cumulative amount eligible to be paid per the inspector across all line items">
      <ColumnLabel>Approved</ColumnLabel>
      <ColumnLabel> to date ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default previousApprovedAmountCumulative;
