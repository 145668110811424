import React, { FC, memo, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { IconButton } from '@mui/material';
import { MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { ColumnV2Width, CommonRowType, RequestTableTabsEnum } from '@interfaces';
import { PhotoGalleryIconWithIndicator } from '@svgAsComponents';
import { colors } from '@theme';

const InspectionPhotosV2: FC<{ row: CommonRowType }> = ({ row }) => {
  const { inspectionId } = useParams();
  const { updateRightDrawer, source } = useContext(TableContext);

  return (
    <IconButton
      data-cy={`${source}__body__inspection_photos_gallery__icon`}
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.PHOTOS,
              inspectionId,
            })
          : row?.updateRightDrawer({ tab: RequestTableTabsEnum.PHOTOS })
      }
    >
      <PhotoGalleryIconWithIndicator
        color={colors.icons.green}
        hasImages={row.documents_preview?.has_photos}
        hasUnseenImages={row.documents_preview?.has_not_seen_photos}
      />
    </IconButton>
  );
};

const Memoized = memo(InspectionPhotosV2);

const inspectionPhotosV2: MilestoneListColumnType = {
  name: 'documents_preview',
  columnText: 'Inspection photos',
  justifyContent: 'center',
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default inspectionPhotosV2;
