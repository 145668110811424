import React, { FC, memo, useContext } from 'react';
import { currencyFormatter } from '@utils';
import { Typography } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

// used only in Draw tab, the same key for the Budget tab is 'adjustments'
const PreviousChangesDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__previous_changes__value__index_${row?.project_milestone?.index}`}
    >
      {currencyFormatter(row.previous_changes, '-')}
    </Typography>
  );
};

const Memoized = memo(PreviousChangesDetails);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__previous_changes__value`}>
      {currencyFormatter(row.previous_estimate_changes || 0)}
    </Typography>
  );
};

const previousChanges: MilestoneListColumnType = {
  name: 'previous_changes',
  columnText: 'Total approved changes ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Cumulative approved changes to original scheduled value">
      <ColumnLabel>Total approved</ColumnLabel>
      <ColumnLabel> changes ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default previousChanges;
