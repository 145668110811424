import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Skeleton, Tooltip, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import {
  approximationEqual,
  canBeEdited,
  checkIsAdmin,
  checkIsInvestor,
  checkIsLender,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  notAllowNegative,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import { TextInputWithTooltip } from '@components';
import { TableContext } from '../controller';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { AuthContext, SettingsContext } from '@context';
import { colors } from '@theme';
import { WarningIcon } from '@svgAsComponents';

const LenderApprovedAmount: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, resetMutation, contingencyMode, source, getLineItemError } =
    useContext(TableContext);
  const error = getLineItemError(row.id, 'approved_amount');

  const approved_amount = useStringFieldModel({
    initError: error,
    initValue: (row['approved_amount'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'approved_amount',
  });

  const { isPHBProject } = useContext(SettingsContext);
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'approved_amount',
    tags,
  });

  const isEditable = useMemo(
    () =>
      canBeEdited({ row, contingencyMode, adjustments_field: 'approved_adjustments', error }) &&
      (checkIsLender(teamRole) || checkIsInvestor(teamRole) || checkIsAdmin(teamRole)),
    [teamRole, row, contingencyMode],
  );

  const debouceEffect = useCallback(
    (props) => {
      apiUpdate({
        milestoneId: row.id,
        value: approved_amount.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(row.approved_amount, approved_amount.floatValue),
        name: 'approved_amount',
        ...props,
      });
    },
    [approved_amount.floatValue, row.approved_amount],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect: debouceEffect,
    handleChange: approved_amount.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { approved_amount: 0 } }),
    afterEffect: () => approved_amount.changeNavigationBlockContext(false),
    delay: isPHBProject ? 1500 : 500,
  });

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable && !(row['approved_amount'] < 0) ? (
    <TextInputWithTooltip
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      value={approved_amount.value}
      onChange={onChange}
      onBlur={onBlur}
      error={Boolean(error)}
      type="currency"
      tooltipText={row.disabled?.reason}
      disabled={row.disabled?.value}
      errorTip={error}
      dataTestName={`${source}__body__approved_amount__value__index_${row?.project_milestone?.index}`}
      iconDataTestName={`${source}__body__approved_amount__error_icon__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <>
      {error && (
        <Tooltip
          placement="top-end"
          title={
            <Typography
              variant="label"
              dangerouslySetInnerHTML={{ __html: error }}
              sx={{ whiteSpace: 'pre-line' }}
            ></Typography>
          }
          sx={{ mr: 'auto' }}
        >
          <Box>
            <WarningIcon color={colors.status.error.medium} size={16} />
          </Box>
        </Tooltip>
      )}
      <Typography
        variant="body3"
        data-cy={`${source}__body__approved_amount__value__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(row.approved_amount, '-')}
      </Typography>
    </>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__approved_amount__value`}>
      {currencyFormatter(row.approved_amount, '-')}
    </Typography>
  );
};

const approvedAmount: MilestoneListColumnType = {
  name: 'approved_amount',
  columnText: 'Approved for this draw ($)',
  isEditable: true,
  renderCell: ({ row }) => <LenderApprovedAmount row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount approved to fund for this draw">
      <ColumnLabel>Approved for</ColumnLabel>
      <ColumnLabel> this draw ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default approvedAmount;
