import { useContext, useEffect, useMemo, useState } from 'react';

import { IProject, IRightDrawerParams, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { PermissionsContext, useGetData } from '@context';
import { isAllowed } from '@utils';

interface ControllerInterface {
  project: Partial<IProject>;
  tab: string;
  tabs: { label: string; value: string }[];
  handleChange: (_event: React.SyntheticEvent, newValue: string) => void;
  TABS: { [key: string]: { label: string; value: string } };
}

const TABS = {
  policies: { label: 'Policies', value: 'policies' },
  documents: { label: 'Documents', value: 'documents' },
  photos: { label: 'Progress photos', value: 'photos' },
  comments: { label: 'Comments', value: 'comments' },
};

export const useRequestQueueRightModal = ({
  projectId,
  activeTab,
  requestId,
  serviceOrderId,
  inspectionId,
}: IRightDrawerParams): ControllerInterface => {
  const { permissions } = useContext(PermissionsContext);
  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: ['name', 'status', 'status_change_reason', 'id'],
    args: { projectId },
  });

  const [tab, setTab] = useState(activeTab || TABS.comments.value);
  const handleChange = (_event: React.SyntheticEvent, newValue: string) => {
    setTab(newValue);
  };

  useEffect(() => {
    if (activeTab) setTab(activeTab);
  }, [activeTab]);

  const withComments = isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions);

  const tabs = useMemo(
    () => [
      ...(isAllowed(PermissionNamesEnums.POLICIES_VIEW, permissions) &&
      requestId &&
      !(inspectionId || serviceOrderId)
        ? [
            {
              label: `${TABS.policies.label}`,
              value: TABS.policies.value,
            },
          ]
        : []),
      {
        label: `${TABS.documents.label}`,
        value: TABS.documents.value,
      },
      ...(!serviceOrderId
        ? [
            {
              label: `${TABS.photos.label}`,
              value: TABS.photos.value,
            },
          ]
        : []),
      ...(withComments
        ? [
            {
              label: `${TABS.comments.label}`,
              value: TABS.comments.value,
            },
          ]
        : []),
    ],
    [permissions, requestId, serviceOrderId, inspectionId],
  );

  return {
    project: project?.data,
    tab,
    tabs,
    handleChange,
    TABS,
  };
};
