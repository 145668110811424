import React from 'react';
import NewProjectV2 from './index';
import NewProject from '../new-project';
import { useLaunchDarklyFlags } from '@context';

const NewProjectContainer = () => {
  const flags = useLaunchDarklyFlags();

  if (!flags) return null;
  if (flags['ENG_9420_number_of_units_for_all_projects']) return <NewProjectV2 />;
  return <NewProject />;
};

export default NewProjectContainer;
