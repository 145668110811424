import React, { memo, FC } from 'react';
import { useParams } from 'react-router-dom';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { StyledLink } from '@components';
import { ColumnV2Width, CommonRowType } from '@interfaces';
import { Stack } from '@mui/material';

const LineItemName: FC<{ row: CommonRowType }> = ({ row }) => {
  const { projectId } = useParams();
  return (
    <Stack justifyContent="flex-end" flexDirection="row" flexWrap="wrap">
      {Array.isArray(row.draw_requests) &&
        row.draw_requests.map((dr, index) => (
          <span key={index}>
            <StyledLink
              variant="body3SemiBold"
              to={`/projects/${projectId}/draws/draw-requests/${dr.id}`}
            >
              {dr.number}
            </StyledLink>
            {index !== row.draw_requests.length - 1 && <span>, </span>}
          </span>
        ))}
    </Stack>
  );
};

const Memoized = memo(LineItemName);

const drawRequestNumbers: MilestoneListColumnType = {
  name: 'draw_requests',
  columnText: 'Draw request #',
  renderCell: ({ row }) => <Memoized row={row} />,
  Header: (
    <HeaderText tooltipText="Draw requests associated with the line item">
      <ColumnLabel>Draw</ColumnLabel>
      <ColumnLabel> request #</ColumnLabel>
    </HeaderText>
  ),
  minWidth: () => ColumnV2Width.TEXT_MIN,
  maxWidth: () => ColumnV2Width.TEXT_MAX,
};

export default drawRequestNumbers;
