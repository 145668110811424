import React, { useEffect, useRef, useMemo } from 'react';
import Slider from 'react-slick';
import { Box, Stack, Theme, useMediaQuery } from '@mui/material';
import { ViewerAPI } from 'react-photo-sphere-viewer';
import { styled } from '@mui/material/styles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { getImageUrl } from '@utils';
import { ImageSizeEnum, IFileRepresentation } from '@interfaces';
import { NextButton, PrevButton } from './CarouselButtons';
import { PanoramaViewer, VideoPlayer } from '@components';

const fixedHeight = 'calc(100vh - 450px)';

interface MediaProps {
  photo: {
    id: string;
    is_panorama?: boolean;
    file_representations: IFileRepresentation;
  };
}

interface ImageSlickCarouselProps {
  slides: Array<MediaProps['photo']>;
  startIndex?: number;
  callbackOnPhotoChange?: (photoId: string) => void;
  activePhoto: MediaProps['photo'];
}

interface SingleSlideProps {
  photo: MediaProps['photo'];
  xxlMediaQuery: boolean;
}

const MediaRenderer = ({ photo }: MediaProps) => {
  if (photo.file_representations.webm) {
    return (
      <Stack sx={{ height: fixedHeight }} justifyContent="center" alignItems="center">
        <VideoPlayer videoUrl={photo.file_representations.webm.url} height="380px" />
      </Stack>
    );
  }

  return (
    <img
      src={
        getImageUrl(ImageSizeEnum.LARGE, photo.file_representations) ||
        getImageUrl(ImageSizeEnum.ORIGINAL, photo.file_representations)
      }
      alt="gallery"
    />
  );
};

const ImageSlickCarousel = ({
  slides,
  startIndex,
  callbackOnPhotoChange,
  activePhoto,
}: ImageSlickCarouselProps) => {
  const ref = useRef<ViewerAPI>(null);
  const xxlMediaQuery = useMediaQuery((theme: Theme) => theme.breakpoints.up('xxl'));

  const settings = useMemo(
    () => ({
      dots: true,
      dotsClass: 'slick-dots slick-dots-custom',
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      adaptiveHeight: true,
      draggable: false,
      nextArrow: <NextButton className="" style={{}} onClick={() => {}} />,
      prevArrow: <PrevButton className="" style={{}} onClick={() => {}} />,
      initialSlide: startIndex,
      className: 'slick-center',
      lazyLoad: 'progressive',
      beforeChange: (_oldIndex: number, newIndex: number) => {
        if (callbackOnPhotoChange && newIndex !== startIndex) {
          callbackOnPhotoChange(slides[newIndex]?.id);
        }
      },
      fade: true,
    }),
    [startIndex, callbackOnPhotoChange, slides],
  );

  useEffect(() => {
    if (activePhoto.is_panorama) {
      const timer = setTimeout(() => {
        ref.current?.setPanorama(
          getImageUrl(ImageSizeEnum.LARGE, activePhoto?.file_representations),
        );
      }, 10);
      return () => clearTimeout(timer);
    }
  }, [activePhoto]);

  if (!slides?.length) {
    return null;
  }

  return (
    <StyledBox sx={{ maxWidth: { xs: '90%', xxl: '1500px' } }}>
      {slides.length > 1 ? (
        <Slider {...settings}>
          {slides.map((photo) => (
            <React.Fragment key={photo.id}>
              {photo.is_panorama ? (
                <Stack sx={{ height: fixedHeight }} justifyContent="center" alignItems="center">
                  {photo.id === activePhoto.id && (
                    <PanoramaViewer
                      ref={ref}
                      height={xxlMediaQuery ? '600px' : '400px'}
                      width="100%"
                    />
                  )}
                </Stack>
              ) : (
                <MediaRenderer photo={photo} />
              )}
            </React.Fragment>
          ))}
        </Slider>
      ) : (
        <SingleSlide photo={slides[0]} ref={ref} xxlMediaQuery={xxlMediaQuery} />
      )}
    </StyledBox>
  );
};

const SingleSlide = React.forwardRef<ViewerAPI, SingleSlideProps>(
  ({ photo, xxlMediaQuery }, ref) => (
    <>
      {photo.is_panorama ? (
        <Stack
          sx={{
            minWidth: { xs: '300px', md: '600px', lg: '750px', xl: '900px', xxl: '1500px' },
          }}
        >
          <PanoramaViewer ref={ref} height={xxlMediaQuery ? '600px' : '400px'} width="100%" />
        </Stack>
      ) : (
        <MediaRenderer photo={photo} />
      )}
    </>
  ),
);

const StyledBox = styled(Box)(() => ({
  '& img': {
    paddingLeft: 30,
    paddingRight: 30,
    maxWidth: '100%',
    maxHeight: fixedHeight,
    minHeight: fixedHeight,
    objectFit: 'contain',
    justifySelf: 'center',
  },
  '& .slick-center': {
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    maxHeight: fixedHeight,
    minHeight: fixedHeight,
  },
  '& .slick-slider .slick-dots': {
    position: 'relative',
    marginBottom: 20,
  },
  '& .slick-slider .slick-dots li.slick-active button:before': {
    color: '#1f4739',
    opacity: 1,
  },
  '& .slick-slider .slick-dots li button:before': {
    width: 10,
    height: 10,
    fontSize: 8,
  },
  '& .slick-slider .slick-dots li': {
    width: 10,
    height: 10,
  },
}));

export default ImageSlickCarousel;
