import React from 'react';

import {
  InviteUsersButton,
  InviteUsersButtonV2,
  LoadingSkeleton,
  SearchBox,
  ServiceMessage,
  StyledBox,
  UsersList,
} from '@components';
import { Grid2 } from '@mui/material';

import { HookState, IUser, PermissionNamesEnums } from '@interfaces';
import { WithPermission } from '@utils';
import { useLaunchDarklyFlags } from '@context';
import * as Controller from './controller';

interface ListComponentsProps {
  columns: string[];
  state: HookState;
  users: IUser[];
}

const ListBlock = ({ state, columns, users }: ListComponentsProps) => {
  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Users" />;
    }

    case HookState.SUCCESS: {
      return <UsersList columns={columns} users={users} />;
    }

    default:
      return null;
  }
};

const CompanyUsers = () => {
  const { state, users, columns, handleSearchSubmit, clearSearch, search } =
    Controller.useCompanyUsers();
  const flags = useLaunchDarklyFlags();

  return (
    <StyledBox sx={{ flex: 12, p: 0 }}>
      <Grid2
        container
        spacing={2}
        sx={{ mb: '24px' }}
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid2 sx={{ maxWidth: 320, mt: 2, ml: 2 }}>
          <SearchBox search={search} onSubmit={handleSearchSubmit} clearSearch={clearSearch} />
        </Grid2>
        <Grid2 sx={{ mt: 2, mr: 2 }}>
          {flags?.['ENG_9494_remove_find_user_button_in_company_settings'] ? (
            <WithPermission
              permissionKey={[
                PermissionNamesEnums.COMPANY_USERS_EDIT,
                PermissionNamesEnums.COMPANY_TEAMS_EDIT,
              ]}
            >
              <InviteUsersButtonV2 dataTestName="company_users__invite_team_member_button" />
            </WithPermission>
          ) : (
            <InviteUsersButton dataTestName="company_users__invite_team_member_button" />
          )}
        </Grid2>
      </Grid2>

      <ListBlock columns={columns} users={users} state={state} />
    </StyledBox>
  );
};

export default CompanyUsers;
