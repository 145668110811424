import React, { FC, memo, useContext, useMemo } from 'react';
import {
  currencyFormatter,
  isRequestDraft,
  isRequestInReview,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { Skeleton, Stack, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType, ProgressionSum } from './common';
import { TableContext } from '../controller';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';

const BalanceDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { requestStatus, source } = useContext(TableContext);
  const isBlocked = useBlockerCell({ milestoneId: row.id });

  const progress = useMemo(
    () =>
      isRequestDraft(requestStatus)
        ? -1 * +(row.requested_amount || 0) + +(row.requested_adjustments || 0)
        : isRequestInReview(requestStatus)
          ? -1 * +(row.approved_amount || 0) + +(row.approved_adjustments || 0)
          : 0,
    [
      requestStatus,
      row.approved_adjustments,
      row.approved_amount,
      row.requested_adjustments,
      row.requested_amount,
    ],
  );

  const sum = useMemo(
    () =>
      isRequestDraft(requestStatus)
        ? row.balance_to_finish || 0
        : (row.balance_to_finish || 0) - progress,
    [row, progress, requestStatus],
  );

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3"
        data-cy={`${source}__body__balance_to_finish__value__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(sum, '-')}
      </Typography>
      <ProgressionSum
        sum={sum}
        progress={progress}
        dataTestName={`${source}__body__balance_to_finish__nextValue__index_${row?.project_milestone?.index}`}
      />
    </Stack>
  );
};

const Memoized = memo(BalanceDetails);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { requestStatus, source } = useContext(TableContext);

  const progress = isRequestDraft(requestStatus)
    ? -1 * +row.requested_amount + +row.requested_budget_change
    : isRequestInReview(requestStatus)
      ? -1 * +row.approved_amount + +row.approved_budget_change
      : 0;

  const sum = isRequestInReview(requestStatus)
    ? row.balance_to_finish - progress
    : row.balance_to_finish;
  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography variant="body3SemiBold" data-cy={`${source}__footer__balance_to_finish__value`}>
        {currencyFormatter(sum)}
      </Typography>
      <ProgressionSum
        sum={sum}
        progress={progress}
        dataTestName={`${source}__footer__balance_to_finish__nextValue`}
      />
    </Stack>
  );
};

const balanceToFinish: MilestoneListColumnType = {
  name: 'balance_to_finish',
  columnText: 'Balance to finish ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Amount remaining of the revised scheduled value">
      <ColumnLabel>Balance</ColumnLabel>
      <ColumnLabel> to finish ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default balanceToFinish;
