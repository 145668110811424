import React, { FC, useContext } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { percentFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__approved_amount_rate__value__index_${row?.project_milestone?.index}`}
    >
      {percentFormatter({ value: row.approved_amount_relative })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__approved_amount_rate__value`}>
      {percentFormatter({ value: row.approved_amount_relative }) || '-'}
    </Typography>
  );
};

const approvedAmountRelative: MilestoneListColumnType = {
  name: 'approved_amount_relative',
  columnText: 'Approved for this draw (%)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="% of amount approved to fund for this draw to revised scheduled value">
      <ColumnLabel>Approved for</ColumnLabel>
      <ColumnLabel> this draw (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default approvedAmountRelative;
