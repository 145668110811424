import { useCallback, useMemo, useState } from 'react';

export const useCommonFilter = (title: string) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const id = useMemo(() => (open ? `${title}-label` : undefined), [title, open]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    if (anchorEl) {
      anchorEl.focus();
    }
    setAnchorEl(null);
  }, [anchorEl]);

  return {
    id,
    anchorEl,
    open,
    handleClick,
    handleClose,
  };
};
