import React, { FC } from 'react';
import { Box, Grid2, Stack, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { colors } from '@theme';
import { ComponentProps } from './interface';
import { PopperWithAutoComplete } from '@components';
import { Button } from './elements';
import { useAsyncFilterData } from './useAsyncFilterData';
import { useCommonFilter } from './useCommonFilter';
import { useStaticFilterData } from './useStaticFilterData';

const FilterButton: FC<ComponentProps> = ({
  title,
  filterKey,
  handleFiltersChange,
  initValues,
  cypressSelector,
  fixedWidth,
  getStaticValues,
  buttonWidth,
  maxWidth,
  minWidth,
  closeOnChange,
  defaultFilterLabel = 'All',
  withPagination,
  getDataParamsPaginated,
  multiple = true,
  textFieldHeight = '32px',
  justifyContent = 'space-between',
}) => {
  const { handleClick, handleClose, anchorEl, open, id } = useCommonFilter(title);

  const { setQ, setPage, nextPageCallback, isLoading, valuesWithExistingData, stringToShow } =
    getDataParamsPaginated
      ? useAsyncFilterData({ initValues, withPagination, getDataParamsPaginated })
      : useStaticFilterData({ initValues, getStaticValues });

  const renderFilterValue = () => (
    <>
      <Typography
        variant="body3SemiBold"
        color={colors.text.medium}
        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
      >
        {initValues?.length ? stringToShow : defaultFilterLabel}
      </Typography>
      <KeyboardArrowDownIcon />
    </>
  );

  return (
    <Grid2 container alignItems="center" sx={{ ...(fixedWidth && { width: fixedWidth }) }}>
      <Box
        sx={{
          maxWidth: fixedWidth || maxWidth || '320px',
          minWidth: fixedWidth || minWidth || '120px',
        }}
      >
        <Button
          onClick={handleClick}
          data-cy={cypressSelector}
          sx={{ ...(buttonWidth && { width: buttonWidth }) }}
        >
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              width: '100%',
              height: textFieldHeight,
              backgroundColor: colors.neutral.lightest,
              borderRadius: '2px',
              p: 1,
              '& svg': { width: 16, height: 16 },
              color: colors.text.medium,
              ...(fixedWidth && { justifyContent }),
            }}
          >
            <Typography variant="body3" color={colors.text.medium} sx={{ whiteSpace: 'nowrap' }}>
              {`${title}:`}
            </Typography>
            {(fixedWidth && (
              <Stack direction="row" sx={{ justifyContent: 'flex-end' }}>
                {renderFilterValue()}
              </Stack>
            )) ||
              renderFilterValue()}
          </Stack>
        </Button>
      </Box>
      <PopperWithAutoComplete
        anchorEl={anchorEl}
        id={id}
        handleClose={handleClose}
        open={open}
        options={valuesWithExistingData}
        closeOnChange={closeOnChange}
        handleValuesChange={(values) => handleFiltersChange(filterKey, values)}
        placeHolder={`Find ${title.toLowerCase()}`}
        values={initValues}
        isLoading={isLoading}
        inputChange={(data) => {
          setQ(data);
          setPage(0);
        }}
        loadNext={!isLoading ? nextPageCallback : null}
        multiple={multiple}
      />
    </Grid2>
  );
};

export default FilterButton;
