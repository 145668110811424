import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { HookState, ServiceActionEnum, PermissionNamesEnums } from '@interfaces';
import {
  isServiceProviderManual,
  isOrderedService,
  isAutomatedServiceProvider,
  WithPermission,
} from '@utils';
import NodeCard from '../NodeCard';
import {
  InspectionLabelAndValue,
  LabelAndValueWithPreload,
  LoadingSkeleton,
  ServiceMessage,
  StyledLink,
  WidgetPaperCard,
} from '@components';
import * as Controller from './controller';

const InspectionCard: FC = () => {
  const controller = Controller.useInspectionCard();

  switch (controller.state) {
    case HookState.FETCHING: {
      return (
        <NodeCard style={{ minHeight: '100%' }} title="Inspection">
          <LoadingSkeleton type="overviewBlock" />
        </NodeCard>
      );
    }
    case HookState.ERROR: {
      return <ServiceMessage text="inspection" />;
    }

    case HookState.SUCCESS: {
      return <InspectionCardBody controller={controller} />;
    }

    default:
      return null;
  }
};

export default InspectionCard;

const InspectionCardBody: FC<{ controller: Controller.ControllerInterface }> = ({ controller }) => {
  const {
    lastInspection,
    isCurrentProjectArchived,
    navigateToInspection,
    daysSinceLastInspectionText,
    isInactiveLastInspection,
    getInspectionLink,
    linkState,
    isFailedInspection,
    isInspectionFetching,
    navigateToOrderInspection,
  } = controller;

  return (
    <WidgetPaperCard
      style={{ minHeight: '100%' }}
      title={
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          sx={{ width: '100%' }}
        >
          <Typography variant="h4">Inspection</Typography>
          {lastInspection && !isFailedInspection && (
            <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_VIEW}>
              <StyledLink
                variant="labelSemiBold"
                to={getInspectionLink(ServiceActionEnum.VIEW)}
                state={linkState}
              >
                See details
              </StyledLink>
            </WithPermission>
          )}
        </Stack>
      }
    >
      <Stack marginTop={2} spacing={1}>
        {lastInspection ? (
          <>
            <LabelAndValueWithPreload
              label="Days since last inspection"
              text={daysSinceLastInspectionText}
              isLoading={isInspectionFetching}
            />
            <InspectionLabelAndValue
              inspection={lastInspection}
              label={`${isInactiveLastInspection ? 'Last' : 'New'} inspection`}
              isLoading={isInspectionFetching}
            />
            <LabelAndValueWithPreload
              label="Agency"
              text={lastInspection.inspection_agency?.display_name || '-'}
              isLoading={isInspectionFetching}
            />
            {isAutomatedServiceProvider(lastInspection?.inspection_agency?.service) && (
              <LabelAndValueWithPreload
                label="External ID"
                text={lastInspection?.provider_order_id || '-'}
                isLoading={isInspectionFetching}
              />
            )}
            {lastInspection.draw_request && (
              <LabelAndValueWithPreload
                label="Linked request"
                text={`Draw request #${lastInspection.draw_request?.number}`}
                isLoading={isInspectionFetching}
              />
            )}
          </>
        ) : (
          <>
            <Typography variant="body3">No inspections yet.</Typography>
          </>
        )}
      </Stack>
      {(!lastInspection || isInactiveLastInspection) && (
        <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_EDIT}>
          <LoadingButton
            onClick={() => navigateToOrderInspection()}
            disabled={isCurrentProjectArchived}
            loading={isInspectionFetching}
            sx={{ marginTop: 3 }}
            data-cy="project__overview__inspection_card__order_inspection__button"
          >
            Order inspection
          </LoadingButton>
        </WithPermission>
      )}
      {isOrderedService(lastInspection?.status) &&
        isServiceProviderManual(lastInspection?.inspection_agency?.service) && (
          <WithPermission permissionKey={PermissionNamesEnums.INSPECTIONS_EDIT}>
            <LoadingButton
              onClick={() => navigateToInspection(ServiceActionEnum.EDIT)}
              disabled={isCurrentProjectArchived}
              loading={isInspectionFetching}
              sx={{ marginTop: 3 }}
              data-cy="inspection_card_enter_inspection_results_button"
            >
              Enter results
            </LoadingButton>
          </WithPermission>
        )}
    </WidgetPaperCard>
  );
};
