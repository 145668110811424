import { PermissionNamesEnums, IPermission } from '@interfaces';

export const filterObjectKeyByPermission = ({ object, key, isAllowed }) => {
  if (!isAllowed && key in object) {
    const { [key]: _, ...rest } = object;
    return rest;
  }
  return object;
};

export const isAllowed = (
  permissionKey: PermissionNamesEnums | PermissionNamesEnums[],
  permissions: IPermission[],
) => {
  if (!permissions?.length) return false;

  if (Array.isArray(permissionKey)) {
    const allKeysPresent = permissionKey.some(
      (key) => permissions.find((permission) => permission.name === key)?.enabled,
    );
    return allKeysPresent;
  } else {
    return permissions.find((permission) => permission.name === permissionKey)?.enabled;
  }
};
