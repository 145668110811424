import { useContext, useMemo, useCallback, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import { HookState, PermissionNamesEnums, QueryNamesEnums, ITeam } from '@interfaces';
import { getHookState, sortTeamsByApprovalLevel, isAllowed } from '@utils';
import { getCompanyTeams } from '@globalService';
import { PermissionsContext } from '@context';

export type ControllerInterface = {
  state: HookState;
  teams: ITeam[];
  columns: string[];
  isAddTeamPopupOpen: boolean;
  setIsAddTeamPopupOpen: (value: boolean) => void;
  isAddTeamMemberPopupOpen: boolean;
  handleOpenAddTeamMemberModal: (team: ITeam) => void;
  handleCloseAddTeamMemberModal: () => void;
  team: ITeam | null;
  isEditTeamPopupOpen: boolean;
  handleOpenEditTeamModal: (team: ITeam) => void;
  handleCloseEditTeamModal: () => void;
  hasCompanyTeamsEditPermission: boolean;
  nextApprovalLevel: number;
};

export const useCompanyTeams = (): ControllerInterface => {
  const { companyId } = useParams();
  const { permissions } = useContext(PermissionsContext);
  const [isAddTeamPopupOpen, setIsAddTeamPopupOpen] = useState<boolean>(false);
  const [isAddTeamMemberPopupOpen, setIsAddTeamMemberPopupOpen] = useState<boolean>(false);
  const [isEditTeamPopupOpen, setIsEditTeamPopupOpen] = useState<boolean>(false);

  const [team, setTeam] = useState<ITeam | null>(null);
  const handleOpenAddTeamMemberModal = useCallback((team) => {
    setTeam(team);
    setIsAddTeamMemberPopupOpen(true);
  }, []);
  const handleCloseAddTeamMemberModal = useCallback(() => {
    setTeam(null);
    setIsAddTeamMemberPopupOpen(false);
  }, []);

  const handleOpenEditTeamModal = useCallback((team) => {
    setTeam(team);
    setIsEditTeamPopupOpen(true);
  }, []);

  const handleCloseEditTeamModal = useCallback(() => {
    setTeam(null);
    setIsEditTeamPopupOpen(false);
  }, []);

  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [QueryNamesEnums.GET_COMPANY_TEAMS, { companyId }],
    getCompanyTeams.bind(this, companyId),
  );

  const columns = useMemo(
    () => [
      'icon',
      'name',
      'email',
      'lastOnline',
      'status',
      ...(isAllowed(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions) ? ['optionsMenu'] : []),
    ],
    [permissions],
  );

  const hasCompanyTeamsEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.COMPANY_TEAMS_EDIT, permissions),
    [permissions],
  );

  const nextApprovalLevel = useMemo(() => {
    return (
      1 +
      (companyTeamsQuery?.data?.results || []).reduce((prev, current) => {
        return prev > current.approval_level ? prev : current.approval_level;
      }, 0)
    );
  }, [companyTeamsQuery?.data?.results]);

  return {
    state: getHookState(companyTeamsQuery),
    teams: sortTeamsByApprovalLevel(companyTeamsQuery.data?.results),
    columns,
    isAddTeamPopupOpen,
    setIsAddTeamPopupOpen,
    isAddTeamMemberPopupOpen,
    handleOpenAddTeamMemberModal,
    handleCloseAddTeamMemberModal,
    isEditTeamPopupOpen,
    handleOpenEditTeamModal,
    handleCloseEditTeamModal,
    team,
    hasCompanyTeamsEditPermission,
    nextApprovalLevel,
  };
};
