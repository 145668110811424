import React, { FC } from 'react';
import { Button, Divider, Stack } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  CustomCurrencyTextField,
  CustomRadioGroup,
  CustomTextField,
  Popup,
  WysiwygEditor,
} from '@components';
import { PlusIcon } from '@svgAsComponents';
import { AddPHBLineItemProps } from './interface';
import { useAddPHBLineItem } from './controller';

const AddPHBLineItemModal: FC<AddPHBLineItemProps> = ({
  requestId,
  open,
  setLineItemModal,
  onAdded,
}) => {
  const {
    itemName,
    comment,
    requestedScheduledValue,
    costType,
    costTypeOptions,
    onClose,
    handleCostTypeChange,
    handleSubmitClick,
    isSubmitting,
    isDrawRequestDraft,
    typeOptions,
    handleTypeChange,
    type,
    originalEstimate,
    prefundingCost,
  } = useAddPHBLineItem({ requestId, setLineItemModal, onAdded });

  return (
    <Popup open={open} title={`Add line item`} icon={PlusIcon}>
      <Stack sx={{ width: '100%' }}>
        <Stack sx={{ mt: 4 }} spacing={2}>
          <CustomTextField
            field={itemName}
            label="Line item name"
            required
            inputProps={{
              'data-cy': 'edit_line_item_modal__name_input',
            }}
          />
          {requestId ? (
            <CustomCurrencyTextField
              field={requestedScheduledValue}
              label={
                isDrawRequestDraft ? 'Requested scheduled value ($)' : 'Revised scheduled value ($)'
              }
              required
              inputProps={{
                'data-cy': 'edit_line_item_modal__estimate_input',
              }}
              sx={{ mb: 2 }}
            />
          ) : (
            <>
              <CustomCurrencyTextField
                field={prefundingCost}
                label="Prefunding cost ($)"
                required
                inputProps={{
                  'data-cy': 'edit_line_item_modal__prefunding_cost_input',
                }}
                sx={{ mb: 2 }}
              />
              <CustomCurrencyTextField
                field={originalEstimate}
                label="Original scheduled value ($)"
                required
                inputProps={{
                  'data-cy': 'edit_line_item_modal__original_estimate_input',
                }}
                sx={{ mb: 2 }}
              />
            </>
          )}
          <CustomRadioGroup
            dataTestName="edit_line_item_modal__type__radio_group"
            value={type}
            onChange={handleTypeChange}
            options={typeOptions}
          />
          <CustomRadioGroup
            dataTestName="edit_line_item_modal__cost_type__radio_group"
            value={costType}
            onChange={handleCostTypeChange}
            options={costTypeOptions}
          />
          <Divider />
          {requestId && (
            <WysiwygEditor editField={comment} source="edit_line_item_modal__comment" />
          )}
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          sx={{ mt: 7.5 }}
          spacing={1}
        >
          <Button variant="text" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton
            onClick={handleSubmitClick}
            loading={isSubmitting}
            data-cy="add_line_item_modal_button"
          >
            Add
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default AddPHBLineItemModal;
