import React, { createContext, useContext, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from '@context';
import { IPermission, QueryNamesEnums } from '@interfaces';
import { getProjectPermissions } from '@globalService';

export const PermissionsContext = createContext<{
  permissions: IPermission[];
}>({
  permissions: [],
});

export const PermissionsProvider = ({ children }) => {
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const commonPermissions = user?.permissions as IPermission[];
  // TODO - thing do we wanna datalayer for this?
  const { data: projectPermissionData } = useQuery<IPermission[], Error>(
    [QueryNamesEnums.GET_PROJECT_PERMISSIONS, { projectId }],
    getProjectPermissions.bind(this, projectId),
    { enabled: Boolean(projectId) && user?.agreed_to_terms },
  );

  const permissions = useMemo<IPermission[]>(
    () =>
      user?.agreed_to_terms && commonPermissions
        ? projectId && projectPermissionData
          ? [
              ...projectPermissionData,
              ...commonPermissions.filter(
                (item) => !projectPermissionData.find((x) => x.name === item.name),
              ),
            ]
          : [...commonPermissions]
        : [],
    [commonPermissions, projectPermissionData],
  );

  return (
    <PermissionsContext.Provider value={{ permissions }}>{children}</PermissionsContext.Provider>
  );
};
