import React, { FC } from 'react';

import { useLaunchDarklyFlags } from '@context';
import ProjectPeopleV2 from '../projectPeopleV2';
import ProjectPeople from './ProjectPeopleV1';

const ProjectPeopleWithFlag: FC = () => {
  const flags = useLaunchDarklyFlags();

  return flags?.['ENG_8495_new_approval_flow'] ? <ProjectPeopleV2 /> : <ProjectPeople />;
};

export default ProjectPeopleWithFlag;
