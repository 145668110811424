import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { CommentIconWithIndicator } from '@svgAsComponents';
import {
  ColumnV2Width,
  CommonRowType,
  PermissionNamesEnums,
  RequestTableTabsEnum,
} from '@interfaces';
import { colors } from '@theme';

const Comments: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, commentsPreviewInfo, source } = useContext(TableContext);
  const rowInfo = commentsPreviewInfo?.[row.id] || row?.comments_preview;
  return (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.COMMENTS,
            })
          : row?.updateRightDrawer?.({ tab: RequestTableTabsEnum.COMMENTS })
      }
      data-cy={
        source ? `${source}__body__comments__icon` : 'inspections__result__general_row__comments'
      }
    >
      <CommentIconWithIndicator
        color={colors.icons.green}
        hasComments={rowInfo?.has_comments}
        hasUnreadComments={rowInfo?.has_unread_comments}
      />
    </IconButton>
  );
};

const Memoized = memo(Comments);

const comments: MilestoneListColumnType = {
  name: 'comments_preview',
  columnText: '',
  Header: '',
  justifyContent: 'flex-start',
  noBorder: true,
  noMargin: true,
  permissionKey: PermissionNamesEnums.COMMENTS_VIEW,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.LAST,
  maxWidth: () => ColumnV2Width.LAST,
};

export default comments;
