import React, { FC, useEffect } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { ExpandedIconPositionEnum, TableKeyEnum } from '@interfaces';
import { DataTableV3 } from '@components';
import { useModelsTable } from './controller';
import { GridEditInputCell, GridRowsProp } from '@mui/x-data-grid';
import { useExpandedState } from '@hooks';

const CreateByModelsTable: FC<{ propertyRows: GridRowsProp; onRowUpdate: (params) => void }> = ({
  propertyRows,
  onRowUpdate,
}) => {
  const { columns } = useModelsTable();

  return (
    <Stack
      sx={{
        backgroundColor: colors.background.white,
        pt: 1,
        borderRadius: '4px',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <DataTableV3
        withoutAdaptiveScroll
        tableKey={TableKeyEnum.CREATE_BY_MODELS}
        withBuiltInSearch={false}
        rows={propertyRows}
        columns={columns}
        getTreeDataPath={(row) => {
          return row.parentId ? [row.parent_level_name, row.property_name] : [row.property_name];
        }}
        rowUpdateApi={onRowUpdate}
        groupingColDef={{
          headerName: 'Property',
          width: 250,
          renderCell: (props) => {
            const { rowNode, api } = props;

            const isChildrenExpanded =
              'childrenExpanded' in rowNode ? rowNode.childrenExpanded : false;

            const children = 'children' in rowNode ? rowNode.children : [];
            const { expanded, getExpandButton, setExpanded } = useExpandedState({
              initialState: isChildrenExpanded,
              expandedIconPosition: ExpandedIconPositionEnum.RIGHT,
            });

            useEffect(() => {
              if (expanded === isChildrenExpanded) return;
              if (
                'setRowChildrenExpansion' in api &&
                typeof api.setRowChildrenExpansion === 'function'
              ) {
                api.setRowChildrenExpansion(rowNode.id, !isChildrenExpanded);
              }
            }, [expanded]);

            useEffect(() => {
              if (expanded === isChildrenExpanded) return;
              setExpanded(isChildrenExpanded);
            }, [isChildrenExpanded]);

            return (
              <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                {!!children?.length && getExpandButton()}
                <GridEditInputCell {...props} />
              </div>
            );
          },
        }}
      />
    </Stack>
  );
};
export default CreateByModelsTable;
