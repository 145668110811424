import React, { FC } from 'react';
import { Typography, Skeleton } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { percentFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography variant="body3">
      {percentFormatter({ value: row.approved_adjustments_rate })}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold">
      {percentFormatter({ value: row.approved_adjustments_rate })}
    </Typography>
  );
};

const adjustmentsRate: MilestoneListColumnType = {
  name: 'approved_adjustments_rate',
  columnText: 'Approved +/- change (%)',
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,

  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="% of approved changes to revised scheduled value for this request">
      <ColumnLabel>Approved</ColumnLabel>
      <ColumnLabel> +/- change (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default adjustmentsRate;
