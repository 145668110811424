export const transloaditTemplate = () => 'systemconfig/transloadit/template/';

export const transloaditSignature = (template_id: string, queryParams: string) =>
  `systemconfig/transloadit/template/${template_id}/request_data/?${queryParams}`;

export const systemConfig = () => 'systemconfig/';

export const platformPoliciesTemplates = () =>
  'checklists/platform/templates/?query={*,items{-exception_reasons,-metadata}}';

export const platformPoliciesTemplate = (templateId: string) =>
  `checklists/platform/templates/${templateId}/`;
