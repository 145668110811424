import { dataProvider } from '@api';
import {
  projectsCompaniesApi,
  projectsInspectionAgenciesApi,
  projectsTeamsApi,
  projectsUsersApi,
  projectsTypesApi,
  projectsRolesApi,
  projectsServiceAgenciesApi,
} from '../../paths';
import {
  ArgumentsProjectsCompaniesApi,
  ArgumentsProjectsRolesApi,
  ArgumentsProjectsUserApi,
} from '../../args';
import { IApiPath, ICompany, IRoleNew, ITeam, IUser } from '@interfaces';

export const getProjectsCompaniesV2 = async (
  args: ArgumentsProjectsCompaniesApi,
): Promise<{ results: ICompany[] }> => dataProvider.get(projectsCompaniesApi(args)).json();

export const getProjectsUsersV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: IUser[] }> => dataProvider.get(projectsUsersApi(args)).json();

export const getProjectsTeamsV2 = async (
  args: ArgumentsProjectsUserApi,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsTeamsApi(args)).json();

export const getProjectsInspectionAgenciesV2 = async (
  args: IApiPath,
): Promise<{ results: ITeam[] }> => dataProvider.get(projectsInspectionAgenciesApi(args)).json();

export const getProjectsServiceAgenciesV2 = async (args: IApiPath): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsServiceAgenciesApi(args)).json();

export const getProjectsTypesV2 = async (args: IApiPath): Promise<{ results: ITeam[] }> =>
  dataProvider.get(projectsTypesApi(args)).json();

export const getProjectsRolesV2 = async (
  args: ArgumentsProjectsRolesApi,
): Promise<{ results: IRoleNew[] }> => dataProvider.get(projectsRolesApi(args)).json();
