import React, { useContext, useState } from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import {
  ConfirmationModal,
  EditPolicyItem,
  IconButtonWithTooltip,
  PoliciesList,
  PoliciesListEditMode,
  SmartSummaryPanel,
  StyledBox,
} from '@components';
import * as Controller from './controller';
import { AddProofpointIcon, EditIcon, NoDataImage } from '@svgAsComponents';
import { checkIsOwner, getTooltipText, WithPermission } from '@utils';
import PoliciesTemplateSelect from '../PoliciesTemplateSelect';
import { PermissionNamesEnums, PoliciesItemLocal, PopupTypeEnum } from '@interfaces';
import { useConfirmationModal } from '@hooks';
import { colors } from '@theme';
import { AuthContext } from '@context';
import { PolicyContainerProps } from './interface';

const PoliciesContainer = ({
  policies,
  title,
  assigneeRole,
  source,
  editMode,
  handleCloseEdit,
  handleEditMode,
}: PolicyContainerProps) => {
  const controller = Controller.usePoliciesContainer({
    assigneeRole,
    policies,
    source,
    editMode,
  });
  const {
    updateActiveDRPolicies,
    isEditable,
    isCurrentProjectArchived,
    isTemplate,
    savePoliciesList,
    isTemplateSelectVisible,
    isFirstDrawTab,
    modalText,
    showResetToDefault,
    templatesList,
    isLoading,
    isProjectActivation,
  } = controller;
  const { isConfirmModalOpened, askConfirm, closeConfirmModal, confirmCallback } =
    useConfirmationModal();
  const isEditing = editMode === source;
  const { user } = useContext(AuthContext);
  const [openEditPolicyItem, setOpenEditPolicyItem] = useState<PoliciesItemLocal>();
  const emptyItem = { label: '', created_by_team: user?.active_team?.id, is_custom: true };
  const documents = policies?.items?.flatMap((item) => item?.documents);

  return (
    <>
      <StyledBox
        flex={1}
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'inherit' }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography variant="h3">{title}</Typography>
          <Stack direction="row" spacing={2}>
            {showResetToDefault && Boolean(templatesList?.length) && (
              <WithPermission permissionKey={PermissionNamesEnums.POLICIES_EDIT}>
                <FormControl sx={{ mr: 2 }}>
                  <InputLabel
                    id="select-policy-template-label"
                    sx={{
                      '&:not(.MuiFormLabel-filled)': {
                        transform: 'translate(12px, 10px) scale(1)',
                      },
                    }}
                  >
                    Choose policy
                  </InputLabel>
                  <Select
                    id="policy-template"
                    value={policies?.template || ''}
                    onChange={(event: SelectChangeEvent) => {
                      askConfirm({ value: event.target.value });
                    }}
                    sx={{ height: '40px', minWidth: '300px' }}
                    data-cy={`${source}_policy__section__policies_list__input`}
                  >
                    {templatesList?.map((template) => (
                      <MenuItem value={template.id} key={template.id}>
                        {template.name}
                        {template?.is_default && ' (default)'}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </WithPermission>
            )}
            {!isEditing && isTemplateSelectVisible && (
              <PoliciesTemplateSelect
                assigneeRole={assigneeRole}
                policies={policies}
                source={source}
              />
            )}
            {!isEditing && Boolean(documents?.length) && (
              <SmartSummaryPanel documents={documents} source={source} />
            )}
            {!isEditing && isEditable && (
              <WithPermission
                permissionKey={PermissionNamesEnums.POLICIES_OWN_ADDITIONAL_ITEMS_EDIT}
              >
                <Stack direction="row">
                  <IconButtonWithTooltip
                    tooltipText={
                      isCurrentProjectArchived
                        ? getTooltipText({ isCurrentProjectArchived })
                        : 'Edit'
                    }
                    onClick={() => handleEditMode(source)}
                    disabled={isCurrentProjectArchived}
                    data-cy={`${source}_policy__items__edit__icon`}
                  >
                    <EditIcon size={20} color={colors.icons.green} />
                  </IconButtonWithTooltip>
                  <IconButtonWithTooltip
                    tooltipText="Add requirement"
                    onClick={() => setOpenEditPolicyItem(emptyItem)}
                    disabled={isCurrentProjectArchived}
                    data-cy={`${source}_policy__items__edit__icon`}
                  >
                    <AddProofpointIcon size={20} color={colors.icons.green} />
                  </IconButtonWithTooltip>
                </Stack>
              </WithPermission>
            )}
          </Stack>
        </Stack>

        {!isEditing ? (
          (!policies?.items?.length && (
            <Stack sx={{ flexGrow: 1 }} justifyContent="center" alignItems="center">
              <NoDataImage size={200} />
              <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
                {isFirstDrawTab ? 'Future draws policy is used' : 'No policy set up'}
              </Typography>
              {isFirstDrawTab && (
                <Typography sx={{ textAlign: 'center' }} variant="label">
                  You can make the policy unique by clicking on “Add policy”
                </Typography>
              )}
            </Stack>
          )) || (
            <PoliciesList
              items={policies?.items}
              policiesId={policies?.id}
              isTemplate={isTemplate}
              showActionIcons={checkIsOwner(assigneeRole) && !isTemplate}
              source={source}
              isLoading={isLoading}
            />
          )
        ) : (
          <PoliciesListEditMode
            policiesList={policies}
            handleCloseEdit={handleCloseEdit}
            handleSave={savePoliciesList(policies?.id)}
            source={source}
            isProjectActivation={isProjectActivation}
          />
        )}
      </StyledBox>

      {Boolean(policies?.items?.length) && (
        <EditPolicyItem
          source={source}
          policyItem={openEditPolicyItem}
          saveItem={(editedItem: PoliciesItemLocal) => {
            savePoliciesList(policies.id)({ items: [...policies.items, editedItem] });
          }}
          onClose={() => setOpenEditPolicyItem(null)}
          items={policies?.items}
          isProjectActivation={isProjectActivation}
        />
      )}
      <ConfirmationModal
        open={isConfirmModalOpened}
        title="Confirm changes"
        text={modalText}
        onClose={closeConfirmModal}
        confirmCallback={() => confirmCallback({ action: updateActiveDRPolicies })}
        type={PopupTypeEnum.CONFIRMATION}
        cancelButtonLabel="Close"
      />
    </>
  );
};

export default PoliciesContainer;
