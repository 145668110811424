import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from '../common';
import { PermissionsContext } from '@context';
import { getEllipsisStyle, isAllowed } from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import {
  ColumnAlign,
  ColumnV2Width,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { TextInputWithTooltip } from '@components';
import { TableContext } from '../../controller';

const Description: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const { apiUpdate, resetMutation, source } = useContext(TableContext);

  const description = useStringFieldModel({
    initValue: (row['description'] || '').toString(),
    withProgressCheck: !row.isModelBuilding,
    blockNavigationKey: 'description',
  });
  const isEditable = useMemo(
    () =>
      !row.isModelBuilding &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );

  const effect = useCallback(() => {
    if (row.description !== description.value) {
      apiUpdate({
        milestoneId: row.id,
        name: 'description',
        value: description.value,
        isNonNumber: true,
      });
    }
  }, [row, description]);

  const { onBlur } = useDebounceInput({
    effect,
    handleChange: description.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { description: '' } }),
    afterEffect: () => description.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={220} justifyContent="space-between">
      <TextInputWithTooltip
        type="string"
        value={description.value}
        onChange={description.handleChange}
        onBlur={onBlur}
        dataTestName={`${source}__body__description__value__index_${row.index}`}
      />
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Tooltip title={row.description?.length > 50 ? row.description : ''}>
        <Typography component="div" sx={getEllipsisStyle('100%')} variant="body3">
          {row.description}
        </Typography>
      </Tooltip>
    </Box>
  );
};

const description: MilestoneListColumnType = {
  name: 'description',
  columnText: 'Description',
  noBorder: true,
  isEditable: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Description row={row} />,
  justifyContent: 'flex-start',
  minWidth: () => ColumnV2Width.NAME_MIN,
  maxWidth: () => ColumnV2Width.NAME_MAX,
  Header: (
    <HeaderText alignItems="flex-start" tooltipText="Description of the item">
      <ColumnLabel>Description</ColumnLabel>
    </HeaderText>
  ),
};

export default description;
