import find from 'lodash/find';
import map from 'lodash/map';

import {
  IInspection,
  IInspectionTotal,
  IStatusChip,
  IUISettings,
  QueryNamesEnums,
  ServiceOrderStatusEnum,
  IProjectDocument,
} from '@interfaces';
import { QueryClient, UseQueryResult } from 'react-query';
import { statusMap, onlyProgressPhotos } from '@constants';

export const getInspectionTotals = (
  inspection: IInspection | undefined,
  filterValue: string,
  defaultOptions: Array<{ filterValue: string; totalKey: string }>,
): IInspectionTotal => {
  const key = find(defaultOptions, { filterValue })?.totalKey;
  return {
    previous_inspector_allowance_rate: inspection?.totals?.[key]?.previous_inspector_allowance_rate,
    inspector_allowance_rate: inspection?.totals?.[key]?.inspector_allowance_rate,
    requested_amount: inspection?.totals?.[key]?.requested_amount,
    revised_estimate: inspection?.totals?.[key]?.revised_estimate,
  };
};

export const getInitColumns = (isEditable: boolean, isInspectionLinkedToDR: boolean): string[] => [
  'name',
  ...(isEditable ? ['previousInspectorAllowanceRate'] : []),
  'inspectorAllowanceRate',
  'revisedEstimate',
  ...(isInspectionLinkedToDR ? ['requestedAmount'] : []),
  'inspectionPhotoUploader',
  'inspectionPhotosV2',
  'comments',
];

export const getStatusChipProps = (
  inspection: IInspection | undefined,
  settings: Partial<IUISettings>,
): IStatusChip => {
  const serviceStatusValue = statusMap(inspection?.status, settings.display, 'inspection');
  return {
    color: serviceStatusValue.color,
    backgroundColor: serviceStatusValue.backgroundColor,
    label: serviceStatusValue.text || inspection?.status,
    dataTestName: 'data-cy="service_results__status_chip"',
  };
};

export const getProviderStatusChipProps = (
  inspection: IInspection | undefined,
  settings: Partial<IUISettings>,
): IStatusChip => {
  const providerStatusValue = statusMap(
    ServiceOrderStatusEnum.ORDERED,
    settings.display,
    'inspection',
  );

  return {
    color: providerStatusValue.color,
    backgroundColor: providerStatusValue.backgroundColor,
    label: inspection?.provider_status_display,
    dataTestName: 'data-cy="inspection_results__provider_status_chip"',
  };
};

export const getFilterOptions = (defaultOptions: Array<{ filterValue: string }>) =>
  map(defaultOptions, 'filterValue');

export const updateAllQueries = (
  queryClient: QueryClient,
  {
    projectId,
    inspectionId,
    drawRequestId,
    requestedDataQueries,
  }: {
    projectId: string;
    inspectionId: string;
    drawRequestId?: string;
    requestedDataQueries: [
      UseQueryResult<IInspection>,
      UseQueryResult<{ results: IProjectDocument[]; count: number }>,
    ];
  },
) => {
  const params = { projectId, drawRequestId };
  requestedDataQueries[0].refetch();

  const queriesToInvalidate = [
    [QueryNamesEnums.GET_SERVICE_ORDERS_LIST],
    [QueryNamesEnums.GET_INSPECTIONS_LIST],
    [QueryNamesEnums.GET_PROJECT_INSPECTIONS, { projectId }],
    [QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS, params],
    [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }, { exact: false }],
    [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId }],
    [QueryNamesEnums.GET_DRAW_REQUEST, params],
    [QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES, params],
    [
      QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
      { projectId, inspectionId, query: onlyProgressPhotos },
    ],
  ];

  queriesToInvalidate.forEach((query) => queryClient.invalidateQueries(query));
};
