import React, { FC } from 'react';
import { Skeleton, Typography } from '@mui/material';
import { currencyFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import {
  ColumnV2Width,
  CommonRowType,
  ColumnWidth,
  IMilestoneTotal,
  PermissionNamesEnums,
} from '@interfaces';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  if (isBlocked) return <Skeleton width={'100%'} />;

  return (
    <Typography variant="body3">
      {currencyFormatter(row.inspector_allowance_incremental, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold">
      {currencyFormatter(row.inspector_allowance_incremental, '-')}
    </Typography>
  );
};

const inspectorAllowanceIncremental: MilestoneListColumnType = {
  name: 'inspector_allowance_incremental',
  columnText: 'Inspection recommendation ($)',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  width: ColumnWidth.MONEY,
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText>
      <ColumnLabel>Inspection</ColumnLabel>
      <ColumnLabel> recommendation ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default inspectorAllowanceIncremental;
