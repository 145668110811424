import React, { FC, useRef } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';

import {
  ButtonWithTooltip,
  CollapsedCard,
  ConfirmationModal,
  CustomDatePickerInput,
  Gallery,
  IconButtonWithTooltip,
  LabelAndValue,
  LinkRequestToServicePickerV2,
  LoadingSkeleton,
  MessagePanel,
  MessagePanelButton,
  PDFViewerNew,
  PermissionDenied,
  ProjectNameLabel,
  RightDrawer,
  ServiceCancelButton,
  ServiceMessage,
  ServiceRelatedDocuments,
  StatusChip,
  StyledBox,
  SuccessModal,
  UpdateServiceOrderFieldsPopup,
} from '@components';
import { HookState, MessagePanelTabsEnum, PopupTypeEnum } from '@interfaces';
import { useServiceOrderEnterResult } from './controller';
import { currencyFormatter, getCostText, isCompletedService } from '@utils';
import { ArrowBackIcon, EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import {
  useDayJsFormatter,
  useServiceOrderFieldsProperties,
  ServiceFieldsVisibility,
} from '@hooks';
import { ControllerInterface } from './interface';

const ServiceResults: FC = () => {
  const controller = useServiceOrderEnterResult();
  const {
    state,
    project,
    serviceOrder,
    updateRightDrawer,
    rightMenu,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    goBack,
    shouldShowDeleteButton,
    deleteServiceOrderModal,
    isCurrentProjectArchived,
    handleDeleteServiceOrder,
    confirmCallBack,
    serviceCompletedAt,
    isMutating,
    isViewRestricted,
  } = controller;
  const fieldsProperties = useServiceOrderFieldsProperties({ serviceOrder });
  const { areSameDays } = useDayJsFormatter();
  if (isViewRestricted) return <PermissionDenied />;
  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }
    case HookState.ERROR: {
      return (
        <StyledBox>
          <ServiceMessage text="service" />
        </StyledBox>
      );
    }

    case HookState.SUCCESS: {
      return (
        <>
          <Stack direction="column" sx={{ height: '100%' }}>
            <Stack sx={{ p: 2 }} direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <IconButtonWithTooltip
                  onClick={goBack}
                  data-cy="service_results__back__icon"
                  tooltipText="Go back"
                >
                  <ArrowBackIcon />
                </IconButtonWithTooltip>
                <Typography sx={{ ml: 2 }} variant="h2">
                  {fieldsProperties['title']?.label}
                </Typography>
              </Stack>

              <Stack direction="row" spacing={2} justifyContent="flex-end">
                <ProjectNameLabel project={project} />
                <MessagePanelButton
                  commentsPreview={serviceOrder?.comments_preview}
                  handleRightDrawerOpenerClick={updateRightDrawer()}
                  source="service_results"
                />
              </Stack>
              <RightDrawer {...rightMenu}>
                <MessagePanel
                  projectId={project?.id}
                  requestId={serviceOrder?.draw_request?.id}
                  serviceOrderId={serviceOrder?.id}
                  source="service_results__comments"
                  tab={MessagePanelTabsEnum.SERVICES}
                  showTabs={false}
                />
              </RightDrawer>
            </Stack>
            <Box
              sx={{
                backgroundColor: colors.background.gray,
                flex: 1,
                p: 3,
              }}
            >
              <ServiceEnterResults controller={controller} fieldsProperties={fieldsProperties} />
            </Box>
            <Stack spacing={2} direction="row" padding={3} justifyContent="flex-end">
              <ServiceCancelButton serviceOrder={serviceOrder} source="service_results" />
              {shouldShowDeleteButton && (
                <Button
                  color="error"
                  disabled={isCurrentProjectArchived}
                  onClick={deleteServiceOrderModal.askConfirm}
                  data-cy="service_results__delete__button"
                >
                  Delete
                </Button>
              )}
              {fieldsProperties['confirm_button']?.view && (
                <ButtonWithTooltip
                  loading={isMutating}
                  onClick={confirmCallBack}
                  disabled={
                    !serviceCompletedAt.isValid ||
                    (isCompletedService(serviceOrder?.status) &&
                      areSameDays(serviceCompletedAt.value, new Date(serviceOrder?.completed_at)))
                  }
                  tooltipText="Completion date is required"
                  dataTestName="service_results__confirm__button"
                >
                  {fieldsProperties['confirm_button']?.label}
                </ButtonWithTooltip>
              )}
            </Stack>
          </Stack>
          {isApprovalPopupOpen && (
            <SuccessModal
              text="Service order has been completed."
              open
              onClose={() => {
                setIsApprovalPopupOpen(false);
                goBack();
              }}
            />
          )}

          <ConfirmationModal
            open={deleteServiceOrderModal.isConfirmModalOpened}
            title="Delete service"
            text="Are you sure you want to delete this service?"
            onClose={deleteServiceOrderModal.closeConfirmModal}
            confirmCallback={() =>
              deleteServiceOrderModal.confirmCallback({
                action: async () => {
                  await handleDeleteServiceOrder();
                },
              })
            }
            type={PopupTypeEnum.CONFIRMATION}
            cancelButtonLabel="Close"
          />
        </>
      );
    }
    default:
      return null;
  }
};

const ServiceEnterResults: FC<{
  controller: ControllerInterface;
  fieldsProperties: ServiceFieldsVisibility;
}> = ({ controller, fieldsProperties }) => {
  const {
    serviceOrder,
    updateQueries,
    imageContainer,
    imagePicker,
    openFile,
    activeDocumentId,
    serviceCompletedAt,
    statusChipProps,
    providerStatusChipProps,
    serviceTypeDisplayName,
    editedFieldKey,
    setEditedFieldKey,
    handleUpdateServiceOrderField,
    isMutating,
  } = controller;
  const { dateFormatter } = useDayJsFormatter();
  const containerRef = useRef();
  return (
    <WrapperWithFixedHeight>
      <Box
        sx={{
          overflow: 'scroll',
          maxWidth: { lg: serviceOrder?.result_documents?.length ? '50%' : '80%', xl: '50%' },
          flex: 1,
        }}
        ref={containerRef}
      >
        <Stack spacing={2}>
          <Stack spacing={1} direction="row" justifyContent="space-between">
            <StyledBox sx={{ flex: 1 }}>
              <Stack spacing={1}>
                <LabelAndValue label="ID" text={serviceOrder.service_number} />

                <LabelAndValue
                  label="Type"
                  text={serviceTypeDisplayName}
                  textStyle={{ textTransform: 'capitalize' }}
                />
                <LabelAndValue label="Provider" text={serviceOrder.service_agency.display_name} />

                {fieldsProperties['provider_order_id']?.view && (
                  <LabelAndValue
                    label="External ID"
                    text={serviceOrder.provider_order_id || ''}
                    icon={
                      fieldsProperties['provider_order_id']?.edit && (
                        <IconButtonWithTooltip
                          onClick={() => setEditedFieldKey('provider_order_id')}
                          sx={{ p: 0, pl: 1 }}
                          data-cy="service_results__provider_order_id__edit__icon"
                          tooltipText="Edit"
                        >
                          <EditIcon size={20} />
                        </IconButtonWithTooltip>
                      )
                    }
                    textDataTestName="service_results__provider_order_id__text"
                  />
                )}

                <LabelAndValue
                  label="Cost"
                  text={getCostText(serviceOrder)}
                  textDataTestName="service_results__cost__text"
                />

                <LabelAndValue
                  label="Status"
                  icon={
                    fieldsProperties['status']?.edit && (
                      <IconButtonWithTooltip
                        onClick={() => setEditedFieldKey('status')}
                        sx={{ p: 0, pl: 1 }}
                        data-cy="service_results__status__edit__icon"
                        tooltipText="Edit"
                      >
                        <EditIcon size={20} />
                      </IconButtonWithTooltip>
                    )
                  }
                >
                  <StatusChip {...statusChipProps} />
                </LabelAndValue>
                {serviceOrder.cancelled_at && (
                  <LabelAndValue
                    label="Date canceled"
                    text={dateFormatter({ date: serviceOrder.cancelled_at })}
                  />
                )}
                {serviceOrder.cancelled_by?.full_name && (
                  <LabelAndValue label="Canceled by" text={serviceOrder.cancelled_by?.full_name} />
                )}

                <LabelAndValue
                  label="Date ordered"
                  text={dateFormatter({ date: serviceOrder.ordered_at })}
                />

                {editedFieldKey && (
                  <UpdateServiceOrderFieldsPopup
                    fieldKey={editedFieldKey}
                    setFieldKey={setEditedFieldKey}
                    initialValue={
                      editedFieldKey === 'provider_status'
                        ? serviceOrder?.provider_status_display
                        : serviceOrder?.[editedFieldKey]
                    }
                    handleUpdate={handleUpdateServiceOrderField}
                    isLoading={isMutating}
                    source="service_results__status_edit_modal"
                  />
                )}
              </Stack>
            </StyledBox>
            <StyledBox sx={{ flex: 1 }}>
              <Stack spacing={1}>
                {serviceOrder.ordered_by?.full_name && (
                  <LabelAndValue label="Ordered by" text={serviceOrder.ordered_by?.full_name} />
                )}
                {serviceOrder.completed_at && (
                  <LabelAndValue
                    label="Date completed"
                    text={dateFormatter({ date: serviceOrder.completed_at })}
                  />
                )}

                <LinkRequestToServicePickerV2
                  serviceOrder={serviceOrder}
                  isEditable={fieldsProperties['draw_request']?.edit}
                />
                <LabelAndValue
                  label="Provider status"
                  icon={
                    fieldsProperties['provider_status']?.edit && (
                      <IconButtonWithTooltip
                        onClick={() => setEditedFieldKey('provider_status')}
                        sx={{ p: 0, pl: 1 }}
                        data-cy="service_results__provider_status__edit__icon"
                        tooltipText="Edit"
                      >
                        <EditIcon size={20} />
                      </IconButtonWithTooltip>
                    )
                  }
                  textDataTestName="service_results__provider_status__text"
                >
                  {providerStatusChipProps?.label ? (
                    <StatusChip {...providerStatusChipProps} />
                  ) : (
                    '-'
                  )}
                </LabelAndValue>

                {fieldsProperties['provider_service_cost']?.view && (
                  <LabelAndValue
                    label="Provider cost"
                    text={currencyFormatter(serviceOrder.provider_service_cost || 0)}
                    icon={
                      fieldsProperties['provider_service_cost']?.edit && (
                        <IconButtonWithTooltip
                          onClick={() => setEditedFieldKey('provider_service_cost')}
                          sx={{ p: 0, pl: 1 }}
                          data-cy="service_results__provider_cost__edit__icon"
                          tooltipText="Edit"
                        >
                          <EditIcon size={20} />
                        </IconButtonWithTooltip>
                      )
                    }
                    textDataTestName="service_results__provider_cost__text"
                  />
                )}

                {fieldsProperties['transaction_id']?.view && (
                  <LabelAndValue
                    label="Transaction ID"
                    text={serviceOrder.transaction_id || ''}
                    icon={
                      fieldsProperties['transaction_id']?.edit && (
                        <IconButtonWithTooltip
                          onClick={() => setEditedFieldKey('transaction_id')}
                          sx={{ p: 0, pl: 1 }}
                          data-cy="service_results__transaction_id__edit__icon"
                          tooltipText="Edit"
                        >
                          <EditIcon size={20} />
                        </IconButtonWithTooltip>
                      )
                    }
                    textDataTestName="service_results__transaction_id__text"
                  />
                )}
              </Stack>
            </StyledBox>
          </Stack>

          {fieldsProperties['completed_at']?.edit && (
            <StyledBox>
              <Stack spacing={2}>
                <Typography variant="h3">Completion info</Typography>
                <Stack spacing={1} sx={{ width: '50%', pt: 1 }}>
                  <CustomDatePickerInput
                    label="Date completed"
                    field={serviceCompletedAt}
                    maxDate={new Date()}
                    inputProps={{
                      'data-cy': 'service_results__date_picker__input',
                    }}
                    required
                    addTimestamp
                  />
                </Stack>
              </Stack>
            </StyledBox>
          )}

          {serviceOrder?.comment && (
            <StyledBox>
              <Stack spacing={2}>
                <Typography variant="h3">Special instructions</Typography>
                <Typography dangerouslySetInnerHTML={{ __html: serviceOrder?.comment }} />
              </Stack>
            </StyledBox>
          )}
          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              isServiceReports
              uploadRefetchCallback={updateQueries}
              source="service_results__related_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={fieldsProperties['documents']?.edit}
            />
          </StyledBox>
          <StyledBox>
            <ServiceRelatedDocuments
              drawRequestId={serviceOrder?.draw_request?.id}
              serviceOrderId={serviceOrder?.id}
              uploadRefetchCallback={updateQueries}
              source="service_results__result_documents"
              onDocumentClick={(document) => {
                imagePicker.close();
                imagePicker.open([document]);
              }}
              activeDocumentId={activeDocumentId}
              sx={{ width: '100%', p: 0 }}
              isEditable={fieldsProperties['documents']?.edit}
            />
          </StyledBox>
        </Stack>
      </Box>
      {Boolean(serviceOrder?.result_documents?.length) && (
        <ServiceReport
          openFile={openFile}
          imagePicker={imagePicker}
          imageContainer={imageContainer}
        />
      )}
    </WrapperWithFixedHeight>
  );
};

const ServiceReport = ({ openFile, imagePicker, imageContainer }) => {
  return (
    <>
      <Box sx={{ position: 'stick', zIndex: 99 }} flex={1}>
        <CollapsedCard expandOnMount onOpen={openFile} fullHeight title="Document preview">
          {imagePicker.pdf && (
            <Stack sx={{ height: '67vh' }}>
              <MemoizedPDF file={imagePicker.pdf[0]} />
            </Stack>
          )}
          <Box sx={{ height: imagePicker.gallery && '67vh' }} ref={imageContainer} />
        </CollapsedCard>
      </Box>
      {imagePicker.gallery && (
        <MemoizedGallery container={imageContainer.current} files={imagePicker.gallery} />
      )}
    </>
  );
};

const PDFViewer = ({ file }) => {
  return <PDFViewerNew pdfFile={file} withoutPortal />;
};

const ImageViewer = ({ container, files }) => {
  return <Gallery container={container} startIndex={0} files={files} />;
};

const MemoizedPDF = React.memo(
  PDFViewer,
  (prevProps, nextProps) => prevProps?.file?.id === nextProps?.file?.id,
);

const MemoizedGallery = React.memo(
  ImageViewer,
  (prevProps, nextProps) => prevProps?.files?.[0]?.id === nextProps?.files?.[0]?.id,
);
export default ServiceResults;

const WrapperWithFixedHeight = ({ children }) => {
  return (
    <Stack
      spacing={2}
      direction={{ lg: 'row', xs: 'column' }}
      sx={{
        maxHeight: { lg: 'calc(100vh - 272px)', xs: 'unset' }, // 64+72+88+24+24
        width: '100%',
        flex: 1,
        justifyContent: 'center',
        overflow: { lg: 'scroll', xs: 'unset' },
      }}
    >
      {children}
    </Stack>
  );
};
