import React, { useContext } from 'react';
import { isAllowed, useDeepTraceUpdates } from '@utils';
import { PermissionsContext } from '@context';

export const WithPermission = ({ children, permissionKey }) => {
  const { permissions } = useContext(PermissionsContext);

  return isAllowed(permissionKey, permissions) ? children : null;
};

export const withTraceUpdates = <P extends object>(Component: React.ComponentType<P>) => {
  return (props: P) => {
    useDeepTraceUpdates(props, Component.displayName);
    return <Component {...props} />;
  };
};
