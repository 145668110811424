import { useCallback, useContext, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { AuthContext } from '@context';
import { useStringFieldModel } from '@models';
import {
  IDrawRequest,
  LineItemPostPayload,
  LineItemPostResponse,
  MutationKeyEnum,
  PatchCommentPayload,
  ProjectById,
  QueryNamesEnums,
} from '@interfaces';
import { costTypeOptions } from '@constants';
import { ControllerInterface, AddPHBLineItemControllerProps } from './interface';
import { getBasicUrl, isRequestDraft } from '@utils';
import {
  getDrawRequest,
  postComment,
  postItemToRequest,
  postMilestoneToProject,
} from '@globalService';

export const useAddPHBLineItem = ({
  setLineItemModal,
  requestId,
  onAdded,
}: AddPHBLineItemControllerProps): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();

  const drawRequestData = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId: requestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId: requestId }),
    {
      enabled: Boolean(projectId && requestId),
    },
  );

  const isDrawRequestDraft = useMemo(
    () => isRequestDraft(drawRequestData.data?.status),
    [drawRequestData.data?.status],
  );

  const typeOptions = [
    { value: 'is_vertical', label: 'Vertical' },
    { value: 'is_horizontal', label: 'Horizontal' },
  ];

  const itemName = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const comment = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(value?.trim()),
  });

  const requestedScheduledValue = useStringFieldModel({
    initValue: '',
    validationRule: (value) => Boolean(+value?.trim()),
    validateOnChange: true,
  });

  const originalEstimate = useStringFieldModel({
    initValue: '',
  });

  const prefundingCost = useStringFieldModel({
    initValue: '',
  });

  const [costType, setCostType] = useState('HARD');
  const [type, setType] = useState('is_vertical');

  const onClose = () => {
    setLineItemModal(false);
  };

  const handleCostTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCostType(event.target.value);
  };

  const handleTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.value);
  };

  const postCommentMutation = useMutation<
    Response,
    Error,
    { url: string; value: PatchCommentPayload }
  >(postComment, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMMENTS, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const update = async (data, variables) => {
    if (variables?.comment && data?.id) {
      const url = getBasicUrl({
        requestType: 'post',
        projectId,
        requestId,
        milestoneId: data?.id,
      });

      await postCommentMutation.mutateAsync({
        url,
        value: {
          message: variables.comment,
          received_by_company_id: user?.company_id,
        },
      });
    }
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST,
      { projectId, drawRequestId: requestId },
    ]);
    queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_MILESTONES, { projectId }]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES_COLUMNS,
      { projectId, requestId },
    ]);

    onAdded?.();
    setLineItemModal(false);
  };

  const addProjectLineItemMutation = useMutation<Response, Error, ProjectById>(
    postMilestoneToProject,
    {
      mutationKey: MutationKeyEnum.DRAW_REQUEST_ADD_ITEM,
      onSuccess: (data, variables) => {
        update(data, variables);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const addDRLineItemMutation = useMutation<LineItemPostResponse, Error, LineItemPostPayload>(
    postItemToRequest,
    {
      onSuccess: async (data, variables) => {
        update(data, variables);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const handleSubmitClick = useCallback(() => {
    const isNameValid = itemName.validate();
    const isRequestedValueValid = requestedScheduledValue.validate();

    if (!isNameValid || (requestId && !isRequestedValueValid)) return;

    const payload = {
      name: itemName.value,
      cost_type: costType,
      is_vertical: type === 'is_vertical',
    };

    const projectPayload = {
      ...payload,
      original_estimate: +originalEstimate.value,
      prefunding_cost: +prefundingCost.value,
    };

    const requestPayload = {
      ...payload,
      project: projectId,
      drawRequest: requestId,
      ...(isDrawRequestDraft
        ? { requested_revised_estimate: +requestedScheduledValue.value }
        : { approved_adjustments: +requestedScheduledValue.value }),
      ...(comment.value && { comment: comment.value }),
    };
    if (requestId) {
      addDRLineItemMutation.mutateAsync(requestPayload);
    } else {
      addProjectLineItemMutation.mutateAsync({ project: projectId, json: projectPayload });
    }
  }, [
    itemName.value,
    comment.value,
    requestedScheduledValue.value,
    costType,
    isDrawRequestDraft,
    requestId,
    addDRLineItemMutation,
    addProjectLineItemMutation,
    projectId,
    type,
  ]);

  return {
    itemName,
    comment,
    requestedScheduledValue,
    costType,
    costTypeOptions,
    onClose,
    handleCostTypeChange,
    handleSubmitClick,
    isSubmitting: addDRLineItemMutation.isLoading,
    isDrawRequestDraft,
    typeOptions,
    handleTypeChange,
    type,
    originalEstimate,
    prefundingCost,
  };
};
