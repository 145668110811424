import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import {
  AddPHBLineItemModal,
  BudgetSummary,
  Filter,
  MilestoneListWithPatch,
  ToggleButtonGroup,
} from '@components';
import { useBudgetTable } from './controller';
import { DeleteBudgetModal } from '../Modals/DeleteBudgetModal/';
import { TableKeyEnum } from '@interfaces';
import TypeFilter from '../TypeFilter';
import { useLaunchDarklyFlags } from '@context';

const BudgetTableV2: FC = () => {
  const {
    initColumns,
    onExpandClick,
    filterOptions,
    tableItems,
    filterValue,
    handleFilterClick,
    isLoading,
    menuItems,
    isDeleteBudgetPopupOpen,
    setIsDeleteBudgetPopupOpen,
    deleteBudget,
    isDeleting,
    totals,
    patchMsGroup,
    isLineItemsView,
    activeView,
    setActiveView,
    viewTypes,
    typeFilterValues,
    setTypeFilterValues,
    setIsAddLineItemPopupOpen,
    isAddLineItemPopupOpen,
  } = useBudgetTable();
  const flags = useLaunchDarklyFlags();
  return (
    <>
      {flags?.['ENG_9259_loan_budget_summary'] && <BudgetSummary />}
      <Stack
        sx={{ backgroundColor: colors.background.white, pt: 1, borderRadius: '4px', flexGrow: 1 }}
      >
        <MilestoneListWithPatch
          withColumnIndication
          initColumns={initColumns}
          milestones={tableItems}
          tableKey={TableKeyEnum.PHB_LINE_ITEMS}
          key="listWithEditModels"
          onExpandTable={onExpandClick}
          menuItems={menuItems}
          totals={totals}
          headerLeftPart={[
            {
              Component: (
                <Stack sx={{ mr: 2 }}>
                  <ToggleButtonGroup
                    value={activeView}
                    typesList={viewTypes}
                    handleChange={(_e, value) => value && setActiveView(value)}
                    size="small"
                    source="budget_tab___line_items_table"
                  />
                </Stack>
              ),
            },
            ...(isLineItemsView
              ? [
                  {
                    Component: (
                      <TypeFilter
                        handleTypeClick={setTypeFilterValues}
                        typesFilterValues={typeFilterValues}
                      />
                    ),
                  },
                ]
              : [
                  {
                    Component: (
                      <Filter
                        filterLabel="Show"
                        onChangeCallback={handleFilterClick}
                        options={filterOptions}
                        filterValue={filterValue}
                        source="budget_tab___line_items_table__filter"
                      />
                    ),
                  },
                ]),
          ]}
          source="project__budget__line_items_table"
          isLoading={isLoading}
          lockSecondColumn
          patchMilestone={patchMsGroup}
        />
        {isDeleteBudgetPopupOpen && (
          <DeleteBudgetModal
            onClose={() => setIsDeleteBudgetPopupOpen(false)}
            deleteSections={deleteBudget}
            isLoading={isDeleting}
          />
        )}
        {isAddLineItemPopupOpen && (
          <AddPHBLineItemModal
            open={isAddLineItemPopupOpen}
            setLineItemModal={setIsAddLineItemPopupOpen}
          />
        )}
      </Stack>
    </>
  );
};
export default BudgetTableV2;
