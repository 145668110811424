import React from 'react';
import { ServiceResultsV0, ServiceResults } from '@components';
import { useLaunchDarklyFlags } from '@context';

const InspectionResultIndex = () => {
  const flags = useLaunchDarklyFlags();
  return flags?.['ENG_9769_external_id_for_service_billing'] ? (
    <ServiceResults />
  ) : (
    <ServiceResultsV0 />
  );
};

export default InspectionResultIndex;
