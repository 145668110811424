import capitalize from 'lodash/capitalize';
import first from 'lodash/first';
import {
  CommonRowType,
  ConfirmOptionEnums,
  DrawRequestSourceEnum,
  DrawRequestStatus,
  DrawRequestTypeEnum,
  IDrawRequest,
  IInspection,
  IInspectionAgency,
  IMilestone,
  IPermission,
  IProject,
  IServiceAgency,
  IServiceOrder,
  ItemStatusesEnum,
  IUser,
  LineItemHighlightVariantEnum,
  PaymentConfiguration,
  PermissionNamesEnums,
  PoliciesTypeEnum,
  PolicyHistoryActionEnum,
  PolicyItemTypesEnum,
  PolicyListSourceEnums,
  ProjectStatusEnum,
  ServiceOrderStatusEnum,
  ServiceProviderEnum,
  ServiceProvidersEnum,
  ServiceTypeEnum,
  UserStatusEnum,
} from '@interfaces';
import { checkIsExternalUser } from './byRoles';
import { isAllowed } from './index';
import { colors } from '@theme';

export const isInspectionFailed = (status: string) => status === ServiceOrderStatusEnum.ERROR;

export const isInspectionCanceled = (status: string) => status === ServiceOrderStatusEnum.CANCELLED;

export const isTruePicInspection = (inspectionService: string) =>
  inspectionService === ServiceProviderEnum.TRUEPIC;

export const isServiceProviderAutomatedByCS = (serviceProvider: string) =>
  serviceProvider === ServiceProvidersEnum.HITL;

export const isServiceProviderManual = (serviceProvider: string) =>
  serviceProvider === ServiceProvidersEnum.MANUAL;

export const isAutomatedServiceProvider = (inspectionService: string) =>
  inspectionService === ServiceProviderEnum.TRINITY ||
  inspectionService === ServiceProviderEnum.PROXYPICS ||
  inspectionService === ServiceProviderEnum.TRUEPIC;

export const getActiveDrawRequestFromList = (data: any) => {
  const list = data?.results || data || [];
  return list?.find(
    ({ status }) => status !== DrawRequestStatus.DRAFT && status !== DrawRequestStatus.COMPLETED,
  );
};

export const getActiveDrawRequestFromListForInspection = (data: any) => {
  const list = data?.results || data || [];
  return list?.find(({ status }) => isRequestInReview(status));
};

export const getDrawRequestForApproval = (drawRequests?: IDrawRequest[]) =>
  drawRequests
    ? drawRequests.find(
        ({ waits_current_user_approval, status }) =>
          waits_current_user_approval && !isRequestDraft(status),
      )
    : null;

export const isChangeRequest = (drawRequest?: Partial<IDrawRequest>) =>
  drawRequest?.type === DrawRequestTypeEnum.CHANGE_REQUEST;

export const isDrawRequest = (drawRequest?: Partial<IDrawRequest>) =>
  drawRequest?.type === DrawRequestTypeEnum.DRAW_REQUEST;

export const isCreditInRequest = (ml: Array<Partial<IMilestone>>) =>
  ml.some((item) => item.approved_credit_amount || item.requested_credit_amount);

export const isCreatedProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.CREATED;
export const isProjectPaused = (project: IProject) => project?.status === ProjectStatusEnum.PAUSED;
export const isRequestDraft = (status: string) => status === DrawRequestStatus.DRAFT;
export const isRequestInReview = (status: string) => status === DrawRequestStatus.IN_REVIEW;
export const isRequestCompleted = (status: string) => status === DrawRequestStatus.COMPLETED;
export const isRequestApproved = (status: string) => status === DrawRequestStatus.APPROVED;
export const isRequestActive = (status: string) =>
  status !== DrawRequestStatus.DRAFT && status !== DrawRequestStatus.COMPLETED;

export const isRequestDraftOrInReview = (status: string) =>
  status === DrawRequestStatus.DRAFT || status === DrawRequestStatus.IN_REVIEW;

export const isRequestHistorical = (source: string) => source === DrawRequestSourceEnum.MANUAL;

export const isRequestLumpSum = (request: IDrawRequest) => request?.is_lump_sum_request;

export const isInactiveProject = (status: ProjectStatusEnum): boolean =>
  [
    ProjectStatusEnum.INACTIVE_COMPLETE,
    ProjectStatusEnum.INACTIVE_INCOMPLETE,
    ProjectStatusEnum.PAUSED,
    ProjectStatusEnum.DISCARDED,
  ].includes(status);

export const isActiveProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.ACTIVE;

export const isCompletedProject = (status: ProjectStatusEnum): boolean =>
  status === ProjectStatusEnum.INACTIVE_COMPLETE;

export const getItemLocalHighlight = (item) => {
  if (item?.milestone_is_in_current_draw) {
    return LineItemHighlightVariantEnum.CURRENT;
  }

  if (item?.milestone_is_active || item?.draw_requests?.length || item?.change_requests?.length) {
    return LineItemHighlightVariantEnum.ACTIVE;
  }

  return undefined;
};

export const isCompletedService = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.COMPLETED;

export const isOrderedService = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.ORDERED;

export const isServiceReadyForReview = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.READY_FOR_REVIEW;

export const isPendingService = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.PENDING;

export const isActiveService = (status: ServiceOrderStatusEnum) =>
  isOrderedService(status) || isCreatedService(status);

export const isCancelRequestedService = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.CANCEL_REQUESTED;

export const isCanceledService = (status: ServiceOrderStatusEnum) =>
  status === ServiceOrderStatusEnum.CANCELLED;

export const isCreatedService = (status: string) => status === ServiceOrderStatusEnum.CREATED;

export const isInspectionService = (service_type: ServiceTypeEnum) =>
  service_type === ServiceTypeEnum.INSPECTION;

export const getServiceTag = ({
  serviceOrder,
  serviceType,
  isTag = false,
  dateFormatter,
}: {
  serviceOrder: IServiceOrder | IInspection;
  serviceType: ServiceTypeEnum;
  isTag?: boolean;
  dateFormatter: ({ date }) => string;
}) => {
  const serviceProvider = isTag
    ? ''
    : `/${
        (serviceOrder as IServiceOrder)?.service_agency?.display_name ||
        (serviceOrder as IInspection)?.inspection_agency?.display_name
      }`;
  switch (serviceOrder?.status) {
    case ServiceOrderStatusEnum.PENDING:
      return `${serviceType}${serviceProvider} (pending)`;
    case ServiceOrderStatusEnum.COMPLETED:
      return `${serviceType}${serviceProvider} ${dateFormatter({
        date: serviceOrder?.completed_at,
      })}`;
    case ServiceOrderStatusEnum.ERROR:
      return `${serviceType}${serviceProvider} (failed)`;
    case ServiceOrderStatusEnum.CANCELLED:
    case ServiceOrderStatusEnum.CANCEL_REQUESTED:
      return `${serviceType}${serviceProvider} (canceled)`;
    default:
      return `${serviceType}${serviceProvider} (in progress)`;
  }
};

export const getInspectionName = ({
  serviceOrder,
  dateFormatter,
}: {
  serviceOrder: IServiceOrder | IInspection;
  dateFormatter: ({ date }) => string;
}) => {
  const serviceType = capitalize(serviceOrder?.service_type);
  switch (serviceOrder?.status) {
    case ServiceOrderStatusEnum.PENDING:
      return `${serviceType} (pending)`;
    case ServiceOrderStatusEnum.COMPLETED:
      return `${serviceType} ${dateFormatter({
        date: serviceOrder?.completed_at,
      })}`;
    case ServiceOrderStatusEnum.ERROR:
      return `${serviceType} (failed)`;
    case ServiceOrderStatusEnum.CANCELLED:
      return `${serviceType} (canceled)`;
    case ServiceOrderStatusEnum.CANCEL_REQUESTED:
      return `${serviceType} (Cancellation requested)`;
    default:
      return `${serviceType} (in progress)`;
  }
};

export const isMilestoneIncomplete = (row: CommonRowType, adjustments_field: string) =>
  +row.previous_approved_amount_cumulative < row.revised_estimate ||
  row[adjustments_field] > 0 ||
  row.approved_adjustments > 0;

export const isRequestHasChangedValues = (drawRequest: IDrawRequest) => {
  const hasRequestedChanges = drawRequest.requested_reallocation > 0;

  const hasRequestedAmounts = drawRequest.totals?.all?.requested_amount > 0;

  const hasRequestedRetainage = drawRequest.totals?.all?.retainage_release_requested > 0;

  return isDrawRequest(drawRequest)
    ? hasRequestedChanges || hasRequestedAmounts || hasRequestedRetainage
    : hasRequestedChanges;
};

export const getInspectionNameByRole = ({
  inspectionAgency,
  teamRole,
}: {
  inspectionAgency: IInspectionAgency;
  teamRole: string;
}) => {
  const isExternalUser = checkIsExternalUser(teamRole);
  const { display_name, service } = inspectionAgency || {};
  return isServiceProviderManual(service)
    ? isExternalUser
      ? display_name
      : `${service} + ${display_name} `
    : display_name;
};

export const getServiceTagByRole = ({
  serviceAgency,
  teamRole,
}: {
  serviceAgency: IServiceAgency;
  teamRole: string;
}) => {
  const isExternalUser = checkIsExternalUser(teamRole);
  const { display_name, service_provider } = serviceAgency || {};
  return isServiceProviderAutomatedByCS(service_provider)
    ? isExternalUser
      ? display_name
      : `${service_provider} + ${display_name} `
    : display_name;
};

export const getColorCodingScheme = ({ request }: { request: IDrawRequest }) => {
  const { status, project, is_on_hold, is_resubmit, waits_current_user_approval } = request;
  if (is_on_hold || (is_resubmit && isRequestDraft(status)) || isProjectPaused(project)) {
    return {
      backgroundColor: colors.status.error.lighter,
      borderColor: colors.status.error.medium,
    };
  }

  if (waits_current_user_approval) {
    return {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
    };
  }

  switch (status) {
    case DrawRequestStatus.DRAFT:
      return { backgroundColor: colors.neutral.lightest, borderColor: colors.neutral.dark };
    case DrawRequestStatus.IN_REVIEW:
      return {
        backgroundColor: colors.status.information.lighter,
        borderColor: colors.status.information.medium,
      };
    case DrawRequestStatus.APPROVED:
      return {
        backgroundColor: colors.status.success.lighter,
        borderColor: colors.status.success.medium,
      };
    case DrawRequestStatus.COMPLETED:
      return {
        backgroundColor: colors.status.violet.lighter,
        borderColor: colors.status.violet.medium,
      };

    default:
      return { backgroundColor: 'transparent', borderColor: 'transparent' };
  }
};

export const isReverseSequentialPaymentConfiguration = (configuration_type: string) =>
  configuration_type === PaymentConfiguration.REVERSE_SEQUENTIAL;

export const isPariPassuPaymentConfiguration = (configuration_type: string) =>
  configuration_type === PaymentConfiguration.PER_DRAW_REQUEST;

export const isReallocationEnabledByProject = (project?: IProject) =>
  project?.is_reallocation_enabled;

export const isReallocationEnabled = (drawRequest: IDrawRequest, project: IProject) =>
  isChangeRequest(drawRequest) || project?.is_reallocation_enabled;

export const getCurrentInspection = (inspectionsList: IInspection[]) =>
  inspectionsList?.find(
    (item) =>
      isOrderedService(item.status) ||
      isPendingService(item.status) ||
      isCancelRequestedService(item.status) ||
      isServiceReadyForReview(item.status),
  );

export const isTextPolicyItem = (type: string) => type === PolicyItemTypesEnum.COMMENT;
export const isAttestationPolicyItem = (type: string) => type === PolicyItemTypesEnum.ATTESTATION;
export const isCompletionDatePolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL;
export const isInspectionPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.INSPECTION_ORDERING;
export const isRequiredDocumentPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD;
export const isOptionalDocumentPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.OPTIONAL_DOCUMENT_UPLOAD;
export const isRequiredPhotoPolicyItem = (type: string) =>
  type === PolicyItemTypesEnum.REQUIRED_PHOTO_UPLOAD;

export const isDocumentPolicyItem = (type: PolicyItemTypesEnum) =>
  [
    PolicyItemTypesEnum.REQUIRED_DOCUMENT_UPLOAD,
    PolicyItemTypesEnum.OPTIONAL_DOCUMENT_UPLOAD,
    PolicyItemTypesEnum.REQUIRED_PHOTO_UPLOAD,
  ].includes(type);

export const isSubmissionPolicySource = (source: string) =>
  source === PolicyListSourceEnums.SUBMISSION;

export const isHistoryActionCreate = (action: PolicyHistoryActionEnum) =>
  action === PolicyHistoryActionEnum.CREATE;

export const isHistoryActionUpdate = (action: PolicyHistoryActionEnum) =>
  action === PolicyHistoryActionEnum.UPDATE;

export const isPolicyItemCompleted = (status: ItemStatusesEnum) =>
  status === ItemStatusesEnum.COMPLETED;

export const isPolicyItemSkipped = (status: ItemStatusesEnum) =>
  status === ItemStatusesEnum.SKIPPED;

// Should always (ENG-7891):
// first pick Ordered Inspection over other statuses
// if no ordered should always pick Completed inspection over Canceled
// From the inspections of the same status should pick the latest one
export const getLastRelevantInspection = (inspections: IInspection[]) => {
  if (!inspections?.length) return null;
  // Filter out inspections with the status of "Created"
  const filteredInspections = inspections.filter(
    (inspection) => !isCreatedService(inspection.status),
  );

  // Sort inspections by status priority (Ordered > Completed > Others) and then by date
  const sortedInspections = filteredInspections.sort((a, b) => {
    const statusPriority = (status) => {
      if (isOrderedService(status)) return 3;
      if (isCompletedService(status)) return 2;
      return 1;
    };

    // Sort by status priority first
    const priorityComparison = statusPriority(b.status) - statusPriority(a.status);
    if (priorityComparison !== 0) return priorityComparison;

    // If the same status, sort by the latest date
    return new Date(b.ordered_at).getTime() - new Date(a.ordered_at).getTime();
  });

  // Find the first inspection that matches the highest priority criteria
  return sortedInspections.find(
    (inspection) => isOrderedService(inspection.status) || isCompletedService(inspection.status),
  );
};

export const isPolicyItemDefault = (type: PolicyItemTypesEnum) =>
  [
    PolicyItemTypesEnum.DRAW_REQUEST_FORM,
    PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
    PolicyItemTypesEnum.INSPECTION_ORDERING,
  ].includes(type);

export const filterActiveUsers = (users: IUser[]) =>
  users?.filter((user) => user?.status === UserStatusEnum.ACTIVE) || [];

export const hasConfirmedCompletionDate = (option: string) => option === ConfirmOptionEnums.YES;

export const isServiceOrderEditableByPermission = (
  serviceOrder: IServiceOrder | IInspection,
  permissions: IPermission[],
) => {
  // TODO - after migration inspections to services think about introducing 'is_editable' field to the service order
  const serviceProviderType =
    (serviceOrder as IServiceOrder)?.service_agency?.service_provider ||
    (serviceOrder as IInspection)?.inspection_agency?.service;

  const canBeEditedByExternalUser =
    isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions) &&
    (isServiceProviderManual(serviceProviderType) || isTruePicInspection(serviceProviderType));

  const canBeEditedByCustomerSuccess =
    (isServiceProviderAutomatedByCS(serviceProviderType) ||
      isTruePicInspection(serviceProviderType)) &&
    isAllowed(
      [PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, PermissionNamesEnums.SERVICES_CS_EDIT],
      permissions,
    );

  return canBeEditedByExternalUser || canBeEditedByCustomerSuccess;
};

export const isServiceOrderCancellableByPermission = (permissions: IPermission[]) =>
  isAllowed(
    [
      PermissionNamesEnums.INSPECTIONS_EDIT,
      PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
      PermissionNamesEnums.SERVICES_CS_EDIT,
    ],
    permissions,
  );

export const isProjectPolicies = (tabPathname: string) => tabPathname === PoliciesTypeEnum.PROJECT;

export const checkIfHistoricalRequestEditable = ({ request, project, projectRequestsList }) =>
  isRequestHistorical(request?.source) &&
  (!isRequestCompleted(request?.status) ||
    (isCreatedProject(project?.status) && first(projectRequestsList)?.id === request?.id));

export const isGetListDataSuccess = (status: string) => status === 'success';
