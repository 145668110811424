import React, { useMemo, FC, useContext, useCallback } from 'react';
import { MilestoneListColumnTypeV2 } from './common';
import { PermissionsContext } from '@context';
import { isAllowed, useBlockerCell } from '@utils';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { CommonRowType, IMilestone, PermissionNamesEnums } from '@interfaces';
import { DrawerContext } from '../drawer';
import { IconButtonWithTooltip } from '@components';

const EditIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const isMutating = useBlockerCell({ milestoneId: row.id });
  const { permissions } = useContext(PermissionsContext);
  const { openEditMilestoneModal } = useContext(DrawerContext);

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.localNew &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );
  const editCallback = useCallback(() => openEditMilestoneModal(row as IMilestone), [row]);

  if (!isEditable) return null;
  if (row.id === 'totals') return null;
  return (
    <IconButtonWithTooltip
      disabled={isMutating}
      onClick={editCallback}
      data-cy="edit_line_item_icon_button"
      tooltipText="Edit"
    >
      <EditIcon size={22} color={colors.icons.gray} />
    </IconButtonWithTooltip>
  );
};

const editItem: MilestoneListColumnTypeV2 = {
  field: 'edit_item',
  headerAlign: 'right',
  headerClassName: 'icon',
  cellClassName: 'icon',
  renderHeader: () => null,
  editable: false,
  pinnable: false,
  disableColumnMenu: true,
  sortable: false,
  filterable: false,
  groupable: false,
  aggregable: false,
  hideable: false,
  resizable: false,
  renderCell: (params) => <EditIconRow row={params.row} />,
  maxWidth: 40,
  minWidth: 40,
};

export default editItem;
