import React, { useMemo, FC, useContext, useCallback } from 'react';
import { MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { PermissionsContext } from '@context';
import { isAllowed, useBlockerCell } from '@utils';
import { EditIcon } from '@svgAsComponents';
import { colors } from '@theme';
import { ColumnV2Width, CommonRowType, IMilestone, PermissionNamesEnums } from '@interfaces';
import { IconButtonWithTooltip } from '@components';

const EditIconRow: FC<{ row: CommonRowType }> = ({ row }) => {
  const isMutating = useBlockerCell({ milestoneId: row.id });
  const { permissions } = useContext(PermissionsContext);
  const { openEditMilestoneModal } = useContext(TableContext);

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.localNew &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );
  const editCallback = useCallback(() => openEditMilestoneModal(row as IMilestone), [row]);

  if (!isEditable) return null;
  return (
    <IconButtonWithTooltip
      disabled={isMutating}
      onClick={editCallback}
      data-cy="edit_line_item_icon_button"
      tooltipText="Edit"
    >
      <EditIcon size={22} color={colors.icons.gray} />
    </IconButtonWithTooltip>
  );
};

const editItem: MilestoneListColumnType = {
  name: 'id',
  columnText: '',
  Header: '',
  noMargin: true,
  noBorder: true,
  renderCell: ({ row }) => <EditIconRow row={row} />,
  minWidth: () => ColumnV2Width.LAST,
  maxWidth: () => ColumnV2Width.LAST,
};

export default editItem;
