import { DrawRequestTypeEnum, TableKeyEnum } from '@interfaces';
import { createColumnVisibleModel, mergeColumnVisibilityModel } from '@utils';
import React, { useState, useCallback, useRef } from 'react';
import { useUpdateUiSettings } from '@hooks';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { defaultPersonalSetting } from '@constants';

import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridColDef, GridSortModel } from '@mui/x-data-grid-premium';

const useTableSettings = (
  tableKey: TableKeyEnum | DrawRequestTypeEnum,
  apiRef: React.MutableRefObject<GridApiPremium>,
  setTableLoading: React.Dispatch<React.SetStateAction<boolean>>,
  memoColumns: GridColDef[],
  rowReordering?: boolean,
  withColumnIndex?: boolean,
) => {
  const needToUpdateSettings = useRef<boolean>(true);

  const { userSettings, updateTableSettings } = useUpdateUiSettings();

  const [canRowsReorder, setCanRowsReorder] = useState(
    userSettings?.tableV3?.[tableKey]?.sorting
      ? !userSettings?.tableV3?.[tableKey].sorting.sortModel.length
      : rowReordering,
  );

  const [initialSettings, setInitState] = useState<GridInitialStatePremium>(
    mergeColumnVisibilityModel(
      defaultPersonalSetting.tables[tableKey]?.hidden_columns || [],
      userSettings?.tableV3?.[tableKey],
      false,
    ),
  );

  const onSortModelChangeLocal = useCallback((model: GridSortModel) => {
    setCanRowsReorder(!model.length);
    saveCurrentSet();
  }, []);

  const saveCurrentSet = useCallback(
    async (keepOrder?: boolean) => {
      if (!needToUpdateSettings.current) return;
      apiRef.current.resetRowHeights();
      const { preferencePanel, ...currentState } = apiRef.current.exportState();
      if (!keepOrder) currentState.columns.orderedFields = [];
      setTableLoading(true);
      await updateTableSettings(tableKey as TableKeyEnum, currentState);
      setTableLoading(false);
    },
    [updateTableSettings, apiRef.current],
  );

  const clearSettings = useCallback(async () => {
    const currentState = apiRef.current.exportState();
    const defaultColumnOrder = memoColumns.map((item) => item.field);

    const dimensions = memoColumns.reduce((sum, column) => {
      const key = column.field;
      const { width, flex, ...value } = currentState.columns.dimensions[key];
      sum[key] = {
        ...value,
        ...(column.flex ? { flex: 1 } : {}),
        ...(column.minWidth ? { minWidth: column.minWidth } : {}),
        ...(column.maxWidth ? { maxWidth: column.maxWidth } : {}),
      };
      return sum;
    }, {});

    const clearState: GridInitialStatePremium = {
      columns: {
        columnVisibilityModel: createColumnVisibleModel(
          defaultPersonalSetting.tables[tableKey]?.hidden_columns || [],
        ),
        dimensions,
        orderedFields: defaultColumnOrder,
      },
      filter: {
        filterModel: {
          items: [],
        },
      },
      pinnedColumns: {
        left: [...(withColumnIndex ? ['id'] : [])],
        right: [],
      },
      density: 'standard',
      rowGrouping: {
        model: [],
      },
      sorting: {
        sortModel: [],
      },
    };
    setTableLoading(true);
    needToUpdateSettings.current = false;
    apiRef.current.restoreState(clearState);
    setInitState(clearState);

    await updateTableSettings(tableKey as TableKeyEnum, clearState);
    setTableLoading(false);
    needToUpdateSettings.current = true;
  }, [apiRef.current, tableKey, memoColumns, withColumnIndex]);

  return {
    initialSettings,
    clearSettings,
    saveCurrentSet,
    canRowsReorder,
    onSortModelChangeLocal,
  };
};

export default useTableSettings;
