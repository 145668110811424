import { StringFieldModel, useStringFieldModel } from '@models';
import { Dispatch, useEffect, useMemo, useState } from 'react';
import { ApproveActionEnum, IDrawRequest, ITeam } from '@interfaces';
import { useProjectTeamsAndCompanies } from '@hooks';
import { isChangeRequest } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  comment: StringFieldModel;
  nextTeam: string;
  setNextTeam: Dispatch<React.SetStateAction<string>>;
  teamsList: ITeam[];
  findNextTeamName: (id: string) => string;
  popupText: string;
  request: IDrawRequest;
  teamsIsLoading: boolean;
  approveActionLabel: string;
}

export const useDRApproveConfirmationModal = ({
  request,
  type,
  isFinalApprove,
  approveActionType,
}: {
  request: IDrawRequest;
  type: string;
  isFinalApprove: boolean;
  approveActionType: ApproveActionEnum;
}): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { companyTeamsWithoutCurrentUserTeam, teamsList, teamsIsLoading } =
    useProjectTeamsAndCompanies({
      currentReviewerTeam: request?.current_reviewer_team,
    });
  const comment = useStringFieldModel({
    initValue: '',
  });
  const [nextTeam, setNextTeam] = useState('');

  useEffect(() => {
    const nextTeamId =
      (flags?.['ENG_8495_new_approval_flow']
        ? request?.next_reviewer?.id
        : request?.next_reviewer_team_elevator?.id) || request?.current_reviewer_team?.id;
    if (!teamsList.length || !nextTeamId) return;
    setNextTeam(nextTeamId);
  }, [
    flags,
    teamsList.length,
    request?.next_reviewer_team_elevator?.id,
    request?.current_reviewer_team?.id,
    request?.next_reviewer?.id,
  ]);

  const findNextTeamName = (id) => teamsList.find((team) => team.id === id)?.name;

  const popupText = useMemo(() => {
    if (request?.totals?.current?.approved_amount || request?.approved_reallocation)
      return !isFinalApprove || isChangeRequest(request)
        ? 'To approve, please confirm the following:'
        : 'You are going to fund the request. Please, confirm the following:';

    if (type === 'Change') {
      return (
        'Are you sure you want to approve $0.00 for this\n' +
        'change request? All line item details for this request\n' +
        'are documented in the Requests tab.'
      );
    }
    if (type === 'Draw')
      return (
        'Are you sure you want approve $0.00?\n' +
        'All line item details for this request are documented\n' +
        'in the Requests tab.'
      );
  }, [request, type, isFinalApprove]);

  const approveActionLabel = useMemo(() => {
    let action = '';

    switch (approveActionType) {
      case ApproveActionEnum.FUND:
        action = 'Fund';
        break;
      case ApproveActionEnum.APPROVE:
        action = 'Approve';
        break;
    }
    return action;
  }, [approveActionType]);

  return {
    comment,
    nextTeam,
    setNextTeam,
    teamsList: request?.next_reviewer_team_elevator?.id
      ? companyTeamsWithoutCurrentUserTeam
      : teamsList,
    findNextTeamName,
    popupText,
    request,
    teamsIsLoading,
    approveActionLabel,
  };
};
