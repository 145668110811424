import React, { FC, memo, useCallback, useContext, useMemo } from 'react';
import { Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { currencyFormatter, isAllowed, notAllowNegative } from '@utils';
import {
  ColumnV2Width,
  CommonRowType,
  ColumnWidth,
  IMilestoneTotal,
  PermissionNamesEnums,
} from '@interfaces';
import { PermissionsContext } from '@context';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { TextInputWithTooltip } from '@components';

const PrefundingCost: FC<{ row: CommonRowType }> = ({ row }) => {
  const { apiUpdate, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'prefunding_cost');
  const prefunding_cost = useStringFieldModel({
    initError: error,
    initValue: (row['prefunding_cost'] || '').toString(),
    blockNavigationKey: 'prefunding_cost',
    withProgressCheck: true,
  });
  const { permissions } = useContext(PermissionsContext);

  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      row.localNew &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: prefunding_cost.floatValue,
        name: 'prefunding_cost',
        ...props,
      }),
    [prefunding_cost.floatValue],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: prefunding_cost.handleChange,
    afterEffect: () => prefunding_cost.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <TextInputWithTooltip
      sx={{ width: '100%', maxWidth: 400 }}
      value={prefunding_cost.value}
      type="currency"
      error={Boolean(error)}
      errorTip={error}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      onBlur={onBlur}
      onChange={onChange}
      dataTestName={`prefunding_cost_index_${row.index}`}
    />
  ) : (
    <Typography variant="body3" data-cy={`prefunding_cost_index_${row.index}`}>
      {currencyFormatter(row.prefunding_cost, '-')}
    </Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__prefunding_cost__value`}>
      {currencyFormatter(row.prefunding_cost, '-')}
    </Typography>
  );
};

const Memoized = memo(PrefundingCost);

const prefundingCost: MilestoneListColumnType = {
  name: 'prefunding_cost',
  initDataField: 'prefunding_cost',
  isEditable: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  columnText: 'Prefunding cost ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  width: ColumnWidth.MONEY,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText title="Project costs spent prior to loan closing">
      <ColumnLabel>Prefunding</ColumnLabel>
      <ColumnLabel> cost ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default prefundingCost;
