import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { AuthContext, PermissionsContext, useGetListData } from '@context';
import { useDropdownFieldModel, useStringFieldModel } from '@models';
import {
  EnumTypeForList,
  ErrorDual,
  IDocumentType,
  PermissionNamesEnums,
  QueryNamesEnums,
  UpdateDrawRequestListItemParam,
} from '@interfaces';
import { ControllerInterface, DocumentEditModalProps } from './interface';
import { getSortedByIndexMilestones, isCreatedByUser, isAllowed } from '@utils';
import {
  getDrawRequestDocumentsTypeList,
  getProjectDocumentTypes,
  updateDRDocument,
  updateProjectDocument,
} from '@globalService';

export const useDocumentEditModal = ({
  document,
  closeDocumentEditModal,
  deleteDocument,
  prId,
}: DocumentEditModalProps): ControllerInterface => {
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { permissions } = useContext(PermissionsContext);
  const { user } = useContext(AuthContext);
  const [documentLineItems, setDocumentLineItems] = useState<EnumTypeForList[]>([]);
  const { draw_request, document_type, id: documentId } = document || {};
  const drawRequestId = draw_request?.id;

  const projectDocumentTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_PROJECT_DOCUMENT_TYPES],
    getProjectDocumentTypes.bind(this),
    { enabled: Boolean(projectId || prId) && !drawRequestId },
  );

  const drawRequestDocumentsTypesQuery = useQuery<IDocumentType[], Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS_TYPES],
    getDrawRequestDocumentsTypeList.bind(this),
    { enabled: Boolean(projectId || prId) && Boolean(drawRequestId) },
  );

  const drawRequestMilestones = useGetListData({
    type: QueryNamesEnums.GET_DRAW_REQUEST_MILESTONES,
    keys: ['id', 'index', 'name', 'project_milestone'],
    args: { projectId: projectId || prId, drawRequestId },
    options: {
      skip: !(projectId || prId) || !drawRequestId,
    },
  });

  const projectMilestones = useGetListData({
    type: QueryNamesEnums.GET_PROJECT_MILESTONES,
    keys: ['id', 'index', 'name'],
    args: { projectId: projectId || prId },
    options: {
      skip: !(projectId || prId) || Boolean(drawRequestId),
    },
  });

  const milestones = useMemo(
    () => (drawRequestId ? drawRequestMilestones.data?.results : projectMilestones.data?.results),
    [drawRequestId, drawRequestMilestones.data, projectMilestones.data],
  );

  const lineItemOptions = useMemo(
    () =>
      getSortedByIndexMilestones(milestones)?.map((milestone) => ({
        ...milestone,
        name_display: `${milestone.project_milestone?.index || milestone.index}. ${milestone.name}`,
      })),
    [milestones],
  );

  useEffect(() => {
    const milestones_names = document.milestone_names;
    const initMilestones = milestones_names
      ?.map((milestone) => {
        const foundMilestone = lineItemOptions.find((o) => o.name === milestone);
        return foundMilestone;
      })
      .filter((milestone) => Boolean(milestone));

    if (initMilestones) {
      setDocumentLineItems(initMilestones);
    }
  }, [lineItemOptions]);

  const documentName = useStringFieldModel({
    initValue: document?.name || '',
    validationRule: (value) => Boolean(value?.trim()),
    validateOnChange: true,
  });

  const documentType = useDropdownFieldModel({
    initValue: null,
    validationRule: (value) => Boolean(value),
    validateOnChange: true,
  });

  const documentTypes = useMemo(() => {
    const types = drawRequestId
      ? drawRequestDocumentsTypesQuery.data
      : projectDocumentTypesQuery.data;

    return types?.map((type) => ({
      ...type,
      name_display: type.name,
    }));
  }, [drawRequestId, projectDocumentTypesQuery.data, drawRequestDocumentsTypesQuery.data]);

  useEffect(() => {
    if (document_type) {
      const type = documentTypes?.find((t) => t.name_display === document_type);
      documentType.setValue(type);
    }
  }, [document_type, documentTypes]);

  const updateDocumentQueries = useCallback(() => {
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_DOCUMENTS,
      { projectId: projectId || prId },
    ]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_PROJECT_MILESTONE_DOCS,
      { projectId: projectId || prId },
    ]);
    queryClient.invalidateQueries([
      QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
      { projectId: projectId || prId },
    ]);

    if (document.draw_request?.id || document.inspection_id) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_DOCUMENTS,
        { projectId: projectId || prId },
      ]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_DRAW_REQUEST_MILESTONE_DOCS,
        { projectId: projectId || prId },
      ]);

      queryClient.invalidateQueries([
        QueryNamesEnums.GET_INSPECTION_MILESTONE_DOCS,
        { projectId: projectId || prId },
      ]);
    }

    if (document.is_checklist_item) {
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_CHECKLIST,
        { projectId: projectId || prId },
      ]);

      if (document.draw_request?.id) {
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_DRAW_REQUEST_ITEM_CHECKLIST,
          { projectId: projectId || prId },
        ]);
      }
    }
  }, [drawRequestId, projectId, prId, document]);

  const updateProjectDocumentMutation = useMutation<
    Response,
    ErrorDual,
    UpdateDrawRequestListItemParam
  >(updateProjectDocument, {
    onSuccess: () => {
      closeDocumentEditModal();
      updateDocumentQueries();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const updateDRDocumentMutation = useMutation<
    Response,
    ErrorDual,
    UpdateDrawRequestListItemParam & { drawRequestId: string }
  >(updateDRDocument, {
    onSuccess: () => {
      closeDocumentEditModal();
      updateDocumentQueries();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const handleSubmitClick = useCallback(() => {
    const isNameValid = documentName.validate();
    const isTypeValid = documentType.validate();

    if (!isNameValid || !isTypeValid || !documentType.value?.id) return;

    if (drawRequestId) {
      updateDRDocumentMutation.mutate({
        projectId: projectId || prId,
        documentId,
        drawRequestId,
        json: {
          name: documentName.value,
          document_type: documentType.value?.id,
          ...(documentLineItems?.length && {
            milestone_ids: documentLineItems.map((item) => item.id),
          }),
        },
      });
    } else {
      updateProjectDocumentMutation.mutate({
        projectId: projectId || prId,
        documentId,
        json: {
          name: documentName.value,
          document_type: documentType.value?.id,
          ...(documentLineItems?.length && {
            milestone_ids: documentLineItems.map((item) => item.id),
          }),
        },
      });
    }
  }, [documentName.value, document, documentType.value, documentLineItems]);

  const handleDeleteClick = useCallback(() => {
    if (documentId) {
      deleteDocument(documentId, document.name);
      closeDocumentEditModal();
    }
  }, [documentId, document.name]);

  const canBeDeleted = useMemo(
    () =>
      isAllowed(PermissionNamesEnums.DOCUMENTS_EDIT, permissions) ||
      isCreatedByUser(document?.created_by?.id, user.id),
    [document?.created_by?.id, permissions, user.id],
  );

  return {
    documentName,
    documentType,
    lineItemOptions,
    documentLineItems,
    setDocumentLineItems,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting: drawRequestId
      ? updateDRDocumentMutation.isLoading
      : updateProjectDocumentMutation.isLoading,
    documentTypes,
    optionsLoading: drawRequestId ? drawRequestMilestones.isLoading : projectMilestones.isLoading,
    canBeDeleted,
  };
};
