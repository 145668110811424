import { Box, Typography } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { tableHeaders as headers, isDrawRequest } from '@utils';
import { ControllerInterface } from '../controller';
import { ReactTable } from '@components';
import { useLaunchDarklyFlags } from '@context';

export const DrawsLog: FC<{ controller: ControllerInterface }> = ({ controller }) => {
  const { previousDrawRequest } = controller;
  const flags = useLaunchDarklyFlags();

  const columns = useMemo(
    () => [
      headers.requestWithNumber({
        accessor: 'counter_per_request_type',
      }),
      headers.amountAndPrecents({
        header: 'Approved for this draw ($/%)',
        accessor: 'approved_amount',
        precentAccessor: 'approved_amount_rate',
        FooterStatic: () => null,
      }),
      headers.amount({
        header: 'Holdback($)',
        accessor: 'construction_holdback',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.amount({
        header: 'Borrower Equity($)',
        accessor: 'borrower_equity',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      headers.amountAndPrecents({
        header: 'Lender allowance ($/%)',
        accessor: 'approved_amount_cumulative',
        precentAccessor: 'lender_allowance_rate',
        FooterStatic: () => null,
      }),
      headers.date({
        header: 'Submitted date',
        accessor: 'submitted_at',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
      flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
        ? headers.amount({
            header: 'Net disbursed amount($)',
            accessor: 'total_transactable_amount',
            isLoading: false,
            typoprops: {
              variant: 'printBody1',
            },
          })
        : headers.amount({
            header: 'Net disbursed amount($)',
            accessor: 'construction_holdback_with_fees',
            isLoading: false,
            typoprops: {
              variant: 'printBody1',
            },
          }),
      headers.date({
        header: 'Disbursement date',
        accessor: 'disbursed_at',
        isLoading: false,
        typoprops: {
          variant: 'printBody1',
        },
      }),
    ],
    [flags],
  );
  const data = previousDrawRequest.filter(isDrawRequest);
  if (!data.length) return null;
  return data.length ? (
    <Box sx={{ breakInside: 'avoid' }}>
      <Typography variant="printH2" pr="15px" pl="15px" data-cy="report_draws_section_title">
        Draws to date
      </Typography>
      <ReactTable data={data} columns={columns} footer={false} source="draws_log" />
    </Box>
  ) : null;
};
