import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Button, Skeleton, Stack, Typography } from '@mui/material';

import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import {
  approximationEqual,
  calculateRequestedAdjustment,
  checkIsAdmin,
  currencyFormatter,
  getMilestoneGroupsTagsIds,
  getTeamRole,
  isCostTypeContingency,
  isAllowed,
  useBlockerCell,
  useBlockerFooter,
} from '@utils';
import { TextInputWithTooltip } from '@components';
import { AuthContext, PermissionsContext } from '@context';
import {
  ColumnV2Width,
  CommonRowType,
  ContingencyDescriptionEnum,
  IMilestoneTotal,
  PermissionNamesEnums,
} from '@interfaces';

const RequestedAdjustments: FC<{ row: CommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const {
    apiUpdate,
    cellTooltipTexts,
    getLineItemError,
    highLightCellByData,
    resetMutation,
    source,
    updateRequestedChanges,
  } = useContext(TableContext);
  const error = getLineItemError(row.id, 'requested_adjustments');
  const tags = useMemo(() => getMilestoneGroupsTagsIds(row.tags), [row.tags]);
  const tooltipText = cellTooltipTexts?.[row.id]?.['requested_adjustments'];

  const requested_adjustments = useStringFieldModel({
    initError: error,
    initValue: (row['requested_adjustments'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'requested_adjustments',
  });

  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const isBlocked = useBlockerCell({
    milestoneId: row.id,
    key: 'requested_adjustments',
    tags,
  });

  const isEditable = useMemo(
    () =>
      row.localIsUserCreator &&
      row.activeToEdit &&
      (isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
        isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions) ||
        checkIsAdmin(teamRole)),
    [permissions, teamRole, row],
  );

  const effect = useCallback(
    (props) =>
      apiUpdate({
        milestoneId: row.id,
        value: requested_adjustments.floatValue,
        needToCompare: false,
        isEqualInit: approximationEqual(
          row.requested_adjustments,
          requested_adjustments.floatValue,
        ),
        name: 'requested_adjustments',
        ...props,
      }),
    [requested_adjustments.floatValue, row.requested_adjustments],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: requested_adjustments.handleChange,
    resetter: () => resetMutation({ milestone: row.id, json: { requested_adjustments: 0 } }),
    afterEffect: () => requested_adjustments.changeNavigationBlockContext(false),
  });

  const getTooltipActionButtons = () => (
    <Stack direction="row" justifyContent="flex-end" mt={2} sx={{ width: '100%' }} spacing={2}>
      <Button
        variant="text"
        size="small"
        onClick={() => {
          const clearedValue = row.requested_adjustments?.toString() || '';
          requested_adjustments.setValue(clearedValue);
          effect({ value: clearedValue });
        }}
      >
        Clear input
      </Button>

      <Button
        variant="new"
        size="small"
        color="secondary"
        onClick={() => {
          const requestedAdjustmentValue = calculateRequestedAdjustment(row, +row.requested_amount);
          effect({ value: requestedAdjustmentValue, isEqualInit: false });
          if (updateRequestedChanges) {
            updateRequestedChanges({
              value: requestedAdjustmentValue,
              requestedAmount: row.requested_amount,
              milestoneId: row.id,
              key: 'requested_adjustments',
            });
          }
          highLightCellByData(row.id, 'requested_adjustments');
          highLightCellByData(row.id, 'requested_revised_estimate');
        }}
      >
        Increase change
      </Button>

      <Button
        variant="new"
        size="small"
        color="secondary"
        onClick={() => {
          effect({
            value: +row.balance_to_finish,
            name: 'requested_amount',
            isEqualInit: false,
          });
          highLightCellByData(row.id, 'requested_amount');
        }}
      >
        Adjust draw
      </Button>
    </Stack>
  );

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable ? (
    <TextInputWithTooltip
      onBlur={onBlur}
      value={requested_adjustments.value}
      onChange={onChange}
      error={Boolean(error)}
      type="currency"
      open={Boolean(tooltipText?.open)}
      tooltipText={
        row.disabled?.reason ||
        (isCostTypeContingency(row.cost_type) && ContingencyDescriptionEnum.COLUMN_DESCRIPTION) ||
        (tooltipText?.text && (
          <Stack sx={{ p: 1 }} spacing={2}>
            <Typography
              variant="label"
              dangerouslySetInnerHTML={{ __html: tooltipText.text }}
              sx={{ whiteSpace: 'pre-line' }}
            />
            <Stack direction="row" justifyContent="flex-end">
              <Button
                variant="new"
                size="small"
                color="secondary"
                target="_blank"
                href="https://trustpointinc.zendesk.com/hc/en-us/sections/13524727569811-Draws-and-Reallocation"
                rel="noreferrer"
                data-cy={`${source}__requested_changes_update__get_help__button`}
              >
                Get help
              </Button>
            </Stack>
          </Stack>
        ))
      }
      tooltipActionButtons={getTooltipActionButtons()}
      disabled={row.disabled?.value}
      errorTip={error}
      dataTestName={`milestone_row_requested_changes_index_${row?.project_milestone?.index}`}
      hasBorder={requested_adjustments.hasBorder}
      iconDataTestName={`${source}__body__requested_adjustments__error_icon__${row?.project_milestone?.index}`}
    />
  ) : (
    <Typography variant="body3">{currencyFormatter(row.requested_adjustments, '-')}</Typography>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Typography variant="body3SemiBold" data-cy="milestone_row_requested_changes_total">
      {currencyFormatter(row.requested_budget_change || 0)}
    </Typography>
  );
};

const requestedAdjustments: MilestoneListColumnType = {
  name: 'requested_adjustments',
  columnText: 'Requested +/- change ($)',
  isEditable: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_CREATE, permissions) ||
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_MANUAL_CREATE, permissions),
  renderCell: ({ row }) => <RequestedAdjustments row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Requested change to revised scheduled value">
      <ColumnLabel>Requested</ColumnLabel>
      <ColumnLabel> +/- change ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default requestedAdjustments;
