import {
  GridCellModes,
  GridCellModesModel,
  GridCellParams,
  GridColDef,
  GridRowsProp,
  GridState,
} from '@mui/x-data-grid-premium';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { ExtendGridStatePremium, IMilestone } from '@interfaces';
import { indexColumn, rowOrderColumn } from '../components/systemColumns';

const useTableState = (
  apiRef: React.MutableRefObject<GridApiPremium>,
  columns: GridColDef[],
  rows: GridRowsProp,
  maxRowCount: number,
  errors?: {
    rowErrors: any;
    columnErrors: any;
  },
  totals?: Partial<IMilestone>,
  rowReordering?: boolean,
  withColumnIndex?: boolean,
) => {
  const [tableLoadingBlock, setTableLoading] = useState(false);
  const [cellModesModel, setCellModesModel] = useState<GridCellModesModel>({});

  const onCellClick = useCallback((params: GridCellParams, event: React.MouseEvent) => {
    if (!params.isEditable) {
      return;
    }

    if (
      (event.target as any).nodeType === 1 &&
      !event.currentTarget.contains(event.target as Element)
    ) {
      return;
    }

    setCellModesModel((prevModel) => {
      return {
        // Revert the mode of the other cells from other rows
        ...Object.keys(prevModel).reduce(
          (acc, id) => ({
            ...acc,
            [id]: Object.keys(prevModel[id]).reduce(
              (acc2, field) => ({
                ...acc2,
                [field]: { mode: GridCellModes.View },
              }),
              {},
            ),
          }),
          {},
        ),
        [params.id]: {
          // Revert the mode of other cells in the same row
          ...Object.keys(prevModel[params.id] || {}).reduce(
            (acc, field) => ({ ...acc, [field]: { mode: GridCellModes.View } }),
            {},
          ),
          [params.field]: { mode: GridCellModes.Edit },
        },
      };
    });
  }, []);

  const onCellModesModelChange = useCallback((newModel: GridCellModesModel) => {
    setCellModesModel(newModel);
  }, []);

  const memoColumns = useMemo<GridColDef[]>(() => {
    const columnsPrefix = [];
    if (withColumnIndex) columnsPrefix.push(indexColumn);
    if (rowReordering) columnsPrefix.push(rowOrderColumn);

    return [...columnsPrefix, ...columns];
  }, [withColumnIndex, rowReordering, columns]);

  const renderRows = useMemo(() => {
    const rowsToRedner = (totals ? [...rows, totals] : rows).map((item) => ({
      ...item,
      __reorder__: item.name,
    }));
    return maxRowCount ? rowsToRedner.slice(0, maxRowCount) : rowsToRedner;
  }, [rows, totals, maxRowCount]);

  const isCellEditable = useCallback(
    (params: GridCellParams) => {
      if (params.row.id === 'totals') return false;
      const state = apiRef.current.state as ExtendGridStatePremium;
      const errorForCurrentCell = state.additionalData?.rowErrors?.[params.field]?.[params.row.id];
      const byMilestone = params.colDef['editableByMilestone']?.(params.row);
      return !!errorForCurrentCell || !!byMilestone;
    },
    [apiRef.current],
  );

  useEffect(() => {
    if (errors)
      apiRef.current.setState((state: GridState) => ({
        ...state,
        additionalData: errors,
      }));
    apiRef.current.forceUpdate();
  }, [errors]);

  return {
    tableLoadingBlock,
    setTableLoading,
    renderRows,
    memoColumns,
    isCellEditable,
    cellModesModel,
    onCellClick,
    onCellModesModelChange,
  };
};

export default useTableState;
