import React, { ChangeEvent } from 'react';
import {
  Chip,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { colors } from '@theme';
import { InspectionIcon } from '@svgAsComponents';

interface CustomRadioGroupProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  options: { value: string; label: string; logo?: string; isPremium?: boolean }[];
  dataTestName?: string;
  direction?: 'row' | 'column';
  disabled?: boolean;
  disabledTooltipText?: string;
  radioButtonStyle?: SxProps;
}

const CustomRadioGroup = ({
  value,
  onChange,
  options,
  dataTestName,
  direction = 'row',
  disabled,
  disabledTooltipText,
  radioButtonStyle = {},
}: CustomRadioGroupProps) => {
  return (
    <RadioGroup
      aria-label="inputType"
      name="inputType"
      value={value}
      onChange={onChange}
      data-cy={dataTestName}
    >
      <Stack
        direction={direction}
        alignItems={direction === 'column' ? 'flex-start' : 'center'}
        flexWrap="wrap"
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            data-cy={`${dataTestName}_${option.value.toLowerCase()}`}
            control={<Radio disabled={disabled} />}
            label={
              option.logo ? (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  spacing={1}
                  sx={{ mr: 4 }}
                >
                  <img
                    alt="service agency logo"
                    src={option.logo}
                    style={{
                      minHeight: '34px',
                      maxHeight: '34px',
                    }}
                  />
                  {option.isPremium && (
                    <Chip
                      sx={{
                        backgroundColor: colors.status.orange.lighter,
                        borderRadius: '2px',
                        height: '24px',
                        padding: '4px 2px',
                        '& .MuiChip-label': {
                          padding: 0,
                        },
                      }}
                      label={
                        <Typography
                          variant="labelSemiBold"
                          sx={{
                            color: colors.status.orange.medium,
                            letterSpacing: '0.3px',
                            padding: '0 4px',
                          }}
                        >
                          Premium
                        </Typography>
                      }
                      icon={<InspectionIcon size={16} color={colors.status.orange.medium} />}
                    />
                  )}
                </Stack>
              ) : (
                <Tooltip title={disabled ? disabledTooltipText : ''}>
                  <Typography variant="body2" sx={disabled ? { color: colors.neutral.dark } : {}}>
                    {option.label}
                  </Typography>
                </Tooltip>
              )
            }
            sx={{
              m: 0,
              ml: 1,
              '& .MuiFormControlLabel-label': { display: 'flex' },
              mb: direction === 'row' ? 3 : 0,
              ...radioButtonStyle,
            }}
          />
        ))}
      </Stack>
    </RadioGroup>
  );
};

export default CustomRadioGroup;
