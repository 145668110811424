import { dataProvider } from '@api';
import {
  ArgumentsProjectDrawRequestPostMilestoneById,
  ArgumentsProjectDrawRequestPostMilestones,
  ArgumentsProjectPostMilestone,
  projectDrawRequestMilestoneByIdApi,
  projectDrawRequestMilestonesApi,
  projectMilestonesApi,
} from '@globalService';

export const postProjectMilestoneV2 = ({
  projectId,
  ...json
}: ArgumentsProjectPostMilestone): Promise<Response> =>
  dataProvider.post(projectMilestonesApi(projectId), { json });

export const postProjectDrawRequestMilestonesV2 = ({
  projectId,
  drawRequestId,
  ...json
}: ArgumentsProjectDrawRequestPostMilestones): Promise<Response> =>
  dataProvider.post(projectDrawRequestMilestonesApi(projectId, drawRequestId), { json });

export const patchProjectDrawRequestMilestoneByIdV2 = ({
  projectId,
  drawRequestId,
  milestoneId,
  ...json
}: ArgumentsProjectDrawRequestPostMilestoneById): Promise<Response> =>
  dataProvider.patch(projectDrawRequestMilestoneByIdApi(projectId, drawRequestId, milestoneId), {
    json,
  });

export const deleteProjectDrawRequestMilestoneByIdV2 = ({
  projectId,
  drawRequestId,
  milestoneId,
}: ArgumentsProjectDrawRequestPostMilestoneById): Promise<Response> =>
  dataProvider.delete(projectDrawRequestMilestoneByIdApi(projectId, drawRequestId, milestoneId));
