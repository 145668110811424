import React, { ReactElement, useContext, useMemo } from 'react';
import { AuthContext, PermissionsContext } from '@context';
import { isAllowed, percentFormatter } from '@utils';
import { ICompanyFull, PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { Box, Stack, Typography } from '@mui/material';
import { BarChart } from '@components';
import { colors } from '@theme';
import { useQuery } from 'react-query';
import { getMyCompany } from '@globalService';

export interface ControllerInterface {
  incompletedSteps: number;
  stepsLength: number;
  steps: { personalInfo: Date; companyInfo?: Date; projectInfo: Date };
  progressElement: ReactElement;
  company: ICompanyFull;
}

export const useOnboardingSteps = (): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const { permissions } = useContext(PermissionsContext);

  const companyQuery = useQuery<ICompanyFull, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const steps = useMemo(
    () => ({
      personalInfo: user?.personal_information_first_updated_at,
      ...(isAllowed(PermissionNamesEnums.COMPANY_EDIT, permissions)
        ? { companyInfo: user?.company_information_first_updated_at }
        : {}),
      projectInfo: user?.project_information_first_accessed_at,
    }),
    [user, permissions],
  );

  const stepsLength = useMemo(
    () => Object.values(steps)?.filter((item) => item !== undefined)?.length,
    [steps],
  );

  const completedSteps = useMemo(
    () => Object.values(steps)?.filter((item) => item)?.length,
    [steps],
  );

  const incompletedSteps = useMemo(
    () => stepsLength - completedSteps,
    [stepsLength, completedSteps],
  );

  const percentageCompletion = useMemo(
    () => (completedSteps / stepsLength) * 100,
    [completedSteps, stepsLength],
  );

  const progressElement = useMemo(
    () => (
      <Stack direction="row" alignItems="center">
        <Box sx={{ width: '300px' }}>
          <BarChart
            stacked
            orientation="horizontal"
            values={[completedSteps, incompletedSteps]}
            total={stepsLength}
            barWidth={16}
            borderRadius="4px"
            colors={[colors.status.information.medium, colors.neutral.lighter]}
          />
        </Box>
        <Typography sx={{ ml: 2, textWrap: 'nowrap' }} variant="body1SemiBold">
          {`${percentFormatter({ value: percentageCompletion, roundTo: 0 })} Completed`}
        </Typography>
      </Stack>
    ),
    [completedSteps, incompletedSteps, stepsLength, percentageCompletion],
  );

  return {
    incompletedSteps,
    stepsLength,
    steps,
    progressElement,
    company: companyQuery.data,
  };
};
