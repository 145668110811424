import React, { FC } from 'react';
import { Typography, Stack, Button } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import { Popup, CustomCurrencyTextField } from '@components';
import { PopupTypeEnum } from '@interfaces';
import { BudgetReviewIcon } from '@svgAsComponents';
import { colors } from '@theme';
import * as Controller from './controller';

interface LumpSumModalProps {
  onClose?: () => void;
  open: boolean;
  fieldKey: string;
  onSuccess?: () => void;
  initValue: string;
}

const LumpSumModal: FC<LumpSumModalProps> = ({ onClose, onSuccess, open, fieldKey, initValue }) => {
  const { lumpSumField, lumpSumError, onEnterLumpSum, onResetLumpSum, values, isSubmitting } =
    Controller.useLumpSumModal({
      onClose,
      onSuccess,
      fieldKey,
      initValue,
    });

  return (
    <Popup open={open} type={PopupTypeEnum.GENERAL} icon={BudgetReviewIcon} title={values?.title}>
      <Stack alignItems="center" sx={{ width: '100%' }}>
        <Typography variant="body2" mb={3} sx={{ whiteSpace: 'pre-wrap', textAlign: 'center' }}>
          {values?.text}
        </Typography>

        <CustomCurrencyTextField
          label="Draw amount"
          field={lumpSumField}
          submitCallback={onEnterLumpSum}
          autoFocus
          inputProps={{
            'data-cy': `lump_sum__modal__${fieldKey}__input`,
          }}
        />
        {lumpSumError && (
          <Typography
            variant="label"
            color={colors.status.error.medium}
            sx={{ alignSelf: 'flex-start' }}
          >
            {lumpSumError}
          </Typography>
        )}
        <Stack
          direction="row"
          justifyContent="flex-end"
          spacing={2}
          sx={{ mt: 7.5, width: '100%' }}
        >
          <Button
            variant="text"
            onClick={onClose}
            data-cy={`lump_sum__modal__${fieldKey}__cancel__button`}
          >
            Cancel
          </Button>
          <Button
            variant="new"
            color="error"
            onClick={onResetLumpSum}
            data-cy={`lump_sum__modal__${fieldKey}__reset__button`}
          >
            Reset
          </Button>
          <LoadingButton
            loading={isSubmitting}
            onClick={onEnterLumpSum}
            data-cy={`lump_sum__modal__${fieldKey}__enter__button`}
          >
            Enter
          </LoadingButton>
        </Stack>
      </Stack>
    </Popup>
  );
};

export default LumpSumModal;
