import { useUpdateUiSettings } from '@hooks';
import React from 'react';
import { ComponentProps } from './interface';

export function withSettingsProtect(Component) {
  return function ProtectedComponent(props: ComponentProps) {
    const { userSettings } = useUpdateUiSettings();

    if (!userSettings) {
      return null;
    }

    return <Component {...props} />;
  };
}
