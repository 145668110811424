import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  DateValidationEnum,
  IInspection,
  IMilestone,
  IRightDrawerParams,
  PermissionNamesEnums,
  QueryNamesEnums,
  RequestTableTabsEnum,
  ServiceActionEnum,
  ServiceOrderStatusEnum,
  TableKeyEnum,
  UpdateInspectionPayload,
} from '@interfaces';
import { useDateFieldModel } from '@models';
import {
  getInspectionDocuments,
  getInspectionMilestones,
  getProjectInspectionById,
  patchInspectionToProject,
} from '@globalService';
import {
  getDateValidationRule,
  getHookState,
  getInspectionNameByRole,
  getTeamRole,
  isPendingService,
  isServiceProviderAutomatedByCS,
  isAllowed,
  isTruePicInspection,
} from '@utils';
import {
  useCommentsAndDocumentsPreview,
  useImagePicker,
  useInspectionFields,
  useLineItemsFilter,
  useRightMenu,
  useSafeSnackbar,
} from '@hooks';
import { AuthContext, PermissionsContext, SettingsContext, useGetData } from '@context';
import { LineItemFilterValues, onlyProgressPhotos } from '@constants';
import {
  getInspectionTotals,
  getInitColumns,
  getStatusChipProps,
  getFilterOptions,
  updateAllQueries,
  getProviderStatusChipProps,
} from './utils';
import { ControllerInterface } from './interfaces';

export const useInspectionEnterResult = (): ControllerInterface => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { inspectionId, action, projectId } = useParams();
  const { user } = useContext(AuthContext);
  const { settings } = useContext(SettingsContext);
  const { permissions } = useContext(PermissionsContext);
  const queryClient = useQueryClient();
  const imagePicker = useImagePicker();
  const { enqueueSnackbar } = useSafeSnackbar();
  const [editedFieldKey, setEditedFieldKey] = useState<string>('');

  const teamRole = getTeamRole(user);

  const project = useGetData({
    type: QueryNamesEnums.GET_PROJECT,
    keys: [
      'id',
      'name',
      'address',
      'comments_preview',
      'status',
      'status_change_reason',
      'inspection_additional_contact_name',
      'inspection_additional_contact_phone',
      'inspection_entry_access_code',
      'inspection_primary_contact_user_id',
    ],
    args: { projectId },
  });

  const {
    additionalContactName,
    additionalContactPhone,
    accessCode,
    primaryContactUserNotRequired,
    primaryContactUserList,
    isBorrowerUsersLoading,
    inspectionRequestedAt,
    primaryContactPhone,
    isContactListHasUsersWithPhone,
  } = useInspectionFields({
    project: project.data,
  });

  const inspectionCompletedAt = useDateFieldModel({
    initValue: null,
    validationRule: (value) =>
      getDateValidationRule({
        value,
        rule: DateValidationEnum.LESS_OR_EQUAL,
        maxDate: new Date(),
      }),
  });

  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [isApprovalPopupOpen, setIsApprovalPopupOpen] = useState<boolean>(false);
  const [rightDrawerParams, setRightDrawerParams] = useState<IRightDrawerParams>({
    projectId,
    inspectionId,
  });

  const imageContainer = useRef();

  const {
    filterValue,
    handleFilterClick,
    defaultOptions,
    isMilestoneMutatingOrFetching,
    filterKey,
  } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.INSPECTION_RESULTS,
  });

  const { updateCommentsPreviewInfo } = useCommentsAndDocumentsPreview({
    projectId,
    inspectionId,
  });

  const { handleRightDrawerOpenerClick, ...rightMenu } = useRightMenu({
    onClose: updateCommentsPreviewInfo,
  });

  const updateRightDrawer = useCallback(
    ({ tab }: { tab?: RequestTableTabsEnum }) =>
      () => {
        handleRightDrawerOpenerClick({
          title: tab ? 'Inspection' : 'Comments',
        });
        setRightDrawerParams({
          tab,
          projectId,
          inspectionId,
        });
      },
    [projectId, inspectionId, handleRightDrawerOpenerClick],
  );

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_INSPECTION_BY_ID, { projectId, inspectionId }],
      queryFn: getProjectInspectionById.bind(this, { projectId, inspectionId }),
    },
    {
      queryKey: [
        QueryNamesEnums.GET_INSPECTION_DOCUMENTS,
        { projectId, inspectionId, query: onlyProgressPhotos },
      ],
      queryFn: getInspectionDocuments.bind(this, {
        projectId,
        inspectionId,
        query: onlyProgressPhotos,
      }),
    },
  ]);

  const inspectionMilestonesQuery = useQuery<{ results: IMilestone[] }, Error>(
    [QueryNamesEnums.GET_INSPECTION_MILESTONES, { projectId, inspectionId, filterKey }],
    getInspectionMilestones.bind(this, { projectId, inspectionId, filterKey }),
    { enabled: Boolean(projectId && inspectionId) },
  );

  const inspection = useMemo(() => requestedDataQueries[0].data, [requestedDataQueries[0].data]);
  const inspectionPhotos = useMemo(
    () => requestedDataQueries[1].data?.results,
    [requestedDataQueries[1].data?.results],
  );
  const inspectionMilestones = useMemo(
    () => inspectionMilestonesQuery.data?.results,
    [inspectionMilestonesQuery.data?.results],
  );

  useEffect(() => {
    if (inspection?.completed_at) {
      inspectionCompletedAt.setValue(new Date(inspection.completed_at));
    } else if (inspection?.ready_for_review_at) {
      inspectionCompletedAt.setValue(new Date(inspection.ready_for_review_at));
    }
  }, [inspection?.completed_at, inspection?.ready_for_review_at]);

  const updateQueries = useCallback(() => {
    updateAllQueries(queryClient, {
      projectId,
      inspectionId,
      drawRequestId: inspection?.draw_request?.id,
      requestedDataQueries,
    });
  }, [queryClient, projectId, inspectionId, inspection, requestedDataQueries]);

  const confirmInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        updateQueries();
        setIsApprovalPopupOpen(true);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_INSPECTIONS);
      },
    },
  );

  const updateInspection = useMutation<IInspection, Error, UpdateInspectionPayload>(
    patchInspectionToProject,
    {
      onSuccess: () => {
        setEditedFieldKey('');
        updateQueries();
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const confirmCallBack = useCallback(async () => {
    if (inspectionCompletedAt.validate()) {
      await confirmInspection.mutateAsync({
        projectId,
        inspectionData: {
          inspectionId,
          status: ServiceOrderStatusEnum.COMPLETED,
          completed_at: inspectionCompletedAt.value,
        },
      });
    }
  }, [projectId, inspectionId, inspectionCompletedAt]);

  const openFile = useCallback(() => {
    if (inspection?.reports?.length) {
      imagePicker.open([inspection.reports?.[0]]);
    }
  }, [inspection?.reports]);

  useEffect(() => {
    imagePicker.close();
    openFile();
  }, [inspection?.reports]);

  const isInspectionLinkedToDR = useMemo(() => Boolean(inspection?.draw_request?.id), [inspection]);

  const isEditableByCSOnly = useMemo(
    () =>
      isAllowed(
        [PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS, PermissionNamesEnums.SERVICES_CS_EDIT],
        permissions,
      ),
    [permissions],
  );

  const isEditable = useMemo(() => {
    const hasInspectionEditPermission = isAllowed(
      PermissionNamesEnums.INSPECTIONS_EDIT,
      permissions,
    );
    const hasCSEditPermission = isAllowed(PermissionNamesEnums.SERVICES_CS_EDIT, permissions);
    const hasCSAccessPermission = isAllowed(
      PermissionNamesEnums.CUSTOMER_SUCCESS_ACCESS,
      permissions,
    );

    const isPseudoAutomated = isServiceProviderAutomatedByCS(
      inspection?.inspection_agency?.service,
    );
    const isPending = isPendingService(inspection?.status);

    return (
      (action === ServiceActionEnum.EDIT &&
        (hasInspectionEditPermission || (isPseudoAutomated && hasCSEditPermission))) ||
      (isPending && isPseudoAutomated && hasCSAccessPermission)
    );
  }, [permissions, inspection?.status, inspection?.inspection_agency?.service, action]);

  const initColumns = useMemo(
    () => getInitColumns(isEditable, isInspectionLinkedToDR),
    [isEditable, isInspectionLinkedToDR],
  );

  const goBack = useCallback(
    () => navigate(state || `/projects/${projectId}/services/all/`),
    [state],
  );

  const inspectionName = getInspectionNameByRole({
    teamRole,
    inspectionAgency: inspection?.inspection_agency,
  });

  const totals = useMemo(
    () => getInspectionTotals(inspection, filterValue, defaultOptions),
    [inspection, filterValue, defaultOptions],
  );

  const statusChipProps = useMemo(
    () => getStatusChipProps(inspection, settings),
    [inspection, settings],
  );

  const providerStatusChipProps = useMemo(
    () => getProviderStatusChipProps(inspection, settings),
    [inspection, settings],
  );

  const handleUpdateServiceOrderField = useCallback(
    async (fieldKey, value) => {
      await updateInspection.mutateAsync({
        projectId,

        inspectionData: {
          inspectionId,
          [fieldKey]: value,
          ...(fieldKey === 'status' && inspectionCompletedAt.value && inspectionCompletedAt.isValid
            ? { completed_at: inspectionCompletedAt.value }
            : {}),
        },
      });
    },
    [projectId, inspectionId, inspectionCompletedAt],
  );

  const isUserHasEditPermission = useMemo(
    () => isAllowed(PermissionNamesEnums.INSPECTIONS_EDIT, permissions),
    [permissions],
  );

  const isCommentsAvailable = useMemo(
    () => isAllowed(PermissionNamesEnums.COMMENTS_VIEW, permissions),
    [permissions],
  );
  const isViewRestricted = useMemo(() => {
    return !isAllowed(PermissionNamesEnums.INSPECTIONS_VIEW, permissions);
  }, [permissions]);

  return {
    state: getHookState(requestedDataQueries[0]),
    inspectionCompletedAt,
    inspection,
    inspectionRefetch: updateQueries,
    project: project.data,
    showPreview,
    setShowPreview,
    confirmCallBack,
    imagePicker,
    imageContainer,
    openFile,
    initColumns,
    isEditable,
    goBack,
    milestones: inspectionMilestones || [],
    drawRequestNumber: inspection?.draw_request?.number,
    inspectionPhotos,
    inspectionName,
    rightDrawerParams,
    rightMenu,
    updateRightDrawer,
    totals,
    filterValue,
    handleFiltersChange: handleFilterClick,
    filterOptions: getFilterOptions(defaultOptions),
    isMilestoneMutatingOrFetching,
    isInspectionLinkedToDR,
    activeDocumentId: imagePicker.pdf?.[0]?.id || imagePicker.gallery?.[0]?.id,
    isApprovalPopupOpen,
    setIsApprovalPopupOpen,
    statusChipProps,
    isEditableByCSOnly,
    inspectionFields: {
      additionalContactName,
      additionalContactPhone,
      accessCode,
      primaryContactUserNotRequired,
      primaryContactUserList,
      isBorrowerUsersLoading,
      inspectionRequestedAt,
      primaryContactPhone,
      isContactListHasUsersWithPhone,
    },
    isTruePicInspection: isTruePicInspection(inspection?.inspection_agency?.service),
    isCommentsAvailable,
    providerStatusChipProps,
    editedFieldKey,
    setEditedFieldKey,
    handleUpdateServiceOrderField,
    isUserHasEditPermission,
    isMutating: confirmInspection.isLoading || updateInspection.isLoading,
    isViewRestricted,
  };
};
