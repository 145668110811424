import { useEffect, useMemo } from 'react';
import { useQueries } from 'react-query';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';

import { useLineItemsFilter } from '@hooks';
import { LineItemFilterValues } from '@constants';
import { IFilterOption, QueryNamesEnums, TableKeyEnum } from '@interfaces';
import { checkMissingValueInOptions, getPHBFilterOptions } from '@utils';
import { getProjectModels } from '@globalService';

interface IPHBFilters {
  filterValue: string;
  handleFilterClick: (value: string) => void;
  filterOptions: IFilterOption[];
  filterTotalKey: string;
  filterKey: string;
}

const usePHBFilters = ({ tableKey }: { tableKey: TableKeyEnum }): IPHBFilters => {
  const { projectId } = useParams();
  const isRequestTable = useMemo(
    () => tableKey === TableKeyEnum.PHB_REQUEST_LINE_ITEMS,
    [tableKey],
  );

  const requestedDataQueries = useQueries([
    {
      queryKey: [QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }],
      queryFn: getProjectModels.bind(this, projectId),
    },
  ]);
  const projectModelsData = requestedDataQueries[0].data;

  const { filterValue, handleFilterClick, setFilterValue } = useLineItemsFilter({
    defaultState: LineItemFilterValues.ALL.filterValue,
    tableKey: TableKeyEnum.PHB_LINE_ITEMS,
  });

  const filterOptions = useMemo(() => getPHBFilterOptions(isRequestTable), [isRequestTable]);

  useEffect(() => {
    if (projectModelsData && checkMissingValueInOptions(filterValue, filterOptions)) {
      setFilterValue(filterOptions[0]?.filterValue);
    }
  }, [filterOptions, filterValue, projectModelsData]);

  const filterTotalKey = useMemo(
    () => find(filterOptions, { filterValue })?.totalKey || 'all',
    [filterOptions, filterValue],
  );

  const filterKey = useMemo(
    () => find(filterOptions, { filterValue })?.filterKey || 'all',
    [filterOptions, filterValue],
  );

  return {
    filterValue,
    handleFilterClick,
    filterOptions,
    filterTotalKey,
    filterKey,
  };
};

export default usePHBFilters;
