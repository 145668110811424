import React, { FC, useState, useRef, useEffect } from 'react';
import { Typography, Stack } from '@mui/material';
import { colors } from '@theme';

interface CollapsibleCommentProps {
  message: string;
  source: string;
}

const CollapsibleComment: FC<CollapsibleCommentProps> = ({ message, source }) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [hasCollapse, setHasCollapse] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (textRef.current) {
      const styles = window.getComputedStyle(textRef.current);
      const lineHeight = parseInt(styles.lineHeight);
      const paddingTop = parseInt(styles.paddingTop);
      const paddingBottom = parseInt(styles.paddingBottom);
      // console.log('lineHeight', lineHeight);
      // console.log('paddingTop', paddingTop);

      // Calculate exact height for 3 lines including padding
      const maxHeight = lineHeight * 3 + paddingTop + paddingBottom - 2;

      // Reset styles for measurement
      textRef.current.style.maxHeight = 'none';
      textRef.current.style.overflow = 'visible';

      // Get actual content height without any padding
      const contentHeight = textRef.current.scrollHeight - paddingTop - paddingBottom;

      setHasCollapse(contentHeight > maxHeight);

      if (isCollapsed) {
        textRef.current.style.maxHeight = `${maxHeight}px`;
        textRef.current.style.overflow = 'hidden';
        textRef.current.style.position = 'relative';
      } else {
        textRef.current.style.maxHeight = 'none';
        textRef.current.style.overflow = 'visible';
        textRef.current.style.position = 'static';
      }
    }
  }, [message, isCollapsed]);

  const toggleExpand = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Stack spacing={1} sx={{ pb: 1 }}>
      <Typography
        ref={textRef}
        dangerouslySetInnerHTML={{ __html: message }}
        variant="body2"
        sx={{
          pt: 1,
          wordWrap: 'break-word',
          maxHeight: !isCollapsed && hasCollapse ? '4.5em' : 'none',
          overflow: 'hidden',
          position: 'relative',
        }}
        data-cy={`${source}__comment__message`}
      />
      {hasCollapse && (
        <Typography
          variant="body3SemiBold"
          component="span"
          onClick={toggleExpand}
          sx={{
            color: colors.text.link,
            '&:hover': {
              cursor: 'pointer',
            },
            alignSelf: 'flex-start',
          }}
          data-cy={`${source}__read_${isCollapsed ? 'more' : 'less'}__button`}
        >
          {isCollapsed ? 'Read more' : 'Read less'}
        </Typography>
      )}
    </Stack>
  );
};

export default CollapsibleComment;
