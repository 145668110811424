import React from 'react';
import { Autocomplete, Stack, TextField, Typography } from '@mui/material';

import { HookState } from '@interfaces';
import { LoadingSkeleton, ServiceMessage, StyledBox } from '@components';
import * as Controller from './controller';
import isEmpty from 'lodash/isEmpty';
import { useLaunchDarklyFlags } from '@context';

const CompanyOtherSettings = () => {
  const controller = Controller.useCompanyOtherSettings();
  const {
    state,
    changeRequestConfiguration,
    options,
    companyUsers,
    companyUpdate,
    hasPoliciesEditPermission,
    defaultCoordinator,
    defaultCoordinatorUpdate,
    hasCoordinatorEditPermission,
  } = controller;
  const flags = useLaunchDarklyFlags();

  switch (state) {
    case HookState.FETCHING: {
      return (
        <StyledBox sx={{ flex: 1 }}>
          <LoadingSkeleton type="overviewBlock" />
        </StyledBox>
      );
    }

    case HookState.ERROR: {
      return <ServiceMessage text="Company Other Settings" />;
    }

    case HookState.SUCCESS: {
      return (
        <Stack sx={{ flexDirection: 'row' }}>
          {flags?.['ENG_9584_default_company_coordinator'] && (
            <StyledBox sx={{ flex: 1, mr: 3, p: 3 }}>
              <Typography variant="h3">Default coordinator</Typography>
              <Autocomplete
                size="small"
                options={companyUsers}
                getOptionLabel={(option) => option.label}
                onChange={(_, value) => defaultCoordinatorUpdate(value.value)}
                value={
                  isEmpty(defaultCoordinator)
                    ? null
                    : { label: defaultCoordinator?.full_name, value: defaultCoordinator?.id }
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="default-company-coordinator"
                    label="Default company coordinator"
                    variant="outlined"
                    inputProps={{
                      ...params?.inputProps,
                      'data-cy': 'company__other_settings__default_company_coordinator__input',
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!hasCoordinatorEditPermission}
                sx={{ minWidth: 200, maxWidth: 480, width: '100%', mt: 3 }}
              />
            </StyledBox>
          )}
          {flags?.['ENG_9252_change_request_configuration_options'] && (
            <StyledBox sx={{ flex: 1, p: 3 }}>
              <Typography variant="h3">Change request</Typography>
              <Autocomplete
                size="small"
                options={options}
                getOptionLabel={(option) => option.label}
                onChange={(_, value) => companyUpdate(value.value)}
                value={changeRequestConfiguration}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="change-request-configuration"
                    label="Change request configuration"
                    variant="outlined"
                    inputProps={{
                      ...params?.inputProps,
                      'data-cy': 'company__other_settings__change_request_configuration__input',
                    }}
                  />
                )}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                disabled={!hasPoliciesEditPermission}
                sx={{ minWidth: 200, maxWidth: 480, width: '100%', mt: 3 }}
              />
            </StyledBox>
          )}
        </Stack>
      );
    }

    default:
      return null;
  }
};

export default CompanyOtherSettings;
