import React, { FC } from 'react';
import { matchPath, useLocation, useParams } from 'react-router-dom';
import { Stack } from '@mui/material';

import { DocumentUploaderWithForm } from '@components';
import * as Controller from './controller';
import { IDocumentProps } from './interface';

const DocumentUploaderWrapper: FC<IDocumentProps> = ({
  children,
  prId,
  requestId,
  milestoneId,
  milestoneSubmitId,
  inspectionId,
  serviceOrderId,
  source,
}) => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const drawRequestId = match?.params?.drawRequestId || requestId;

  const controller = Controller.useDocumentUploader({
    projectId: projectId || prId,
    drawRequestId,
    milestoneId,
    milestoneSubmitId,
    inspectionId,
    serviceOrderId,
  });

  const {
    handleOpenDocumentUploader,
    isFilesUploaderOpened,
    transloaditSignature,
    filesUploaderClose,
    restrictions,
    documentTypes,
    milestones,
  } = controller;

  return (
    <Stack sx={{ flex: 1, marginBottom: 9 }}>
      {children({ handleOpenDocumentUploader })}
      {transloaditSignature?.signature && (
        <DocumentUploaderWithForm
          isOpen={isFilesUploaderOpened}
          documentTypes={documentTypes}
          closeUploader={filesUploaderClose}
          transloaditSignature={transloaditSignature}
          restrictions={restrictions}
          milestones={inspectionId ? null : milestones}
          source={source}
          drawRequestId={drawRequestId}
          milestoneId={milestoneId}
          serviceOrderId={serviceOrderId}
        />
      )}
    </Stack>
  );
};

export default DocumentUploaderWrapper;
