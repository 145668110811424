import React, { FC } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { CloseIcon } from '@svgAsComponents';
import { CustomAutocomplete } from '@components';
import { TableItem } from './TableItem';
import LoadingButton from '@mui/lab/LoadingButton';
import { useChooseNamingModal } from './ChooseNamingModalController';
import { mainLevelOptions, nestedLevelOptions } from '@constants';

export const ChooseNamingModal: FC<{
  onClose: () => void;
  open: boolean;
}> = ({ open, onClose }) => {
  const { onNextClick, mainLevelOption, nestedLevelOption, isLoading } = useChooseNamingModal();

  return (
    <Dialog
      open={open}
      maxWidth="lg"
      sx={{
        '& .MuiPaper-root': {
          borderRadius: { md: '4px', xs: 0 },
          minWidth: { md: 500, xs: '100%' },
          minHeight: { md: 'auto', xs: '100%' },
        },
      }}
    >
      <Stack sx={{ p: 4, flex: 1 }}>
        <Stack justifyContent="space-between" direction="row" alignItems="center" mb={3}>
          <Typography variant="h2">Budget naming & Structure</Typography>
          <IconButton
            onClick={onClose}
            data-cy="project__budget__create__choose_naming__modal__close__icon"
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <DialogContent sx={{ justifyContent: 'center', padding: 0 }}>
          <Typography variant="body1">
            To ensure our system mirror's the budget structure, we'd like to understand how it is
            named and organised.
            <br />
            You can have 2 options: simple list of property and nested list of property.
            <br />
            <br />
            First, please choose names of properties you are operating within your projects.
          </Typography>
          <Stack direction="row" spacing={3} pt={3} pb={3}>
            <CustomAutocomplete
              field={mainLevelOption}
              options={mainLevelOptions}
              label="Main level"
              freeSolo
              required
              handleTextFieldChange={(value: string) =>
                mainLevelOption.setValue({ name: value, name_display: value })
              }
              inputProps={{
                'data-cy': 'project__budget__create__choose_naming__modal__main_level__input',
              }}
            />
            <CustomAutocomplete
              field={nestedLevelOption}
              options={nestedLevelOptions}
              label="Nested level (Optional)"
              freeSolo
              handleTextFieldChange={(value: string) =>
                nestedLevelOption.setValue({ name: value, name_display: value })
              }
              inputProps={{
                'data-cy': 'project__budget__create__choose_naming__modal__nested_level__input',
              }}
            />
          </Stack>
          <Typography variant="h4" pb={2}>
            Structure variance
          </Typography>
          <Stack direction="row" spacing={3}>
            {!nestedLevelOption.value && (
              <Stack width="100%">
                <Typography variant="body1SemiBold" pb={1}>
                  Simple list of property
                </Typography>
                <Stack>
                  <TableItem name={mainLevelOption.value?.name_display} />
                  <TableItem name={mainLevelOption.value?.name_display} />
                  <TableItem name={mainLevelOption.value?.name_display} />
                  <TableItem name={mainLevelOption.value?.name_display} />
                </Stack>
              </Stack>
            )}
            {(!mainLevelOption.value || nestedLevelOption.value) && (
              <Stack width="100%">
                <Typography variant="body1SemiBold" pb={1}>
                  Nested list of property
                </Typography>
                <Stack>
                  <TableItem isExpanded name={mainLevelOption.value?.name_display} />
                  <TableItem isMainLevel={false} name={nestedLevelOption.value?.name_display} />
                  <TableItem isMainLevel={false} name={nestedLevelOption.value?.name_display} />
                  <TableItem isMainLevel={false} name={nestedLevelOption.value?.name_display} />
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ p: 0, flex: 1, alignItems: 'flex-end', mt: 5 }}>
          <Button
            variant="new"
            color="secondary"
            onClick={onClose}
            data-cy="project__budget__create__choose_naming__modal__close__button"
          >
            Close
          </Button>
          <LoadingButton
            onClick={onNextClick}
            disabled={!mainLevelOption.value || !mainLevelOption.isValid}
            loading={isLoading}
            data-cy="project__budget__create__choose_naming__modal__next__button"
          >
            Next
          </LoadingButton>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};
