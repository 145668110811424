import React, {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Stack } from '@mui/material';
import { IPropertyDetailsFields } from '@interfaces';
import {
  AdditionalPropertyDetailsModal,
  CreateByModelsTableNew,
  CreateByUnitsTableNew,
  PropertyDetailsTable,
} from '@components';
import { GridRowsProp } from '@mui/x-data-grid';

interface UnitsContextInterface {
  openAdditionalPropertyDetailsModal?: (id: string) => void;
  deleteProperty?: (id: string) => void;
  source?: string;
  updateQuantity?: (id: string, quantity: number) => void;
}

export const UnitsContext = createContext<UnitsContextInterface>({});

const PropertyDetails: FC<{
  propertyDetails: IPropertyDetailsFields;
  isProductionBuildProject: boolean;
  propertyRows: GridRowsProp;
  setPropertyRows: Dispatch<SetStateAction<GridRowsProp>>;
  unitsNumber: number;
  isCreateByModels: boolean;
}> = ({
  isProductionBuildProject,
  propertyDetails,
  propertyRows,
  setPropertyRows,
  unitsNumber,
  isCreateByModels,
}) => {
  const [selectedProperty, setSelectedProperty] = useState(null);

  const drawerContext = useMemo<UnitsContextInterface>(
    () => ({
      deleteProperty: (id) => {
        setPropertyRows((prev) => {
          const filteredRows = prev.filter((row) => row.id !== id && row.parentId !== id);
          return filteredRows.map((row, index) => ({ ...row, rowNumber: index + 1 }));
        });
      },
      source: 'project__create__property__table',
      openAdditionalPropertyDetailsModal: (id) => {
        setPropertyRows((currentRows) => {
          const property = currentRows.find((row) => row.id === id);
          setSelectedProperty(property);
          return currentRows;
        });
      },
      updateQuantity: (id, quantity) => {
        setPropertyRows((prev) => {
          const updatedRows = [];
          let idCounter = 1;

          prev.forEach((row) => {
            if (!row.parentId) {
              // This is a model row
              const modelId = idCounter++;
              const modelName = row.property_name;
              const currentQuantity = row.id === id ? quantity : row.quantity;

              // Add the model row
              updatedRows.push({
                ...row,
                id: modelId,
                quantity: currentQuantity,
              });

              // Add unit rows for this model
              for (let i = 0; i < currentQuantity; i++) {
                updatedRows.push({
                  id: idCounter++,
                  property_name: `Unit ${i + 1}`,
                  parentId: modelId,
                  isEditableV2: true,
                  parent_level_name: modelName,
                  propertyDetails: [],
                });
              }
            }
          });

          return updatedRows;
        });
      },
    }),
    [propertyRows],
  );

  const onClose = useCallback(() => {
    setSelectedProperty(null);
  }, []);

  const onSave = useCallback(
    (propertyDetailsList, id) => {
      setPropertyRows((prev) =>
        prev.map((row) => (row.id === id ? { ...row, propertyDetails: propertyDetailsList } : row)),
      );
      onClose();
    },
    [onClose],
  );

  const onRowUpdate = useCallback((params) => {
    setPropertyRows((prev) =>
      prev.map((row) => (row.id === +params.id ? { ...row, ...params.json } : row)),
    );
  }, []);

  return (
    <Stack alignItems="center" spacing={2}>
      {isProductionBuildProject || unitsNumber > 1 ? (
        <UnitsContext.Provider value={drawerContext}>
          {isCreateByModels ? (
            <CreateByModelsTableNew propertyRows={propertyRows} onRowUpdate={onRowUpdate} />
          ) : (
            <CreateByUnitsTableNew propertyRows={propertyRows} onRowUpdate={onRowUpdate} />
          )}
        </UnitsContext.Provider>
      ) : (
        <PropertyDetailsTable
          isEditable
          propertyDetails={propertyDetails}
          isPHBProject={false}
          source="project__create"
        />
      )}
      {selectedProperty && (
        <AdditionalPropertyDetailsModal
          propertyDetailsList={selectedProperty.propertyDetails}
          onClose={onClose}
          onSave={onSave}
          unitId={selectedProperty.id}
        />
      )}
    </Stack>
  );
};

export default PropertyDetails;
