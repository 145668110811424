import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { CustomDatePickerInput, CustomRadioGroup, CustomSelect, WysiwygEditor } from '@components';
import {
  IButtonController,
  IChecklistController,
  IFieldsController,
  IValidationController,
} from '../../main/interfaces';
import SectionWrapper from '../sectionWrapper';
import {
  debounceFunction,
  getDefaultChecklistItem,
  hasConfirmedCompletionDate,
  isValidDate,
} from '@utils';
import { ConfirmOptionEnums, PolicyItemTypesEnum } from '@interfaces';
import { SubmissionSectionKeyMap } from '@constants';
import { useDateFormatter } from '@hooks';
import { format, isBefore } from 'date-fns';
import isEqual from 'lodash/isEqual';

interface CompletionDateProps {
  checklistController: IChecklistController;
  fieldsController: IFieldsController;
  validationController: IValidationController;
  buttonsController: IButtonController;
}

const CompletionDateV2: FC<CompletionDateProps> = ({
  fieldsController,
  validationController,
  checklistController,
  buttonsController,
}) => {
  const { dateFormatter } = useDateFormatter();
  const {
    completionDate,
    completionDateComment,
    completionDateReasons,
    completionDateReasonsList,
    isCompletionDateConfirmed,
    setCompletionDateConfirmed,
    currentCompletionDate,
    handleCompletionDateReasonChange: handleSelectChange,
    isCompletionDateReasonsValid,
    startDate,
    isCompletionDateChanged,
    isInitMetadataUsed,
    resetCompletionDate,
  } = fieldsController;
  const { checklistItems, handleDone } = checklistController;
  const { completionDateDone } = buttonsController;
  const { onSectionButtonsClick, validatedSection, isAdditionalRequirementResubmit } =
    validationController;
  const checklistItem = getDefaultChecklistItem(
    checklistItems,
    PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL,
  );
  const sectionKey = SubmissionSectionKeyMap[PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL];
  const isSectionSkipped = validatedSection?.[sectionKey]?.isSkipped;
  const isSectionDone = validatedSection?.[sectionKey]?.isDone;

  const markCompletionDateSectionAsDone = useCallback(() => {
    if (
      !isSectionDone &&
      !isSectionSkipped &&
      ((completionDate.value &&
        completionDate.validate() &&
        isCompletionDateChanged &&
        (!currentCompletionDate || isCompletionDateReasonsValid)) ||
        (currentCompletionDate && hasConfirmedCompletionDate(isCompletionDateConfirmed)))
    ) {
      onSectionButtonsClick({ sectionKey, key: 'isDone' });
      completionDateDone(true);
    }
  }, [
    isCompletionDateReasonsValid,
    completionDate.value,
    isCompletionDateChanged,
    isSectionSkipped,
    isSectionDone,
    isCompletionDateConfirmed,
    currentCompletionDate,
  ]);

  useEffect(() => {
    markCompletionDateSectionAsDone();
  }, [markCompletionDateSectionAsDone]);

  const handleInputChange = useCallback(
    debounceFunction(async (payload) => {
      if (!checklistItem) return;

      handleDone({
        checklistItemId: checklistItem.id,
        shouldComplete: isSectionDone,
        metadata: {
          ...payload,
        },
      });
    }, 500),
    [checklistItem?.id, isSectionDone],
  );

  // automatically save data if it's changed
  useEffect(() => {
    const payload = {
      comment: completionDateComment.value,
      date_confirmed: isCompletionDateConfirmed,
      reasons: completionDateReasons,
      date: isValidDate(completionDate.value).value
        ? format(completionDate.value, 'yyyy-MM-dd')
        : null,
    };

    if (isInitMetadataUsed && !isEqual(payload, checklistItem?.metadata))
      handleInputChange(payload);
  }, [
    completionDateComment.value,
    completionDateReasons,
    completionDate.value,
    checklistItem?.metadata,
    isInitMetadataUsed,
    isCompletionDateConfirmed,
  ]);

  const warningText = useMemo(() => {
    if (!currentCompletionDate) return '';
    const dateToCheck = new Date(currentCompletionDate);
    const currentDate = new Date();

    if (isBefore(dateToCheck, currentDate)) {
      return `The estimated completion date for this project was ${dateFormatter({ date: currentCompletionDate })}. Is this project complete?`;
    }

    return `Is the estimated completion date for this project ${dateFormatter({ date: currentCompletionDate })}?`;
  }, [currentCompletionDate]);

  const confirmOptions = [
    { label: 'Yes', value: ConfirmOptionEnums.YES },
    { label: 'No', value: ConfirmOptionEnums.NO },
  ];

  return (
    <SectionWrapper
      title={checklistItem?.label || 'Update project completion date'}
      controller={validationController}
      sectionKey={sectionKey}
      collapsedSectionText={{
        value:
          completionDate.value && completionDate.validate()
            ? dateFormatter({ date: completionDate.value })
            : '',
      }}
      hideSkipButton
      handleDone={completionDateDone}
      innerRef={checklistItem?.itemRef}
      itemType={PolicyItemTypesEnum.COMPLETION_DATE_RENEWAL}
    >
      <Box mx={8} mt={2}>
        <Stack sx={{ width: { xs: '100%', md: '50%' } }} spacing={4}>
          {Boolean(currentCompletionDate) && (
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
              <Stack>
                <Typography sx={{ mb: 3 }} variant="body2">
                  {warningText}
                </Typography>
              </Stack>
              <CustomRadioGroup
                dataTestName="request__submission__completion_date__radio_group"
                value={isCompletionDateConfirmed}
                onChange={(event) => {
                  if (isCompletionDateConfirmed === ConfirmOptionEnums.NO) {
                    resetCompletionDate();
                  }
                  setCompletionDateConfirmed(event.target.value);
                  if (hasConfirmedCompletionDate(event.target.value)) {
                    completionDateDone(true);
                  }
                }}
                options={confirmOptions}
              />
            </Stack>
          )}
          {isCompletionDateConfirmed === ConfirmOptionEnums.NO && (
            <>
              <Stack>
                <Stack direction="row" spacing={1} ml={1}>
                  <Typography variant="label">Updated estimated completion date</Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <CustomDatePickerInput
                    label=""
                    field={completionDate}
                    minDate={startDate}
                    inputStyles={{ width: '300px' }}
                    disabled={isAdditionalRequirementResubmit}
                  />
                  {Boolean(currentCompletionDate) && (
                    <CustomSelect
                      label="Reason for update"
                      optionsList={completionDateReasonsList}
                      selectedOptions={completionDateReasons}
                      handleSelectChange={(e) => handleSelectChange(e.target.value as string[])}
                      required
                      error={!isCompletionDateReasonsValid}
                    />
                  )}
                </Stack>
              </Stack>
              <WysiwygEditor
                editField={completionDateComment}
                source={'request__submission__completion_date__comments'}
              />
            </>
          )}
        </Stack>
      </Box>
    </SectionWrapper>
  );
};

export default CompletionDateV2;
