import React from 'react';
import { Stack, Typography } from '@mui/material';

import {
  CenteredStyledBox,
  CustomAutocomplete,
  CustomTextField,
  IsProductionBuildCheckbox,
} from '@components';
import * as Controller from './controller';
import { states } from '@constants';

const mainLevelOptions = [
  { name: 'Model', name_display: 'Model' },
  { name: 'Phases', name_display: 'Phases' },
  { name: 'Lot', name_display: 'Lot' },
  { name: 'Building address', name_display: 'Building address' },
  { name: 'Unit', name_display: 'Unit' },
];

const nestedLevelOptions = [
  { name: 'Building address', name_display: 'Building address' },
  { name: 'Unit', name_display: 'Unit' },
  { name: 'Lot', name_display: 'Lot' },
];

const General = ({ generalFields, isProductionBuildProject, setIsProductionBuildProject }) => {
  const {
    address_1,
    city,
    state,
    zip_code,
    project_type,
    property_existing_type,
    property_proposed_type,
    scope_of_work,
    exit_strategy,
    number_of_units,
    main_level_name,
    nested_level_name,
  } = generalFields;
  const { projectTypes, propertyTypes, exitStrategies } = Controller.useGeneralCreateProject();

  return (
    <Stack alignItems="center" spacing={2}>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Typography variant="h3">Address</Typography>
          <CustomTextField
            field={address_1}
            label="Street address"
            required
            inputProps={{
              'data-cy': 'project__create__street_address__input',
            }}
          />
          <CustomTextField
            field={city}
            label="City"
            required
            inputProps={{
              'data-cy': 'project__create__city__input',
            }}
          />
          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              field={state}
              label="State"
              options={states}
              required
              inputProps={{
                'data-cy': 'project__create__state__input',
              }}
            />

            <CustomTextField
              field={zip_code}
              label="ZIP code"
              validationText="Please enter a valid 5-digit zip code"
              required
              inputProps={{
                'data-cy': 'project__create__zip_code__input',
              }}
            />
          </Stack>
        </Stack>
      </CenteredStyledBox>
      <CenteredStyledBox>
        <Stack spacing={2}>
          <Typography variant="h3">Properties</Typography>
          <CustomAutocomplete
            field={project_type}
            label="Project type"
            options={projectTypes}
            required
            inputProps={{
              'data-cy': 'project__create__project_type__input',
            }}
          />
          <Stack direction="row" spacing={2}>
            <CustomAutocomplete
              field={property_existing_type}
              label="Existing property type"
              options={propertyTypes}
              inputProps={{
                'data-cy': 'project__create__existing_property_type__input',
              }}
            />
            <CustomAutocomplete
              field={property_proposed_type}
              label="Property proposed type"
              options={propertyTypes}
              required
              inputProps={{
                'data-cy': 'project__create__proposed_property_type__input',
              }}
            />
          </Stack>
          <Stack>
            <Stack spacing={2}>
              <CustomAutocomplete
                field={exit_strategy}
                label="Exit strategy"
                options={exitStrategies}
              />

              <CustomTextField field={scope_of_work} label="Scope of work" rows={3} multiline />
            </Stack>
            <Typography variant="label">
              Description of the renovation / other work to be performed.
            </Typography>
            <Stack direction="row" sx={{ mt: 2 }} spacing={2} alignItems="flex-start">
              {isProductionBuildProject && (
                <CustomAutocomplete
                  field={main_level_name}
                  options={mainLevelOptions}
                  label="Main level"
                  freeSolo
                  required
                  handleTextFieldChange={(value: string) =>
                    main_level_name.setValue({ name: value, name_display: value })
                  }
                  inputProps={{
                    'data-cy': 'project__create__main_level_name__input',
                  }}
                />
              )}
              <CustomTextField
                field={number_of_units}
                label={`# of ${main_level_name.value?.name_display || 'units'}`}
                inputProps={{
                  'data-cy': 'project__create__units_number__input',
                }}
                sx={{ maxWidth: '50%' }}
              />
              {isProductionBuildProject && (
                <CustomAutocomplete
                  field={nested_level_name}
                  options={nestedLevelOptions}
                  label="Nested level (Optional)"
                  freeSolo
                  handleTextFieldChange={(value: string) =>
                    nested_level_name.setValue({ name: value, name_display: value })
                  }
                  inputProps={{
                    'data-cy': 'project__create__nested_level_name__input',
                  }}
                />
              )}
              <IsProductionBuildCheckbox
                checked={isProductionBuildProject}
                wrapperPadding={0}
                onChange={() => {
                  setIsProductionBuildProject(!isProductionBuildProject);
                }}
                label="Advanced structure"
              />
            </Stack>
          </Stack>
        </Stack>
      </CenteredStyledBox>
    </Stack>
  );
};

export default General;
