import { CellRenderWithStaticError, MilestoneListColumnTypeV2 } from './common';
import React from 'react';

const propertyName: MilestoneListColumnTypeV2 = {
  field: 'property_name',
  headerName: 'Property name',
  description: '',
  headerClassName: 'text',
  headerAlign: 'left',
  align: 'left',
  cellClassName: 'text',
  resizable: false,
  editable: true,
  sortable: true,
  filterable: true,
  groupable: false,
  aggregable: true,
  hideable: false,
  minWidth: 200,
  renderCell: (params) => (
    <CellRenderWithStaticError
      params={params}
      errorText="Please, fill the name or delete the row."
    />
  ),
  editableByMilestone: (row) => row.isEditableV2,
};

export default propertyName;
