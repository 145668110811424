import { useState, useEffect } from 'react';
import { GridRowsProp } from '@mui/x-data-grid';
import { IProjectProperty } from '@interfaces';

interface UseProjectRowsProps {
  unitsNumber: number;
  isCreateByModels: boolean;
  parentLevelName: string;
  nestedLevelName: string;
  editable: boolean;
  projectBuildingData?: { results: IProjectProperty[] };
}

export const useProjectRows = ({
  unitsNumber,
  isCreateByModels,
  parentLevelName,
  nestedLevelName,
  editable,
  projectBuildingData,
}: UseProjectRowsProps) => {
  const [unitsRows, setUnitsRows] = useState<GridRowsProp>([]);
  const [modelsRows, setModelsRows] = useState<GridRowsProp>([]);

  useEffect(() => {
    const buildings = projectBuildingData?.results;
    if (unitsNumber < 1 || isCreateByModels || !buildings) {
      return;
    }

    let initialRows = [];
    if (buildings.length) {
      if (+unitsNumber <= buildings.length) {
        // Crop existing buildings if unitsNumber is less
        initialRows = buildings.slice(0, +unitsNumber).map((building, index) => ({
          id: building.id,
          property_name: building.name,
          isEditableV2: editable,
          propertyDetails: building.details,
          rowNumber: index + 1,
        }));
      } else {
        // Keep all existing buildings and add new ones if unitsNumber is more
        initialRows = [
          ...buildings.map((building, index) => ({
            id: building.id,
            property_name: building.name,
            isEditableV2: editable,
            propertyDetails: building.details,
            rowNumber: index + 1,
          })),
          ...Array.from({ length: +unitsNumber - buildings.length }, (_, index) => ({
            id: buildings.length + index + 1,
            property_name: `${parentLevelName || 'Unit'} ${buildings.length + index + 1}`,
            isEditableV2: true,
            propertyDetails: [],
            rowNumber: buildings.length + index + 1,
          })),
        ];
      }
    }
    setUnitsRows(initialRows);
  }, [unitsNumber, isCreateByModels, parentLevelName, projectBuildingData]);

  useEffect(() => {
    if (isCreateByModels) {
      let idCounter = 1;
      const unitsPerModel = 1;
      const generatedRows = [];

      for (let modelIndex = 0; modelIndex < +unitsNumber; modelIndex++) {
        // Add a model row
        const modelId = idCounter++;
        const modelName = `${parentLevelName} ${modelIndex + 1}`;
        generatedRows.push({
          id: modelId,
          property_name: modelName,
          quantity: unitsPerModel,
          isEditableV2: true,
          parent_level_name: modelName,
          propertyDetails: [],
        });

        // Add associated unit rows for the current model
        for (let unitIndex = 0; unitIndex < unitsPerModel; unitIndex++) {
          const unitId = idCounter++;
          generatedRows.push({
            id: unitId,
            property_name: `${nestedLevelName} ${modelIndex * unitsPerModel + unitIndex + 1}`,
            parentId: modelId,
            isEditableV2: true,
            parent_level_name: modelName,
          });
        }
      }

      setModelsRows(generatedRows);
    }
  }, [unitsNumber, isCreateByModels, parentLevelName, nestedLevelName]);

  return {
    unitsRows,
    setUnitsRows,
    modelsRows,
    setModelsRows,
  };
};
