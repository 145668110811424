import React, { FC, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import { Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { colors } from '@theme';
import { documentsTypeMap } from '@constants';
import { ComponentProps } from './interface';
import { BetaChip, DocumentSummary } from '@components';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

const PDFViewerNew: FC<ComponentProps> = ({ pdfFile, close, withoutPortal }) => {
  useEffect(() => {
    // Push custom event to dataLayer for GTM when user scrolls in PDF viewer
    // This is to track the scroll position of the PDF viewer and analyze it in HEAP
    if (!pdfFile) return;

    let findElementAttempts = 0;
    let debounceTimer: NodeJS.Timeout;
    let lastScrollPosition = 0;

    const handleScroll = (scrollableElement: Element) => {
      const currentScrollPosition = scrollableElement.scrollTop;

      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }

      debounceTimer = setTimeout(() => {
        if (Math.abs(currentScrollPosition - lastScrollPosition) > 100) {
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: 'pdf_page_change',
            scrollPosition: currentScrollPosition,
          });

          lastScrollPosition = currentScrollPosition;
        }
      }, 500);
    };

    const findAndAttachScrollListener = () => {
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');

      if (scrollableElement) {
        clearInterval(findElementInterval);
        scrollableElement.addEventListener('scroll', () => handleScroll(scrollableElement));
        return scrollableElement;
      }

      if (findElementAttempts >= 20) {
        // Stop trying after 10 seconds (20 * 500ms)
        clearInterval(findElementInterval);
        console.warn('Could not find PDF scrollable element after 10 seconds');
      }

      findElementAttempts++;
    };

    const findElementInterval: ReturnType<typeof setInterval> = setInterval(
      findAndAttachScrollListener,
      500,
    );

    return () => {
      clearInterval(findElementInterval);
      const scrollableElement = document.querySelector('.rpv-core__inner-pages');
      if (scrollableElement) {
        scrollableElement.removeEventListener('scroll', () => handleScroll(scrollableElement));
      }
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
    };
  }, [pdfFile]);

  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  if (!pdfFile) return null;

  const getFileName = (file) =>
    file?.name || documentsTypeMap[file?.document_type] || file?.document_type || `PDF File`;

  const modalBody = () => (
    <Stack
      alignItems="center"
      position="fixed"
      sx={{
        overflow: 'hidden',
        top: 0,
        height: '100vh',
        width: '100%',
        zIndex: 9999,
        left: 0,
        backgroundColor: colors.background.gray,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{
          width: '100%',
          backgroundColor: colors.background.white,
          padding: '1.5rem',
          borderBottom: `1px solid ${colors.background.gray}`,
        }}
      >
        <Typography variant="h2">{getFileName(pdfFile)}</Typography>
        <CloseIcon sx={{ cursor: 'pointer', ml: 'auto' }} onClick={close} />
      </Stack>
      <Stack
        direction="row"
        sx={{
          width: '100%',
          backgroundColor: colors.background.white,
          height: 'calc(100vh)',
        }}
      >
        <Stack
          sx={{
            width: pdfFile?.has_smart_summary ? 'calc(100vw - 720px)' : '100%',
            padding: '1.5rem',
            height: '90%',
          }}
        >
          {pdfModal()}
        </Stack>
        {pdfFile?.has_smart_summary && (
          <Stack sx={{ width: '720px', padding: '1.5rem', height: '90%' }}>
            <Stack direction="row" alignContent="center" spacing={1}>
              <Typography variant="h3">SmartSummary</Typography>
              <BetaChip dataTestName="pdf__viewer__documents_summary__beta__chip" />
            </Stack>
            <DocumentSummary row={pdfFile} source="pdf__viewer" canBeProcessed />
          </Stack>
        )}
      </Stack>
    </Stack>
  );

  const pdfModal = () => (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
      <Viewer
        fileUrl={pdfFile.file_url}
        plugins={[defaultLayoutPluginInstance]}
        defaultScale={1.25}
      />
    </Worker>
  );

  return withoutPortal ? (
    <>{pdfModal()}</>
  ) : (
    <>{ReactDOM.createPortal(modalBody(), document.body)}</>
  );
};

export default PDFViewerNew;
