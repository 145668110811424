import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { LineItemEditIconWithIndicator } from '@svgAsComponents';
import {
  ColumnV2Width,
  CommonRowType,
  RequestTableTabTypesEnum,
  RequestTableTabsEnum,
} from '@interfaces';
import { creditAvailableForMilestone, wasCreditItteraction } from '@utils';

const LineItemDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source, updateRightDrawer } = useContext(TableContext);
  const creditAvailable = creditAvailableForMilestone(row);
  const showCreditInfo = Boolean(wasCreditItteraction(row));

  return (
    <IconButton
      onClick={
        updateRightDrawer
          ? updateRightDrawer({
              milestoneId: row?.id,
              milestoneName: row?.name,
              tab: RequestTableTabsEnum.BREAKDOWN,
              type: RequestTableTabTypesEnum.SUBMIT,
              creditAvailable,
            })
          : row?.updateRightDrawer?.()
      }
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <LineItemEditIconWithIndicator hasValues={showCreditInfo} />
    </IconButton>
  );
};

const Memoized = memo(LineItemDetails);

const submitLineItemDetails: MilestoneListColumnType = {
  name: 'requested_credit_amount',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default submitLineItemDetails;
