import React, { FC, memo, useContext } from 'react';
import { IconButton } from '@mui/material';

import { MilestoneListColumnType } from './common';
import { TableContext } from '../controller';
import { InfoIcon, LineItemEditIconWithIndicator } from '@svgAsComponents';
import {
  ColumnV2Width,
  CommonRowType,
  RequestTableTabTypesEnum,
  RequestTableTabsEnum,
} from '@interfaces';
import { creditAvailableForMilestone, wasCreditItteraction } from '@utils';

const ApproveLineItemDetails: FC<{ row: CommonRowType }> = ({ row }) => {
  const { updateRightDrawer, source } = useContext(TableContext);
  const creditAvailable = creditAvailableForMilestone(row);
  const showCreditInfo = Boolean(wasCreditItteraction(row));

  return row.activeToEdit ? (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.BREAKDOWN,
        type: RequestTableTabTypesEnum.APPROVE,
        creditAvailable,
      })}
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <LineItemEditIconWithIndicator hasValues={showCreditInfo} />
    </IconButton>
  ) : (
    <IconButton
      onClick={updateRightDrawer?.({
        milestoneId: row?.id,
        milestoneName: row?.name,
        tab: RequestTableTabsEnum.BREAKDOWN,
        type: RequestTableTabTypesEnum.INFO,
        creditAvailable: showCreditInfo,
      })}
      disabled={!showCreditInfo}
      data-cy={`${source}__body__line_item_details__button__icon__index_${row.project_milestone?.index}`}
    >
      <InfoIcon />
    </IconButton>
  );
};

const Memoized = memo(ApproveLineItemDetails);

const approveLineItemDetails: MilestoneListColumnType = {
  name: 'approved_credit_amount',
  columnText: '',
  Header: '',
  justifyContent: 'center',
  noBorder: true,
  noMargin: true,
  renderCell: ({ row }) => <Memoized row={row} />,
  minWidth: () => ColumnV2Width.ICON,
  maxWidth: () => ColumnV2Width.ICON,
};

export default approveLineItemDetails;
