import { filterObjectKeyByPermission, getArrayFromObject, isAllowed } from '@utils';
import { ProjectStatusMap } from '@constants';
import { PermissionNamesEnums, ProjectStatusEnum } from '@interfaces';
import { FilterObject } from '../interface';

export const projectStatusFilter: FilterObject = {
  title: 'Project status',
  filterKey: 'project_status',
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  defaultFilters: () => [ProjectStatusEnum.ACTIVE],
  getStaticValues: ({ permissions }) => {
    if (!permissions) return [];

    const filteredStatues = filterObjectKeyByPermission({
      object: ProjectStatusMap,
      key: ProjectStatusEnum.CREATED,
      isAllowed:
        isAllowed(PermissionNamesEnums.PROJECT_ONBOARDING, permissions) ||
        isAllowed(PermissionNamesEnums.PROJECT_BUDGET_EDIT, permissions),
    });

    return getArrayFromObject(filteredStatues, 'value', 'label') as unknown as Array<{
      label: string;
      value: string;
    }>;
  },
  cypressSelector: 'filters__project_status',
};
