import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useSafeSnackbar } from '@hooks';
import { ILineItemModal, LineItemModalTypeEnums } from '@interfaces';
import { ControllerInterface } from './interface';
import fieldsConfigs from './fields';
import mutationConfigs from './mutations';
import { useStringFieldModel } from '@models';
import { useMutation, useQueryClient } from 'react-query';

interface LineItemEditControllerProps {
  modalConfig: ILineItemModal;
  setLineItemModal: ({ open, type, lineItem }: ILineItemModal) => void;
  requestId?: string;
}

export const useLineItemEdit = ({
  modalConfig,
  setLineItemModal,
  requestId,
}: LineItemEditControllerProps): ControllerInterface => {
  const { fields, submitAction, deleteAction, lineItem } = modalConfig;
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSafeSnackbar();
  const { projectId } = useParams();
  const mutationConfig = mutationConfigs[submitAction](projectId, requestId);
  const deleteMutationConfig = deleteAction
    ? mutationConfigs[deleteAction](projectId, requestId)
    : null;

  const submitMutation = useMutation<
    Parameters<typeof mutationConfig.api>[0],
    Error,
    Awaited<ReturnType<typeof mutationConfig.api>>
  >(mutationConfig.api, {
    onSuccess: () => {
      Object.keys(mutationConfig.invalidation).forEach((key) => {
        queryClient.invalidateQueries([key, { ...mutationConfig.invalidation[key] }]);
      });
      onClose();
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const deleteMutation = deleteMutationConfig
    ? useMutation<
        Parameters<typeof deleteMutationConfig.api>[0],
        Error,
        Awaited<ReturnType<typeof deleteMutationConfig.api>>
      >(deleteMutationConfig.api, {
        onSuccess: () => {
          Object.keys(deleteMutationConfig.invalidation).forEach((key) => {
            queryClient.invalidateQueries([key, { ...deleteMutationConfig.invalidation[key] }]);
          });
          onClose();
        },
        onError: (error) => {
          enqueueSnackbar(error.message, { variant: 'error' });
        },
      })
    : null;

  const fieldsModels = fields.map((field) =>
    useStringFieldModel(fieldsConfigs[field](lineItem).modelProps),
  );

  const handleSubmitClick = useCallback(() => {
    const mutationVariables = { projectId };
    if (requestId) mutationVariables['drawRequestId'] = requestId;
    if (lineItem?.id) mutationVariables['milestoneId'] = lineItem?.id;

    fields.forEach((field, index) => {
      mutationVariables[field] = fieldsModels[index].value;
    });

    submitMutation.mutateAsync({ ...mutationVariables });
  }, [requestId, projectId, fieldsModels, fields]);

  const onClose = () => {
    setLineItemModal({ open: false, type: LineItemModalTypeEnums.ADD, lineItem: null });
  };

  const handleDeleteClick = useCallback(() => {
    if (!deleteMutationConfig) return;
    const deleteVariables = { projectId };
    if (requestId) deleteVariables['drawRequestId'] = requestId;
    if (lineItem?.id) deleteVariables['milestoneId'] = lineItem?.id;
    deleteMutation.mutateAsync({ ...deleteVariables });
  }, [lineItem, requestId, projectId, deleteMutation]);

  const isValidRequest = useMemo(
    () => fieldsModels.every((fieldModel) => fieldModel.isValid),
    [fieldsModels],
  );

  return {
    onClose,
    handleSubmitClick,
    handleDeleteClick,
    isSubmitting: false,
    isDeleting: false,
    fieldsModels,
    isValidRequest,
  };
};
