import React, { FC, useCallback, useContext } from 'react';
import { Stack } from '@mui/material';
import { GridColumnHeaderParams, GridColumnHeaderTitle } from '@mui/x-data-grid';
import { DataTableV3, MilestoneListOptionsMenu } from '@components';
import { AuthContext, PermissionsContext } from '@context';
import { useMilestoneList } from './controllerV2';
import { MilestoneListInterface } from './interface';
import { GridColDef, useGridApiContext } from '@mui/x-data-grid-premium';
import { ColumnIcon } from './ColumnIcon';
import { MilestoneListColumnTypeV2 } from './columnsV2/common';
import { checkIsAdmin, getTeamRole } from '@utils';
import { MilestroneListDrawer } from './drawer';
import { ExtendGridStatePremium } from '@interfaces';
import ReviewCredit from './ReviewCredit';
import { useNavigationNetBlocker } from '@hooks';
import { MilestoneListItem } from './columns/common';

const HeaderComponent = (params: GridColumnHeaderParams) => {
  const api = useGridApiContext();
  const state = api.current.state as ExtendGridStatePremium;

  return (
    <Stack alignItems="center" direction="row">
      <ColumnIcon columns={state.additionalData.columnErrors} name={params.field} source={''} />
      <GridColumnHeaderTitle label={params.colDef?.headerName || ''} columnWidth={0} />
    </Stack>
  );
};

const MilestoneList: FC<MilestoneListInterface> = ({
  tableKey,
  milestones,
  initColumns,
  totals,
  withColumnIndication,
  headerLeftPart,
  headerRightPart,
  patchMilestone,
  refetch,
  deleteMilestone,
  openEditMilestoneModal,
  contingencyMode,
  source,
  updateData,
  withCredit,
  groupByFilter,
  onCreditChangeCompleted,
  commentIconComponent,
  menuItems = [],
  exportToCSV,
  rowReordering,
  bulkUpdateApi,
  containerSpacing,
}) => {
  useNavigationNetBlocker();
  const {
    sortedMilestones,
    columns,
    milestoneColumns,
    apiUpdateV2,
    totalCalculation,
    drawRequestErrors,
    showCreditButton,
  } = useMilestoneList({
    tableKey,
    milestones,
    initColumns,
    patchMilestone,
    withColumnIndication,
    contingencyMode,
    source,
    updateData,
    withCredit,
    groupByFilter,
    totals,
  });
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { permissions } = useContext(PermissionsContext);

  const isColumnEditableWithPermission = useCallback(
    (column: MilestoneListColumnTypeV2) => {
      if ('editable' in column) {
        return { editable: column.editable };
      }

      if ('editableByPermissions' in column) {
        if (checkIsAdmin(teamRole)) return { editable: true };
        return { editable: column.editableByPermissions(permissions) };
      }

      return { editable: true };
    },
    [permissions, teamRole],
  );

  const serColumns = React.useMemo<GridColDef[]>(
    () =>
      (columns as unknown as MilestoneListColumnTypeV2[]).map((item) => ({
        ...item,
        ...isColumnEditableWithPermission(item),
        groupable: false,
        aggregable: false,
        renderHeader: (props) => <HeaderComponent {...props} />,
      })),
    [columns, isColumnEditableWithPermission, milestoneColumns, source],
  );

  const columnsToCSV = React.useMemo(
    () => columns.map(({ field, headerName }) => ({ name: field, columnText: headerName })),
    [columns],
  );

  return (
    <MilestroneListDrawer
      refetch={refetch}
      deleteMilestone={deleteMilestone}
      openEditMilestoneModal={openEditMilestoneModal}
      commentsPreviewQueryEnabled={!!sortedMilestones.length}
      tableKey={tableKey}
      source={source}
      onCreditChangeCompleted={onCreditChangeCompleted}
    >
      <DataTableV3
        containerSpacing={containerSpacing}
        withColumnIndex
        bulkUpdateApi={bulkUpdateApi}
        rowReordering={rowReordering}
        withCustomColumnPicker
        headerLeft={headerLeftPart}
        headerRight={[
          ...(showCreditButton
            ? [
                {
                  Component: <ReviewCredit />,
                },
              ]
            : []),
          ...headerRightPart,
          {
            Component: commentIconComponent,
          },
        ]}
        dotsMenu={
          <MilestoneListOptionsMenu
            menuItems={menuItems}
            totals={totals}
            columns={columnsToCSV as Partial<MilestoneListItem>[]}
            sortedMilestones={sortedMilestones}
            exportToCSV={exportToCSV}
          />
        }
        rowUpdateApi={apiUpdateV2}
        tableKey={tableKey}
        rows={sortedMilestones}
        columns={serColumns}
        totals={totalCalculation}
        errors={{
          rowErrors: drawRequestErrors,
          columnErrors: milestoneColumns,
        }}
      />
    </MilestroneListDrawer>
  );
};

export default MilestoneList;
