import React, { FC, memo, useContext } from 'react';
import { currencyFormatter, percentFormatter, useBlockerCell, useBlockerFooter } from '@utils';
import { Typography, Stack, Skeleton } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, ProgressionSum, HeaderText } from './common';
import { ColumnV2Width, CommonRowType, IMilestoneTotal, IProject } from '@interfaces';
import { TableContext } from '../controller';

const RetainageBalanceTodateApproved: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id });
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3"
        data-cy={`${source}__body__retainage_balance_to_date_approved__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(row.retainage_balance_to_date + row.retainage_release_approved, '-')}
      </Typography>
      <ProgressionSum
        sum={row.retainage_balance_to_date + row.retainage_release_approved}
        progress={-1 * row.retainage_release_approved}
        dataTestName={`${source}__body__retainage_balance_to_date_approved__next_value__index_${row?.project_milestone?.index}`}
      />
    </Stack>
  );
};

const Memoized = memo(RetainageBalanceTodateApproved);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const isBlocked = useBlockerFooter();
  const { source } = useContext(TableContext);

  if (isBlocked) return <Skeleton width={'100%'} />;
  return (
    <Stack alignItems="flex-end">
      <Typography
        variant="body3SemiBold"
        data-cy={`${source}__footer__retainage_balance_to_date_approved__value`}
      >
        {currencyFormatter(row.retainage_balance_to_date + row.retainage_release_approved)}
      </Typography>
      <ProgressionSum
        sum={row.retainage_balance_to_date + row.retainage_release_approved}
        progress={-1 * row.retainage_release_approved}
        dataTestName={`${source}__footer__retainage_balance_to_date_approved__next_value`}
      />
    </Stack>
  );
};

const retainageBalanceTodateApproved: MilestoneListColumnType = {
  name: 'retainage_balance_to_date',
  columnText: 'Retainage balance to date ($)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: ({ project }: { project?: IProject }) => (
    <HeaderText tooltipText="Amount retainage balance to date ($)">
      <ColumnLabel>Retainage balance</ColumnLabel>
      <ColumnLabel>
        to date{' '}
        {project?.retainage_rate && `(${percentFormatter({ value: project?.retainage_rate })})`}
      </ColumnLabel>
    </HeaderText>
  ),
};

export default retainageBalanceTodateApproved;
