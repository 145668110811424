import {
  IPermission,
  IUser,
  PermissionNamesEnums,
  TableKeyEnum,
  TTeamRole,
  TypePagination,
} from '@interfaces';
import * as FiltersItems from './filters';

export type FiltersTypes = keyof typeof FiltersItems;

export interface ComponentProps {
  source: string;
  padding?: number;
  filters: FiltersTypes[];
  LeftComponent?: React.FunctionComponent<any>;
  setFilterStringQuery: React.Dispatch<React.SetStateAction<string>>;
  width?: string;
  tableKey?: TableKeyEnum;
  onReady?: () => void;
  onPageChange?: (event, newPage) => void;
  skipToggle?: boolean;
}

// TODO: connect getDataParamsPaginated with data provider types
export type FilterObject = {
  noNeedToRender?: boolean;
  title: string;
  filterKey: string;
  userPermission?: (user: IUser) => boolean; // TODO: remove that when migrate to permissions
  permissionKey?: PermissionNamesEnums | PermissionNamesEnums[];
  defaultFilters?: (role?: TTeamRole) => Array<string>;
  needToUpdateUrl?: boolean;
  needToUpdateSetting?: boolean;
  getDataParamsPaginated?: (
    pagination?: TypePagination,
    q?: string,
    skip?: boolean,
  ) => {
    type: string;
    keys: string[];
    args: Record<string, boolean | number | string | TypePagination>;
    options: {
      paginate?: boolean;
      strictSerialize?: (data: Array<any>) => unknown;
    };
  };
  willChangeUrl?: boolean;
  getStaticValues?: ({
    permissions,
  }: {
    permissions: IPermission[];
  }) => Array<{ label: string; value: string }>;
  cypressSelector?: string;
  withPagination?: boolean;
};

export const DEFAULT_FILTERS_COUNT = 4;
export const DEFAULT_SET_TO_URL = false;
export const DEFAULT_SET_TO_SETTINGS = false;
