import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { colors } from '@theme';
import { TableKeyEnum } from '@interfaces';
import { DataTableV3 } from '@components';
import { useUnitsTable } from './controller';
import { GridRowsProp } from '@mui/x-data-grid';

const CreateByUnitsTable: FC<{
  propertyRows: GridRowsProp;
  onRowUpdate: (params) => void;
}> = ({ propertyRows, onRowUpdate }) => {
  const { columns } = useUnitsTable();

  return (
    <Stack
      sx={{
        backgroundColor: colors.background.white,
        pt: 1,
        borderRadius: '4px',
        flexGrow: 1,
        width: '100%',
      }}
    >
      <DataTableV3
        withoutAdaptiveScroll
        tableKey={TableKeyEnum.CREATE_BY_UNITS}
        withBuiltInSearch={false}
        rows={propertyRows}
        columns={columns}
        rowUpdateApi={onRowUpdate}
      />
    </Stack>
  );
};
export default CreateByUnitsTable;
