import React, { FC, memo } from 'react';
import { Typography } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { percentFormatter } from '@utils';
import { ColumnV2Width, CommonRowType } from '@interfaces';

const PreviousChangesPercent: FC<{ row: CommonRowType }> = ({ row }) => (
  <Typography variant="body3">{percentFormatter({ value: row.previous_changes_rate })}</Typography>
);

const Memoized = memo(PreviousChangesPercent);

const previousChangesRate: MilestoneListColumnType = {
  name: 'previous_changes_rate',
  columnText: 'Total approved changes (%)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => (
    <Typography variant="body3SemiBold">
      {percentFormatter({ value: row.previous_changes_rate })}
    </Typography>
  ),
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="% of cumulative changes to original scheduled value">
      <ColumnLabel>Total approved</ColumnLabel>
      <ColumnLabel> changes (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default previousChangesRate;
