import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import {
  ErrorDual,
  IProject,
  IPropertyDetail,
  MilestoneTag,
  PatchBuildingParam,
  QueryNamesEnums,
} from '@interfaces';
import {
  createProjectBuilding,
  createProjectBuildingModelsBulk,
  createProjectBuildingsBulk,
  patchProjectBuilding,
  patchProjectMilestoneTag,
  postProjectMilestoneTag,
  updateProjectFields,
} from '@globalService';
import { parsePathErrorDual } from '@utils';

export const useProjectMutations = (projectId: string) => {
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onError: (error) => {
      enqueueSnackbar(
        parsePathErrorDual(error) || 'Something went wrong while updating project info',
        { variant: 'error' },
      );
    },
  });

  const patchBuildingMutation = useMutation<Response, ErrorDual, PatchBuildingParam>(
    patchProjectBuilding,
    {
      onError: (error) => {
        enqueueSnackbar(parsePathErrorDual(error), { variant: 'error' });
      },
      onSuccess: () => {
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_BUILDING);
      },
    },
  );

  const addBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      details?: IPropertyDetail[];
    }
  >(createProjectBuilding, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addUnitBuildingMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json: {
        project: string;
        details?: IPropertyDetail[];
        name?: string;
        sqft?: number;
        description?: string;
      }[];
    }
  >(createProjectBuildingsBulk, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const addBuildingModelsMutation = useMutation<
    Response,
    Error,
    {
      projectId: string;
      json?: {
        project: string;
        details?: IPropertyDetail[];
        name?: string;
        sqft?: number;
        description?: string;
      }[];
    }
  >(createProjectBuildingModelsBulk, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_BUILDING_MODELS, { projectId }]);
    },
    onError: (error) => {
      enqueueSnackbar(error.message, { variant: 'error' });
    },
  });

  const patchMsTag = useMutation<
    Response,
    ErrorDual,
    { projectId: string; milestoneTagId: string; json: Partial<MilestoneTag> }
  >(patchProjectMilestoneTag, {
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  const postMsTag = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<MilestoneTag> }
  >(postProjectMilestoneTag, {
    onError: (error) => {
      enqueueSnackbar(error?.message, { variant: 'error' });
    },
  });

  return {
    projectMutation,
    patchBuildingMutation,
    addBuildingMutation,
    addUnitBuildingMutation,
    addBuildingModelsMutation,
    patchMsTag,
    postMsTag,
  };
};
