export const projectMilestonesApi = (projectId: string) => `projects/${projectId}/milestones/`;

export const projectDrawRequestMilestonesApi = (projectId: string, drawRequestId: string) =>
  `projects/${projectId}/draw_requests/${drawRequestId}/milestones/`;

export const projectDrawRequestMilestoneByIdApi = (
  projectId: string,
  drawRequestId: string,
  milestoneId: string,
) => `projects/${projectId}/draw_requests/${drawRequestId}/milestones/${milestoneId}/`;
