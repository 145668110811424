import React, { FC, memo, useContext } from 'react';
import { Typography } from '@mui/material';
import { MilestoneListColumnType, ColumnLabel, HeaderText } from './common';
import { percentFormatter } from '@utils';
import { ColumnV2Width, CommonRowType, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';

const LenderAllowancePercentPreviousDraws: FC<{ row: CommonRowType }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3"
      data-cy={`${source}__body__approved_to_date_rate__index_${row?.project_milestone?.index}`}
    >
      {percentFormatter({ value: row.previous_lender_allowance_rate })}
    </Typography>
  );
};

const Memoized = memo(LenderAllowancePercentPreviousDraws);

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);
  return (
    <Typography variant="body3SemiBold" data-cy={`${source}__footer__approved_to_date_rate__value`}>
      {percentFormatter({ value: row.previous_lender_allowance_rate })}
    </Typography>
  );
};

const previousLenderAllowanceRate: MilestoneListColumnType = {
  name: 'previous_lender_allowance_rate',
  columnText: 'Approved to date (%)',
  renderCell: ({ row }) => <Memoized row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText>
      <ColumnLabel>Approved</ColumnLabel>
      <ColumnLabel> to date (%)</ColumnLabel>
    </HeaderText>
  ),
};

export default previousLenderAllowanceRate;
