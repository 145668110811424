import React, { FC } from 'react';
import { Stack, Typography, Box } from '@mui/material';
import { useParams, useLocation, matchPath } from 'react-router-dom';

import { HookState, MessagePanelTabsEnum, TableKeyEnum } from '@interfaces';
import {
  Gallery,
  ServiceMessage,
  StyledBox,
  PDFViewerNew,
  TableItemRightDrawer,
  RightDrawer,
  ToolTipLine,
  FiltersV2,
  TablePaginationNew,
  DataTableV3,
} from '@components';
import { NoDataImage } from '@svgAsComponents';
import * as Controller from './controller';
import { ControllerInterface } from './interface';
import { useLaunchDarklyFlags } from '@context';
const ServicesTab = () => {
  const { projectId } = useParams();
  const { pathname } = useLocation();
  const match = matchPath('/projects/:projectId/:tab/draw-requests/:drawRequestId', pathname);
  const controller = Controller.useServices(projectId, match?.params?.drawRequestId);

  switch (controller.state) {
    case HookState.FETCHING: {
      return <Table controller={controller} />;
    }
    case HookState.ERROR: {
      return <ServiceMessage text="services" />;
    }

    case HookState.SUCCESS: {
      return <Table controller={controller} />;
    }

    default:
      return null;
  }
};

export default ServicesTab;

const Table: FC<{
  controller: ControllerInterface;
}> = ({ controller }) => {
  const {
    rows,
    columns,
    pdf,
    gallery,
    close,
    rightDrawerParams,
    rightMenu,
    inspectionCadence,
    setFilterStringQuery,
    paginationProps,
    noFiltersApplied,
    handleRowClick,
    setTableSortingModel,
  } = controller;

  const flags = useLaunchDarklyFlags();
  return (
    <>
      {Boolean(inspectionCadence) && (
        <Box mb={2}>
          <ToolTipLine
            text={`Progress inspections are scheduled every ${inspectionCadence} days for this project.`}
            typographyVariant="body3"
            fullWidth
          />
        </Box>
      )}

      <StyledBox sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, px: 0 }}>
        {noFiltersApplied && controller.state === HookState.SUCCESS && rows?.length === 0 ? (
          <Stack justifyContent="center" alignItems="center" sx={{ flexGrow: 1 }}>
            <NoDataImage size={200} />
            <Typography sx={{ mt: 4, textAlign: 'center' }} variant="body2SemiBold">
              We know you’re curious to see how the project is going. <br />
              You’ll see services information here after an some service is ordered.
            </Typography>
          </Stack>
        ) : (
          <DataTableV3
            withoutAdaptiveScroll
            controllerLoading={controller.state === HookState.FETCHING}
            tableKey={TableKeyEnum.PROJECT_SERVICES}
            onRowClick={handleRowClick}
            onSortModelChange={setTableSortingModel}
            withBuiltInSearch={false}
            headerRight={[
              {
                Component: (
                  <TablePaginationNew
                    page={paginationProps.page}
                    rowsPerPage={paginationProps.rowsPerPage}
                    rowsPerPageOptions={paginationProps.rowsPerPageOptions}
                    itemsCount={paginationProps.itemsCount}
                    onPageChange={paginationProps.onPageChange}
                    onRowsPerPageChange={paginationProps.onRowsPerPageChange}
                    source="project__services_tab__list"
                  />
                ),
              },
            ]}
            headerLeft={[
              {
                Component: (
                  <FiltersV2
                    skipToggle
                    source="project__services_tab__list"
                    setFilterStringQuery={setFilterStringQuery}
                    filters={[
                      'servicesTypeFilter',
                      'servicesListStatusFilter',
                      ...(flags?.['ENG_9721_filter_by_service_provider']
                        ? (['inspectionsListAgencyFilter', 'servicesListAgencyFilter'] as const)
                        : []),
                    ]}
                    padding={0}
                    width="unset"
                    tableKey={TableKeyEnum.PROJECT_SERVICES}
                    onPageChange={paginationProps.onPageChange}
                  />
                ),
              },
            ]}
            rows={rows}
            columns={columns}
          />
        )}
        {gallery && <Gallery startIndex={0} close={close} files={gallery} />}
        {pdf && <PDFViewerNew pdfFile={pdf[0]} close={close} />}
        <RightDrawer {...rightMenu}>
          <TableItemRightDrawer
            {...rightDrawerParams}
            requestDocuments={false}
            messagePanelTab={MessagePanelTabsEnum.SERVICES}
            showMessagePanelTabs={false}
            source="project__services_tab__list__right_drawer"
          />
        </RightDrawer>
      </StyledBox>
    </>
  );
};
