import React from 'react';
import { Link, matchPath, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Box, Stack, Tab, Tabs, Typography } from '@mui/material';

import PlatformPolicies from './PlatformPolicies';
import { colors } from '@theme';

const TABS = {
  POLICIES: { label: 'Policies', value: 'policies' },
};

const Company = () => {
  const { pathname } = useLocation();
  const match = matchPath('/platform-settings/:tab/*', pathname);
  const pathBase = '/platform-settings';

  return match?.params?.tab ? (
    <Box sx={{ display: 'flex', flexFlow: 'column nowrap', height: '100%' }}>
      <Typography variant="h1" sx={{ px: { md: 3, xs: 2 }, pt: { md: 3, xs: 2 } }}>
        Platform settings
      </Typography>
      <Box sx={{ mt: { md: 1.5, xs: 0 }, position: 'relative' }}>
        <Tabs
          TabIndicatorProps={{ style: { transition: 'none' }, children: <span /> }}
          textColor="secondary"
          indicatorColor="secondary"
          value={match?.params.tab || TABS.POLICIES.value}
        >
          <Tab
            label={TABS.POLICIES.label}
            value={TABS.POLICIES.value}
            component={Link}
            to={`${pathBase}/${TABS.POLICIES.value}/all`}
            sx={{ ml: { md: 3, xs: 2 } }}
            data-cy={`platform_settings_tab_${TABS.POLICIES.value}`}
          />
        </Tabs>
      </Box>
      <Stack sx={{ flex: 1, backgroundColor: colors.background.gray, p: { md: 3, xs: 2 } }}>
        <Routes>
          <Route path={`${TABS.POLICIES.value}/*`} element={<PlatformPolicies />} />
        </Routes>
      </Stack>
    </Box>
  ) : (
    <Navigate to={`${pathBase}/${TABS.POLICIES.value}/all`} replace />
  );
};

export default Company;
