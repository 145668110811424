// TODO: contact to single hook
import { EngineContext } from '@context';
import { approximationEqual, compareDates, equalNumbOrString, isValidDate } from '@utils';
import {
  ChangeEvent,
  Dispatch,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { EnumTypeForList } from '@interfaces';
import isEqual from 'lodash/isEqual';
import { PhoneNumberUtil } from 'google-libphonenumber';

export interface StringFieldModel {
  value: string;
  setValue: Dispatch<React.SetStateAction<string>>;
  handleChange: (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  isValid: boolean;
  validate: (eventValue?: string) => boolean;
  floatValue: number;
  setAsFloat: (value: number) => void;
  changeNavigationBlockContext: (value: boolean) => void;
  validationRule: (value: string) => boolean;
  errorTip: string;
  setErrorTip: Dispatch<React.SetStateAction<string>>;
  hasBorder: boolean;
  setBorder: Dispatch<React.SetStateAction<boolean>>;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  isChanged: boolean;
  initValue: string;
}

interface StringFieldProp {
  blockNavigationKey?: string;
  initValue: string;
  validationRule?: (value: string) => boolean;
  validateOnChange?: boolean;
  initError?: string;
  withProgressCheck?: boolean;
}

// TODO: provide useNumberFields for logic with blocking and change

export const useStringFieldModel = (props?: StringFieldProp): StringFieldModel => {
  const { setBlockedBy } = useContext(EngineContext);

  const [value, setValue] = useState<string>(props.initValue);
  const [isValid, setValid] = useState<boolean>(true);
  const [errorTip, setErrorTip] = useState<string>(props.initError || '');

  useEffect(() => {
    if (value !== props.initValue && props.validateOnChange) validate();
  }, [value]);

  useEffect(() => {
    if (props?.withProgressCheck && !equalNumbOrString(props.initValue, value)) {
      setValue(props.initValue || '');
    }
  }, [props?.withProgressCheck, props.initValue]);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setValue(e.target.value);
    setValid(props?.validationRule ? props.validationRule(e.target.value) : true);
    if (!approximationEqual(+props.initValue, +e.target.value)) {
      changeNavigationBlockContext(true);
    }
  };

  const validate = useCallback(
    (eventValue = '') => {
      const isValid = !props?.validationRule || props.validationRule(eventValue || value);
      setValid(isValid);
      return isValid;
    },
    [value, props.validationRule],
  );

  const changeNavigationBlockContext = useCallback(
    (isBlock: boolean) => {
      if (!props?.blockNavigationKey) return null;

      setBlockedBy((blocked) => ({
        ...blocked,
        [props?.blockNavigationKey]: isBlock,
      }));
    },
    [props?.blockNavigationKey],
  );

  const [hasBorder, setBorder] = useState(false);

  const isChanged = useMemo(() => value !== props.initValue, [value, props.initValue]);

  return {
    value,
    setValue,
    handleChange,
    isValid,
    validate,
    floatValue: parseFloat(value || '0'),
    setAsFloat: (value: number) => setValue(value.toString()),
    changeNavigationBlockContext,
    validationRule: props.validationRule,
    errorTip,
    setErrorTip,
    hasBorder,
    setBorder,
    setValid,
    isChanged,
    initValue: props.initValue,
  };
};

export interface DateFieldModel {
  value: Date;
  setValue: Dispatch<React.SetStateAction<Date>>;
  handleChangePicker: (e: Date | null) => void;
  isValid: boolean;
  validate: () => boolean;
  changeNavigationBlockContext: (value: boolean) => void;
  validationRule?: (value: Date | null) => {
    value: boolean;
    reason: string;
  };
  validationError?: string;
  isChanged: boolean;
  initValue: Date | null;
}

interface DateFieldProp {
  blockNavigationKey?: string;
  initValue?: Date | null;
  validationRule?: (value: Date | null) => {
    value: boolean;
    reason: string;
  };
  validateOnChange?: boolean;
}

export const useDateFieldModel = ({
  initValue = null,
  validationRule,
  blockNavigationKey,
  validateOnChange = true,
}: DateFieldProp): DateFieldModel => {
  const { setBlockedBy } = useContext(EngineContext);
  const [value, setValue] = useState(initValue);
  const [isValid, setValid] = useState<boolean>(true);
  const [validationError, setValidationError] = useState<string>('');

  const handleChangePicker = (date: Date | null) => {
    setValue(date);
  };

  const changeNavigationBlockContext = useCallback(
    (isBlock: boolean) => {
      if (!blockNavigationKey) return null;

      setBlockedBy((blocked) => ({
        ...blocked,
        [blockNavigationKey]: isBlock,
      }));
    },
    [blockNavigationKey],
  );

  const validate = useCallback(() => {
    const isValid = validationRule ? validationRule(value)?.value : isValidDate(value).value;
    setValid(isValid);
    setValidationError(validationRule ? validationRule(value)?.reason : isValidDate(value).reason);
    return isValid;
  }, [value, validationRule]);

  useEffect(() => {
    if (value !== initValue) {
      if (validateOnChange) validate();
      changeNavigationBlockContext(true);
    } else {
      setValid(true);
    }
    if (!value) {
      changeNavigationBlockContext(true);
    }
  }, [value]);

  const isChanged = useMemo(() => {
    return compareDates(value, initValue) !== 0;
  }, [value, initValue]);

  return {
    value,
    setValue,
    handleChangePicker,
    isValid,
    validate,
    changeNavigationBlockContext,
    validationRule,
    validationError,
    isChanged,
    initValue,
  };
};

// for dropdowns with predefined list of object values
export interface DropdownFieldModel {
  value: EnumTypeForList;
  setValue: Dispatch<React.SetStateAction<EnumTypeForList>>;
  isValid: boolean;
  validate: () => boolean;
  validationRule: (value: EnumTypeForList | null) => boolean;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  initValue: EnumTypeForList | null;
  setInitValue: Dispatch<React.SetStateAction<EnumTypeForList | null>>;
  isChanged: boolean;
}
interface DropdownFieldProp {
  initValue: EnumTypeForList | null;
  validateOnChange?: boolean;
  validationRule?: (value: EnumTypeForList | null) => boolean;
}

export const useDropdownFieldModel = ({
  validateOnChange = true,
  validationRule,
  ...props
}: DropdownFieldProp): DropdownFieldModel => {
  const [initValue, setInitValue] = useState<EnumTypeForList | null>(props.initValue);
  const [value, setValue] = useState<EnumTypeForList>(props.initValue);
  const [isValid, setValid] = useState<boolean>(true);

  useEffect(() => {
    if (props.initValue !== initValue) {
      setValue(initValue);
    }
  }, [props.initValue, initValue]);

  useEffect(() => {
    if (value !== initValue && validateOnChange) validate();
  }, [value]);

  const validate = useCallback(() => {
    const isValid = validationRule ? validationRule(value) : true;
    setValid(isValid);
    return isValid;
  }, [value, validationRule]);

  const isChanged = useMemo(() => !isEqual(value, initValue), [value, initValue]);

  return {
    value,
    setValue,
    isValid,
    validate,
    validationRule,
    setValid,
    initValue,
    setInitValue,
    isChanged,
  };
};

// Phone input section
type PhoneValue = string | undefined;

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone: string) => {
  try {
    const number = phoneUtil.parseAndKeepRawInput(phone, 'US');
    return phoneUtil.isValidNumberForRegion(number, 'US');
  } catch (error) {
    return false;
  }
};

export interface PhoneFieldModel {
  value: PhoneValue;
  setValue: (value: PhoneValue) => void;
  handleChange: (newValue: PhoneValue) => void;
  isValid: boolean;
  validate: (eventValue?: string) => boolean;
  setValid: Dispatch<React.SetStateAction<boolean>>;
  isChanged: boolean;
  valueToSave: string | null;
}

interface PhoneFieldProps {
  initValue?: string;
  isRequired?: boolean;
}

export const usePhoneFieldModel = (props: PhoneFieldProps = {}): PhoneFieldModel => {
  const [value, setValue] = useState<PhoneValue>(props.initValue || '');
  const [isValid, setValid] = useState<boolean>(true);
  const [hasValidated, setHasValidated] = useState<boolean>(false); // Track if validation has been called

  const isRequired = props.isRequired ?? false;

  const handleChange = (newValue) => {
    setValue(newValue);
    if (hasValidated) {
      validate(newValue);
    }
  };

  const validate = useCallback(
    (valueToValidate: string = value) => {
      setHasValidated(true); // Mark that validation has been called
      const isValid =
        !isRequired && !valueToValidate?.replace('+1', '') ? true : isPhoneValid(valueToValidate);
      setValid(isValid);
      return isValid;
    },
    [value, isRequired],
  );

  const isChanged = useMemo(() => value !== props.initValue, [value, props.initValue]);

  const valueToSave = useMemo(() => {
    return value?.replace('+1', '') ? value : null;
  }, [value]);

  return {
    value,
    setValue,
    handleChange,
    isValid,
    validate,
    setValid,
    isChanged,
    valueToSave,
  };
};
