import { useMemo } from 'react';
import { ICompany, ITeam, QueryNamesEnums } from '@interfaces';
import { getMyCompany, getProjectTeams, getProjectTeamsWithQuery } from '@globalService';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import find from 'lodash/find';
import last from 'lodash/last';
import { getBorrower, getCustomer, getInvestor } from '@utils';
import { useLaunchDarklyFlags } from '@context';

interface ControllerInterface {
  companyTeamsWithoutCurrentUserTeam: ITeam[];
  nextApprovalLevelTeam: ITeam;
  teamsList: ITeam[];
  teamsIsLoading: boolean;
  projectBorrowerTeam: ITeam;
  projectCustomerCompany: ICompany;
  projectInvestorCompany: ICompany;
}

export const useProjectTeamsAndCompanies = ({
  currentReviewerTeam,
  prId,
}: {
  currentReviewerTeam?: Partial<ITeam>;
  prId?: string;
}): ControllerInterface => {
  const flags = useLaunchDarklyFlags();
  const { projectId } = useParams();
  const companyQuery = useQuery<ICompany, Error>(
    [QueryNamesEnums.GET_MY_COMPANY],
    getMyCompany.bind(this),
  );

  const {
    data: { results: userCompanyTeamsList = [] } = {},
    isLoading: userCompanyTeamsIsLoading,
  } = useQuery<{ results: ITeam[] }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_TEAMS,
      { companyId: companyQuery.data?.id, projectId: projectId || prId },
    ],
    getProjectTeams.bind(this, { projectId: projectId || prId, companyId: companyQuery.data?.id }),
    { enabled: Boolean((projectId || prId) && companyQuery.data?.id) },
  );

  const { data: { results: projectTeamsList = [] } = {}, isLoading: projectTeamsisLoading } =
    useQuery<{ results: ITeam[] }, Error>(
      [QueryNamesEnums.GET_PROJECT_TEAMS, { projectId: projectId || prId, companyId: null }],
      getProjectTeamsWithQuery.bind(this, {
        projectId: projectId || prId,
      }),
      { enabled: Boolean(projectId || prId) },
    );

  const {
    data: { results: projectApprovalTeams = [] } = {},
    isLoading: projectApprovalTeamsisLoading,
  } = useQuery<{ results: ITeam[] }, Error>(
    [
      QueryNamesEnums.GET_PROJECT_TEAMS,
      {
        projectId: projectId || prId,
        newFlow: flags?.['ENG_8495_new_approval_flow'],
      },
    ],
    getProjectTeamsWithQuery.bind(this, {
      projectId: projectId || prId,
      query: 'exclude_approval_level=0,1',
    }),
    { enabled: Boolean(projectId || prId) && flags?.['ENG_8495_new_approval_flow'] },
  );

  const companyTeamsWithoutCurrentUserTeam = useMemo(
    () =>
      (flags?.['ENG_8495_new_approval_flow'] ? projectApprovalTeams : userCompanyTeamsList)
        ?.filter(
          (o) => o.id !== currentReviewerTeam?.id && o?.project_team_enrollment?.approval_level > 0,
        )
        .sort(
          (a, b) =>
            a.project_team_enrollment?.approval_level - b.project_team_enrollment?.approval_level,
        ),
    [userCompanyTeamsList, currentReviewerTeam, flags, projectApprovalTeams],
  );

  const currentTeamApprovalLevel = useMemo(
    () =>
      userCompanyTeamsList.find((team) => team.id === currentReviewerTeam?.id)
        ?.project_team_enrollment?.approval_level || 1,
    [userCompanyTeamsList, currentReviewerTeam],
  );

  const nextApprovalLevelTeam = useMemo(
    () =>
      find(
        companyTeamsWithoutCurrentUserTeam,
        (team) => team.project_team_enrollment.approval_level - currentTeamApprovalLevel === 1,
      ) || last(companyTeamsWithoutCurrentUserTeam),
    [companyTeamsWithoutCurrentUserTeam, currentTeamApprovalLevel],
  );

  const filteredTeamList = useMemo(
    () =>
      (flags?.['ENG_8495_new_approval_flow'] ? projectApprovalTeams : projectTeamsList).filter(
        (team) => team?.project_team_enrollment?.approval_level > 0,
      ),
    [projectTeamsList, projectApprovalTeams, flags?.['ENG_8495_new_approval_flow']],
  );

  const projectBorrowerTeam = useMemo(() => getBorrower(projectTeamsList), [projectTeamsList]);
  const projectCustomerCompany = useMemo(
    () => getCustomer(projectTeamsList)?.company,
    [projectTeamsList],
  );

  const projectInvestorCompany = useMemo(
    () => getInvestor(projectTeamsList)?.company,
    [projectTeamsList],
  );

  return {
    companyTeamsWithoutCurrentUserTeam,
    nextApprovalLevelTeam,
    teamsList: filteredTeamList,
    teamsIsLoading:
      userCompanyTeamsIsLoading || projectTeamsisLoading || projectApprovalTeamsisLoading,
    projectBorrowerTeam,
    projectCustomerCompany,
    projectInvestorCompany,
  };
};
