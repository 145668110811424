import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Skeleton, Tooltip, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from './common';
import { currencyFormatter, useBlockerCell } from '@utils';
import { CommonRowType, ColumnV2Width, IMilestoneTotal } from '@interfaces';
import { TableContext } from '../controller';
import { useDebounceInput, useStringFieldModel } from '@models';
import { TextInputWithTooltip } from '@components';

const Cell: FC<{ row: CommonRowType }> = ({ row }) => {
  const isBlocked = useBlockerCell({ milestoneId: row.id, key: 'retainage_release_approved' });
  const { apiUpdate, source, getLineItemError } = useContext(TableContext);
  const error = getLineItemError(row.id, 'retainage_release_approved');

  const retainage_release_approved = useStringFieldModel({
    initError: error,
    initValue: (row['retainage_release_approved'] || '').toString(),
    withProgressCheck: true,
    blockNavigationKey: 'retainage_release_approved',
  });

  const isEditable = useMemo(
    () => row.activeToEdit && row.retainage_rate && row.is_retainage_release_approved_editable,
    [row],
  );

  const effect = useCallback(
    (props) => {
      +retainage_release_approved.floatValue !== +row['retainage_release_approved'] &&
        apiUpdate({
          milestoneId: row.id,
          value: retainage_release_approved.floatValue,
          name: 'retainage_release_approved',
          ...props,
        });
    },
    [retainage_release_approved.floatValue, row['retainage_release_approved']],
  );

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: retainage_release_approved.handleChange,
    afterEffect: () => retainage_release_approved.changeNavigationBlockContext(false),
  });

  if (isBlocked) return <Skeleton width={'100%'} />;
  return isEditable ? (
    <TextInputWithTooltip
      sx={{ width: '100%', maxWidth: 400 }}
      value={retainage_release_approved.value}
      onBlur={onBlur}
      onChange={onChange}
      type="currency"
      error={Boolean(error)}
      errorTip={error}
      dataTestName={`${source}__body__retainage_release_approved__index_${row?.project_milestone?.index}`}
    />
  ) : (
    <Tooltip title={`Retainage can only be requested for completed line items.`}>
      <Typography
        variant="body3"
        data-cy={`${source}__body__retainage_release_approved__index_${row?.project_milestone?.index}`}
      >
        {currencyFormatter(row.retainage_release_approved, '-')}
      </Typography>
    </Tooltip>
  );
};

const Footer: FC<{ row: IMilestoneTotal }> = ({ row }) => {
  const { source } = useContext(TableContext);

  return (
    <Typography
      variant="body3SemiBold"
      data-cy={`${source}__footer__retainage_release_approved__value`}
    >
      {currencyFormatter(row.retainage_release_approved || 0)}
    </Typography>
  );
};

const retainageReleaseApproved: MilestoneListColumnType = {
  name: 'retainage_release_approved',
  columnText: 'Approved retainage release ($)',
  isEditable: true,
  renderCell: ({ row }) => <Cell row={row} />,
  Footer: (row) => <Footer row={row} />,
  minWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MIN,
  maxWidth: (activeToEdit = false) =>
    activeToEdit ? ColumnV2Width.INPUT : ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText tooltipText="Approved retainage release ($)">
      <ColumnLabel>Approved retainage</ColumnLabel>
      <ColumnLabel>release ($)</ColumnLabel>
    </HeaderText>
  ),
};

export default retainageReleaseApproved;
