import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { isRequestApproved } from '@utils';
import { IActivityLogItem, IDrawRequest, QueryNamesEnums } from '@interfaces';
import { getDrawRequest, getDrawRequestAuditLog } from '@globalService';
import { useLaunchDarklyFlags } from '@context';

export const useApproveSummary = ({ drawRequestId, projectId }) => {
  const flags = useLaunchDarklyFlags();

  const drawRequestQuery = useQuery<IDrawRequest, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST, { projectId, drawRequestId }],
    getDrawRequest.bind(this, { projectId, drawRequestId }),
    { enabled: Boolean(drawRequestId && projectId) },
  );

  const drawRequest = useMemo(() => drawRequestQuery.data, [drawRequestQuery.data]);

  const drawRequestAuditLogQuery = useQuery<{ results: IActivityLogItem[] }, Error>(
    [QueryNamesEnums.GET_DRAW_REQUEST_AUDIT_LOG, { drawRequestId }],
    getDrawRequestAuditLog.bind(this, drawRequestId),
    { enabled: Boolean(drawRequest?.is_on_hold) },
  );

  const drawRequestOnHoldText = useMemo(() => {
    if (drawRequest?.is_on_hold) {
      const onHoldAuditLogItem = drawRequestAuditLogQuery.data?.results.find(
        (item) => item.type === 'DRAW_REQUEST_IS_ON_HOLD_CHANGED',
      );
      if (onHoldAuditLogItem?.user) {
        return `This draw was put on hold by ${onHoldAuditLogItem.user.first_name} ${onHoldAuditLogItem.user.last_name}.`;
      }
      return 'This draw was put on hold.';
    }
    return '';
  }, [drawRequest?.is_on_hold, drawRequestAuditLogQuery.data?.results]);

  const isApprovedRequest = useMemo(
    () => isRequestApproved(drawRequest?.status),
    [drawRequest?.status],
  );

  const amountToDisburse = useMemo(() => {
    return flags?.['ENG_9698_funding_sources_usage_in_DR_metrics']
      ? drawRequest?.total_transactable_amount || 0
      : drawRequest?.construction_holdback_with_fees || 0;
  }, [drawRequest, flags]);

  return {
    drawRequest,
    isApprovedRequest,
    amountToDisburseIsLoading: drawRequestQuery?.isLoading,
    amountToDisburse,
    drawRequestOnHoldText,
  };
};
