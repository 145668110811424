import { PermissionNamesEnums, QueryNamesEnums } from '@interfaces';
import { FilterObject } from '../interface';

export const inspectionsListAgencyFilter: FilterObject = {
  title: 'Inspection provider',
  filterKey: 'service_agency',
  permissionKey: PermissionNamesEnums.INSPECTIONS_VIEW,
  needToUpdateUrl: true,
  needToUpdateSetting: true,
  getDataParamsPaginated: (pagination, q, skip) => ({
    type: QueryNamesEnums.GET_INSPECTION_AGENCIES,
    keys: ['id', 'display_name'],
    args: { pagination, q },
    options: {
      skip,
      strictSerialize: (data) =>
        data.map((item) => ({
          value: item.display_name,
          label: item.display_name,
        })),
    },
  }),
  cypressSelector: 'filters__inspections_list_agency',
  withPagination: true,
};
