import React, { FC } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';

import { currencyFormatter, getValueColor, percentFormatter, useBlockerFooter } from '@utils';
import { useExpandedState } from '@hooks';
import { LabelAndValue, LabelAndValueWithPreload, ProgressWithArrow, StyledBox } from '@components';
import { WarningIcon, BudgetReviewIcon } from '@svgAsComponents';
import { REQUEST_SUMMARY_ERROR_TEXTS } from '@constants';
import { IDrawRequest, ILoanInfo, TableKeyEnum, ValueTypeEnum } from '@interfaces';
import { colors } from '@theme';
import { RetainageBalanceToDate } from '../../../project/components/RequestSummary/subcomponents';

const SubmissionSummary: FC<{
  request: IDrawRequest;
  isDrawRequest: boolean;
  isReallocateComplete: boolean;
  projectLoan: ILoanInfo;
  retainageRate?: number;
  isReallocationAllowed?: boolean;
  canBeCollapsed?: boolean;
}> = ({
  request,
  isDrawRequest,
  isReallocateComplete,
  projectLoan,
  retainageRate,
  isReallocationAllowed,
  canBeCollapsed = false,
}) => {
  const isUpdating = useBlockerFooter();
  const {
    requested_budget_change,
    requested_amount,
    balance_to_finish,
    revised_estimate,
    previous_lender_allowance_rate,
    approved_amount_cumulative,
    requested_revised_estimate,
    retainage_requested_amount_holdback,
    retainage_release_requested,
    retainage_balance_to_date,
    previous_retainage_balance_to_date,
  } = request?.totals?.all || {};
  const { requested_reallocation, requested_reallocation_rate } = request || {};
  const { expanded, getExpandButton } = useExpandedState({
    tableKey: TableKeyEnum.SUBMISSION,
    initialState: true,
  });
  const valueErrorText = REQUEST_SUMMARY_ERROR_TEXTS.REQUESTED;
  const balanceToFinishNextValue = balance_to_finish - requested_amount + requested_budget_change;

  if (expanded)
    return (
      <Stack direction={{ lg: 'row', md: 'column' }} spacing={1}>
        <Stack spacing={1} sx={{ minWidth: '20%' }}>
          <StyledBox sx={{ flexGrow: 1 }}>
            {isDrawRequest ? (
              <LabelAndValueWithPreload
                isLoading={isUpdating}
                color={getValueColor({
                  isInReview: Boolean(requested_amount),
                  isError: requested_amount < 0,
                })}
                valueIcon={requested_amount < 0 ? <WarningIcon size={14} /> : null}
                valueIconTooltip={
                  requested_amount < 0 ? "Requested amount can't be negative" : null
                }
                valueIconTooltipDataTestName="submission__summary__requested_amount__tooltip_text"
                label="Draw amount"
                text={currencyFormatter(requested_amount, '-')}
                textDataTestName="submission__summary__requested_amount"
                icon={
                  request?.is_lump_sum_request ? (
                    <span data-cy="submission__summary__lump_sum_icon">
                      <BudgetReviewIcon size={24} />
                    </span>
                  ) : null
                }
                iconTooltip={request?.is_lump_sum_request ? 'Lump sum request' : ''}
              />
            ) : (
              <Stack flex={1} spacing={1}>
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  label="Reallocations"
                  text={`${currencyFormatter(requested_reallocation, '-')} / ${percentFormatter({
                    value: requested_reallocation_rate,
                  })}`}
                  textDataTestName={'submission__summary__reallocation__value'}
                />
              </Stack>
            )}
            {Boolean(retainageRate) && (
              <>
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  color={getValueColor({
                    isInReview: Boolean(retainage_requested_amount_holdback),
                    isError: retainage_requested_amount_holdback < 0,
                  })}
                  label="Retainage heldback for this draw"
                  text={currencyFormatter(-1 * retainage_requested_amount_holdback, '-')}
                  textDataTestName="submission__summary__retainage_holback"
                />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  color={getValueColor({ isInReview: Boolean(retainage_release_requested) })}
                  label="Requested retainage release"
                  text={currencyFormatter(retainage_release_requested, '-')}
                  textDataTestName="submission__summary__retainage_requested"
                />
                <Divider sx={{ my: 1 }} />
                <LabelAndValueWithPreload
                  isLoading={isUpdating}
                  color={getValueColor({ isInReview: true })}
                  label="Net disbursement"
                  text={currencyFormatter(
                    requested_amount -
                      retainage_requested_amount_holdback +
                      retainage_release_requested,
                    '-',
                  )}
                  textDataTestName="submission__summary__net_disbursement"
                />
              </>
            )}
          </StyledBox>
          {isReallocationAllowed && (
            <StyledBox sx={{ flexGrow: 1 }}>
              <RemainingReallocationBalance
                isReallocateComplete={isReallocateComplete}
                requested_budget_change={requested_budget_change}
                valueErrorText={valueErrorText}
                isUpdating={isUpdating}
              />
            </StyledBox>
          )}
        </Stack>

        <StyledBox sx={{ flexGrow: 1 }}>
          <Stack flex={1} spacing={1}>
            <LabelAndValue label="" text="Pre ➝ Post" color={colors.text.medium} />
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              label="Revised scheduled values"
              valueIcon={!isReallocateComplete ? <WarningIcon size={14} /> : null}
              valueIconTooltip={valueErrorText}
              valueIconDataTestName={
                'request__submission__summary__revised_scheduled_values__error_icon'
              }
            >
              <ProgressWithArrow
                prevValue={currencyFormatter(revised_estimate, '-')}
                nextValue={currencyFormatter(requested_revised_estimate, '-')}
                showProgress
                nextValueDataTestName={
                  'request__submission__summary__revised_scheduled_values__value'
                }
                nextValueType={
                  !isReallocateComplete
                    ? { [ValueTypeEnum.IS_ERROR]: true }
                    : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                }
              />
            </LabelAndValueWithPreload>
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              label="Original borrower equity"
              text={currencyFormatter(projectLoan?.original_borrower_equity, '-')}
            />
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              label="Construction holdback"
              text={currencyFormatter(projectLoan?.construction_holdback, '-')}
            />
          </Stack>
        </StyledBox>

        <StyledBox sx={{ flexGrow: 1 }}>
          <Stack direction="row">
            <Stack flex={1} spacing={1}>
              <LabelAndValue label="" text="Pre ➝ Post" color={colors.text.medium} />
              <LabelAndValueWithPreload isLoading={isUpdating} label="Approved to date">
                <ProgressWithArrow
                  prevValue={`${currencyFormatter(
                    approved_amount_cumulative,
                    '-',
                  )} / ${percentFormatter({
                    value: previous_lender_allowance_rate,
                  })}`}
                  nextValue={'-'}
                  showProgress
                  getTextStyleForActive={true}
                />
              </LabelAndValueWithPreload>
              <LabelAndValueWithPreload
                isLoading={isUpdating}
                label="Balance to finish"
                valueIcon={balanceToFinishNextValue < 0 ? <WarningIcon size={14} /> : null}
                valueIconTooltip="Requested amount exceeds your balance to finish."
              >
                <ProgressWithArrow
                  prevValue={currencyFormatter(balance_to_finish, '-')}
                  nextValueType={
                    balanceToFinishNextValue < 0
                      ? { [ValueTypeEnum.IS_ERROR]: true }
                      : { [ValueTypeEnum.IS_IN_REVIEW]: true }
                  }
                  nextValue={currencyFormatter(balanceToFinishNextValue, '-')}
                  showProgress
                  getTextStyleForActive={true}
                  nextValueDataTestName={'request__submission__summary__balance_to_finish__value'}
                />
              </LabelAndValueWithPreload>
              {Boolean(retainageRate) && (
                <RetainageBalanceToDate
                  isUpdating={isUpdating}
                  totals={{
                    retainage_balance_to_date,
                    previous_retainage_balance_to_date,
                  }}
                  retainageRate={retainageRate}
                  isInReview={Boolean(requested_amount)}
                />
              )}
            </Stack>
            {canBeCollapsed && (
              <Box data-tour="expand_summary_button" sx={{ pt: 1, ml: 2 }}>
                {getExpandButton()}
              </Box>
            )}
          </Stack>
        </StyledBox>
      </Stack>
    );

  return (
    <Stack direction={{ lg: 'row', md: 'column' }} spacing={1}>
      <StyledBox sx={{ flexGrow: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-start"
          sx={{ height: '100%' }}
        >
          <Typography variant="h4" sx={{ mr: '4px' }}>{`Active ${
            isDrawRequest ? 'draw' : 'change'
          } #${request.counter_per_request_type}`}</Typography>
          <Typography variant="body3">requested</Typography>
        </Stack>
      </StyledBox>

      <StyledBox sx={{ flexGrow: 1 }}>
        {isDrawRequest ? (
          <Stack flex={1} spacing={1}>
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              color={getValueColor({ isInReview: Boolean(requested_amount) })}
              label="Draw amount"
              text={currencyFormatter(requested_amount, '-')}
            />
          </Stack>
        ) : (
          <Stack flex={1} spacing={1}>
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              label="Reallocations"
              text={`${currencyFormatter(requested_reallocation, '-')} / ${percentFormatter({
                value: requested_reallocation_rate,
              })}`}
              textDataTestName={'submission__summary__reallocation__value'}
            />
          </Stack>
        )}
      </StyledBox>

      <StyledBox sx={{ flexGrow: 1 }}>
        <Stack direction="row">
          <Stack flex={1} spacing={1}>
            <LabelAndValueWithPreload
              isLoading={isUpdating}
              color={getValueColor({
                isInReview: Boolean(requested_amount),
                isError: balanceToFinishNextValue < 0,
              })}
              label="Balance to finish"
              text={currencyFormatter(
                balance_to_finish - requested_amount + requested_budget_change,
                '-',
              )}
              textDataTestName={'request__submission__summary__balance_to_finish__value'}
              valueIcon={balanceToFinishNextValue < 0 ? <WarningIcon size={14} /> : null}
              valueIconTooltip="Requested amount exceeds your balance to finish."
            />
            {isReallocationAllowed && (
              <RemainingReallocationBalance
                requested_budget_change={requested_budget_change}
                valueErrorText={valueErrorText}
                isUpdating={isUpdating}
                isReallocateComplete={isReallocateComplete}
              />
            )}
          </Stack>
          <Box sx={{ mt: 1 }}>{getExpandButton()}</Box>
        </Stack>
      </StyledBox>
    </Stack>
  );
};

export default SubmissionSummary;

const RemainingReallocationBalance = ({
  requested_budget_change,
  valueErrorText,
  isUpdating,
  isReallocateComplete,
}) => {
  return (
    <LabelAndValueWithPreload
      color={getValueColor({ isError: !isReallocateComplete })}
      valueIcon={!isReallocateComplete ? <WarningIcon size={14} /> : null}
      valueIconTooltip={valueErrorText}
      isLoading={isUpdating}
      label="Reallocation balance"
      text={currencyFormatter(requested_budget_change, '-')}
      textDataTestName={'request__submission__summary__reallocation_balance__value'}
      valueIconDataTestName={'request__submission__summary__reallocation_balance__error_icon'}
    />
  );
};
