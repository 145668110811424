import { getPaginationString, getParamString } from '@utils';
import { IApiPath } from '@interfaces';
import {
  ArgumentsProjectsCompaniesApi,
  ArgumentsProjectsUserApi,
  ArgumentsProjectsTeamsApi,
  ArgumentsProjectsRolesApi,
} from '../../args';

// TODO think about sorting
export const projectsCompaniesApi = ({ pagination, ...params }: ArgumentsProjectsCompaniesApi) =>
  `projects/companies/?sorting=name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsUsersApi = ({ pagination, ...params }: ArgumentsProjectsUserApi) =>
  `projects/users/?sorting=first_name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsTeamsApi = ({ pagination, ...params }: ArgumentsProjectsTeamsApi) =>
  `projects/teams/?sorting=name&${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsInspectionAgenciesApi = ({ pagination, ...params }: IApiPath) =>
  `projects/inspections/agencies/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsServiceAgenciesApi = ({ pagination, ...params }: IApiPath) =>
  `projects/service_orders/agencies/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsTypesApi = ({ pagination, ...params }: IApiPath) =>
  `projects/types/?${getParamString(params)}${getPaginationString(pagination)}`;

export const projectsRolesApi = ({
  pagination,
  project_id,
  ...params
}: ArgumentsProjectsRolesApi) =>
  `projects/${project_id}/roles/?${getParamString(params)}${getPaginationString(pagination)}`;
