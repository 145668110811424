import React, { FC } from 'react';
import { Typography, Stack } from '@mui/material';
import { useMutation, useQueryClient } from 'react-query';

import {
  IDrawRequest,
  PopupTypeEnum,
  PostDrawRequestReviewParam,
  QueryNamesEnums,
  RequestReviewStatusEnum,
} from '@interfaces';
import { postDrawRequestReview } from '@globalService';
import { useSafeSnackbar } from '@hooks';
import { ButtonWithTooltip, ConfirmationModal } from '@components';

const CompleteDRButton: FC<{
  drawRequest: IDrawRequest;
  isApproveDisabled: boolean;
  projectId: string;
  tooltipText: string;
}> = ({ drawRequest, isApproveDisabled, projectId, tooltipText }) => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const [confirmationModalData, setConfirmationModalData] = React.useState<{
    isOpen: boolean;
    title: string;
    text: React.ReactNode;
    confirmCallback?: () => void;
  }>({
    isOpen: false,
    title: '',
    text: '',
    confirmCallback: () => {},
  });

  const completeDR = useMutation<Response, Error, PostDrawRequestReviewParam>(
    postDrawRequestReview,
    {
      onSuccess: () => {
        //TODO refactor when adding invalidating groups
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_DRAW_REQUEST_LIST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_PROGRESS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_PROJECT_FUNDS);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_FOR_APPROVAL);
        queryClient.invalidateQueries(QueryNamesEnums.GET_DRAW_REQUEST_LIST);
        queryClient.invalidateQueries([
          QueryNamesEnums.GET_PROJECT_MILESTONES_COLUMNS,
          { projectId },
        ]);
      },
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    },
  );

  const completeRequest = () => {
    completeDR.mutateAsync({
      id: projectId,
      drawRequest: drawRequest?.id,
      status: RequestReviewStatusEnum.APPROVE,
    });
  };

  const handleSubmitClick = () => {
    // Check 1: Zero amount confirmation
    const hasZeroAmount =
      !drawRequest?.totals?.current?.approved_amount && !drawRequest?.approved_reallocation;
    // Check 2: Missing inspection results
    const hasMissingInspection =
      drawRequest?.inspector_allowance_rate == drawRequest?.inspector_allowance_rate_before;

    if (hasZeroAmount) {
      setConfirmationModalData({
        isOpen: true,
        title: 'Confirmation',
        text: (
          <Stack spacing={1}>
            <Typography variant="body2">You are approving the request with</Typography>
            <Typography variant="body2SemiBold">Draw amount = $0.00.</Typography>
          </Stack>
        ),
        confirmCallback: () => {
          // After first confirmation, check for missing inspection
          if (hasMissingInspection) {
            setConfirmationModalData({
              isOpen: true,
              title: 'Missing inspection results:',
              text: (
                <Stack spacing={1}>
                  <Typography variant="body2">
                    Do you want to record this historical draw without including an inspection or
                    progress percentage?
                  </Typography>
                  <Typography variant="body2SemiBold">
                    This will have a reflect in the project health score.
                  </Typography>
                </Stack>
              ),
              confirmCallback: completeRequest,
            });
          } else {
            completeRequest();
          }
        },
      });
      return;
    }

    // If only missing inspection, show that confirmation
    if (hasMissingInspection) {
      setConfirmationModalData({
        isOpen: true,
        title: 'Missing inspection results:',
        text: (
          <Stack spacing={1}>
            <Typography variant="body2">
              Do you want to record this historical draw without including an inspection or progress
              percentage?
            </Typography>
            <Typography variant="body2SemiBold">
              This will have a reflect in the project health score.
            </Typography>
          </Stack>
        ),
        confirmCallback: completeRequest,
      });
      return;
    }

    // If no conditions need confirmation, proceed directly
    completeRequest();
  };

  return (
    <>
      <ButtonWithTooltip
        variant="contained"
        color="primary"
        disabled={isApproveDisabled}
        onClick={handleSubmitClick}
        tooltipText={tooltipText}
        dataTestName="draw_request_approval_buttons_complete_historical"
        loading={completeDR.isLoading}
      >
        Submit
      </ButtonWithTooltip>
      <ConfirmationModal
        open={confirmationModalData.isOpen}
        title="Confirmation"
        text={confirmationModalData.text}
        onClose={() => setConfirmationModalData({ isOpen: false, title: '', text: '' })}
        confirmCallback={confirmationModalData.confirmCallback}
        type={PopupTypeEnum.CONFIRMATION}
        cancelButtonLabel="Continue Editing"
        confirmButtonLabel="Save"
      />
    </>
  );
};

export default CompleteDRButton;
