import React, { FC, useCallback, useContext, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { ColumnLabel, HeaderText, MilestoneListColumnType } from '../common';
import isEmpty from 'lodash/isEmpty';
import { PermissionsContext } from '@context';
import { isAllowed } from '@utils';
import { useDebounceInput, useStringFieldModel } from '@models';
import {
  ColumnAlign,
  ColumnV2Width,
  PermissionNamesEnums,
  ProductionBuildCommonRowType,
} from '@interfaces';
import { TextInputWithTooltip } from '@components';
import { TableContext } from '../../controller';

const Quantity: FC<{ row: ProductionBuildCommonRowType }> = ({ row }) => {
  const { permissions } = useContext(PermissionsContext);
  const { apiUpdate, source } = useContext(TableContext);

  const quantity = useStringFieldModel({
    initValue: (row['quantity'] || '').toString(),
    withProgressCheck: !row.isModelBuilding,
    blockNavigationKey: 'quantity',
  });
  const isEditable = useMemo(
    () =>
      row.activeToEdit &&
      !row.isModelBuilding &&
      isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
    [permissions, row],
  );

  const effect = useCallback(() => {
    if (row.quantity !== quantity.value && !isEmpty(quantity.value)) {
      apiUpdate({
        milestoneId: row.id,
        name: 'quantity',
        value: quantity.value,
        isNonNumber: false,
      });
    }
  }, [row, quantity]);

  const { onBlur, onChange } = useDebounceInput({
    effect,
    handleChange: quantity.handleChange,
    afterEffect: () => quantity.changeNavigationBlockContext(false),
  });

  return isEditable ? (
    <Stack flexDirection="row" maxWidth={220} justifyContent="space-between">
      <TextInputWithTooltip
        onBlur={onBlur}
        type="string"
        value={quantity.value}
        onChange={onChange}
        dataTestName={`${source}__body__quantity__value__index_${row.index}`}
      />
    </Stack>
  ) : (
    <Box textAlign={ColumnAlign.TEXT}>
      <Typography variant="body3">{row.quantity}</Typography>
    </Box>
  );
};

const quantity: MilestoneListColumnType = {
  name: 'quantity',
  columnText: 'Quantity',
  noBorder: true,
  isEditable: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderCell: ({ row }) => <Quantity row={row} />,
  justifyContent: 'flex-start',
  minWidth: () => ColumnV2Width.NUMBER_MIN,
  maxWidth: () => ColumnV2Width.NUMBER_MAX,
  Header: (
    <HeaderText alignItems="flex-start" tooltipText="Quantity of the item">
      <ColumnLabel>Quantity</ColumnLabel>
    </HeaderText>
  ),
};

export default quantity;
