import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { colors } from '@theme';
import {
  ContactSupportImage,
  HelpCenterImage,
  OnboardingChecklistImage,
  QuickStartImage,
} from '@svgAsComponents';
import { COMPANY_DATA } from '@constants';
import SubmitDrawImage from '@assets/submit-draw-screenshot.png';
import BudgetImage from '@assets/budget-screenshot.png';
import RequestCardImage from '@assets/request-card-screenshot.png';
import RequestStatusesImage from '@assets/request-statuses.png';
import SupportButton from './components/SupportButton';
import Article from './components/Article';
import Step from './components/Step';
import { useOnboardingSteps } from './controller';

const OnboardingDashboard: FC = () => {
  const { incompletedSteps, stepsLength, steps, progressElement, company } = useOnboardingSteps();

  return (
    <Stack spacing={2} sx={{ height: '100%', backgroundColor: colors.background.gray, padding: 3 }}>
      {Boolean(incompletedSteps) && (
        <Stack
          sx={{ backgroundColor: colors.background.white, paddingX: 2, paddingY: 4 }}
          spacing={2}
        >
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h1">
              {`${stepsLength} steps to complete your onboarding process for smoother request submissions & faster
              payments!`}
            </Typography>
            {progressElement}
          </Stack>
          <Stack direction="row" justifyContent="space-between" paddingTop={3}>
            <Stack spacing={4}>
              <Step
                title="1. Personal information"
                link="/profile/personal"
                linkText="Fill in"
                isChecked={Boolean(steps.personalInfo)}
                dataTestName="personal_info"
              />
              <Step
                title="2. Company information"
                link={`/company/${company?.id}/general`}
                linkText="Fill in"
                isChecked={Boolean(steps.companyInfo)}
                dataTestName="company_info"
              />
              <Step
                title="3. First project"
                link="/projects"
                linkText="Review"
                isChecked={Boolean(steps.projectInfo)}
                dataTestName="project_info"
              />
            </Stack>
            <OnboardingChecklistImage />
          </Stack>
        </Stack>
      )}
      <Stack sx={{ backgroundColor: colors.background.white, padding: 2 }}>
        <Typography variant="h4">Support</Typography>
        <Stack direction="row" spacing={2} sx={{ flexWrap: 'wrap' }} justifyContent="space-between">
          <SupportButton
            image={<ContactSupportImage />}
            text="Contact support"
            onClick={() => {
              if (!window.zE) return;
              const conversationBadge = document.querySelector('#conversation-badge');
              const action = conversationBadge.getAttribute('data-btn-action');
              window.zE('messenger', action);
            }}
            dataTestName="contact_support"
          />
          <SupportButton
            image={<HelpCenterImage />}
            text="Help center"
            link={COMPANY_DATA.HELP_CENTER_LINK}
            dataTestName="help_center"
          />
          <SupportButton
            image={<QuickStartImage />}
            text="Quick start guide"
            link={COMPANY_DATA.QUICK_GUIDE_LINK}
            dataTestName="start_guide"
          />
        </Stack>
      </Stack>
      <Stack sx={{ backgroundColor: colors.background.white, padding: 2 }}>
        <Typography variant="h4">Videos</Typography>
        <Stack
          direction="row"
          spacing={2}
          sx={{ flexWrap: 'wrap', pt: 2 }}
          justifyContent="space-between"
        >
          <Article
            image={<img src={SubmitDrawImage} style={{ width: '246px' }} alt="submit draw" />}
            dataTestName="Submit request"
            title="How to submit a Draw"
            link={COMPANY_DATA.SUBMIT_DRAW_VIDEO_LINK}
            buttonText="Watch video"
          />
          <Article
            image={<img src={BudgetImage} style={{ width: '246px' }} alt="budget" />}
            dataTestName="Change"
            title="Budget changes and Reallocating line items"
            link={COMPANY_DATA.BUDGET_GUIDE_LINK}
            buttonText="Read article"
          />
          <Article
            image={
              <Stack direction="row" alignItems="center" spacing={1}>
                <img src={RequestCardImage} style={{ width: '105px' }} alt="request card" />
                <img
                  src={RequestStatusesImage}
                  style={{ width: '75px', height: '120px' }}
                  alt="request statuses"
                />
              </Stack>
            }
            dataTestName="Draw"
            title="Understanding Draw status"
            link={COMPANY_DATA.DRAW_STATUS_GUIDE_LINK}
            buttonText="Read article"
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default OnboardingDashboard;
