import { MilestoneListColumnTypeV2 } from './common';
import { currencyFormatter, isAllowed, notAllowNegative } from '@utils';
import { PermissionNamesEnums } from '@interfaces';
import { InputBaseComponentProps } from '@mui/material';
import { GridEditInputCell } from '@mui/x-data-grid';
import React from 'react';
import { CurrencyInput } from 'src/components/NumberFormatInputs';

const originalEstimate: MilestoneListColumnTypeV2 = {
  field: 'original_estimate',
  valueFormatter: (value) => currencyFormatter(value, ''),
  headerAlign: 'right',
  headerClassName: 'number',
  cellClassName: 'number',
  headerName: 'Original scheduled value ($)',
  description: 'Original construction budget post funding',
  editableByMilestone: (row) => row.isEditableV2 && row.localNew,
  editableByPermissions: (permissions) =>
    isAllowed(PermissionNamesEnums.DRAWREQUESTS_LINE_ITEM_CREATE, permissions),
  renderEditCell: (props) => (
    <GridEditInputCell
      {...props}
      inputProps={{
        isAllowed: notAllowNegative,
      }}
      inputComponent={CurrencyInput as unknown as React.ElementType<InputBaseComponentProps>}
    />
  ),
  minWidth: 120,
};

export default originalEstimate;
