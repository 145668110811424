import React from 'react';
import { InspectionResultsV2, InspectionResultsV1 } from '@components';
import { useLaunchDarklyFlags } from '@context';

const InspectionResultIndex = () => {
  const flags = useLaunchDarklyFlags();
  return flags?.['ENG_9769_external_id_for_service_billing'] ? (
    <InspectionResultsV2 />
  ) : (
    <InspectionResultsV1 />
  );
};

export default InspectionResultIndex;
