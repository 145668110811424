import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  Dispatch,
  SetStateAction,
} from 'react';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  StringFieldModel,
  useStringFieldModel,
  usePhoneFieldModel,
  PhoneFieldModel,
} from '@models';
import { addUserToTeams, getCompanyTeams, findUserByEmail } from '@globalService';
import {
  AddUserToTeamsPayload,
  EnumTypeForList,
  ErrorDual,
  ITeam,
  IUser,
  QueryNamesEnums,
} from '@interfaces';
import { checkIsOwner, getErrorText, getTeamRole, regexValidation } from '@utils';
import { AuthContext } from '@context';
import { useSafeSnackbar } from '@hooks';

export type ControllerInterface = {
  email: StringFieldModel;
  firstName: StringFieldModel;
  lastName: StringFieldModel;
  phone: PhoneFieldModel;
  handleAddCompanyUser: () => void;
  isSubmitting: boolean;
  teams: EnumTypeForList[];
  optionsLoading: boolean;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseCallback: () => void;
  selectedTeams: EnumTypeForList[];
  setSelectedTeams: Dispatch<SetStateAction<EnumTypeForList[]>>;
  inviteDisabled: boolean;
  isSearching: boolean;
  handleSearchEmailClick: () => void;
  foundUser: IUser | null;
  tooltipText: string;
  showAdditionalInputs: boolean;
};

export const useAddCompanyUser = ({ companyId, showFindUserButton }): ControllerInterface => {
  const { user } = useContext(AuthContext);
  const teamRole = getTeamRole(user);
  const { companyId: paramCompanyId } = useParams();

  // this query param is used to determine if response should contains
  // all company teams or only teams that has shared projects with current user company
  // to implement independent borrower teams for different lenders concept
  const is_on_shared_project = useMemo(() => Boolean(companyId), [companyId]);
  const [isOpened, setIsOpened] = useState(false);
  const { enqueueSnackbar } = useSafeSnackbar();
  const queryClient = useQueryClient();
  const companyTeamsQuery = useQuery<{ results: ITeam[] }, Error>(
    [
      QueryNamesEnums.GET_COMPANY_TEAMS,
      { companyId: paramCompanyId || companyId, is_on_shared_project },
    ],
    getCompanyTeams.bind(this, paramCompanyId || companyId, is_on_shared_project),
    { enabled: Boolean(paramCompanyId || companyId) },
  );

  const [selectedTeams, setSelectedTeams] = useState<EnumTypeForList[]>([]);

  const [isSearching, setIsSearching] = useState(false);
  const [foundUser, setFoundUser] = useState<IUser | null>(null);
  const [tooltipText, setTooltipText] = useState<string>('');
  const [showAdditionalInputs, setShowAdditionalInputs] = useState(false);

  const email = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('email', value) && Boolean(value?.trim()),
    validateOnChange: true,
  });

  const firstName = useStringFieldModel({
    initValue: '',
  });
  const lastName = useStringFieldModel({
    initValue: '',
  });
  const phone = usePhoneFieldModel({
    initValue: '',
  });

  const addCompanyUserToSeveralTeamsMutation = useMutation<
    Response,
    ErrorDual,
    AddUserToTeamsPayload
  >(addUserToTeams, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_USERS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_COMPANY_TEAMS]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES]);
      onCloseCallback();
    },
    onError: (error: ErrorDual) => {
      enqueueSnackbar(getErrorText(error), {
        variant: 'error',
      });
    },
  });

  const handleAddCompanyUser = useCallback(async () => {
    const isTeamValid = selectedTeams.length > 0;
    const isEmailValid = email.validate();
    const isPhoneValid = phone.validate();

    const isAllFieldsValid = isTeamValid && isEmailValid && isPhoneValid;
    if (!isAllFieldsValid) return;

    if (companyId) {
      // Flow for adding user to borrower company
      addCompanyUserToSeveralTeamsMutation.mutateAsync({
        companyId,
        data: {
          teams: selectedTeams.map((o) => o.id),
          user: {
            ...(foundUser
              ? { id: foundUser.id }
              : {
                  email: email.value,
                  first_name: firstName.value,
                  last_name: lastName.value,
                  phone: phone.valueToSave,
                }),
          },
        },
      });
    } else {
      // Flow for adding user to organization
      try {
        setIsSearching(true);
        const result = await findUserMutation.mutateAsync(email.value);

        const userData = result?.results?.[0] || null;
        if (userData) {
          // User already exists - show message and don't proceed
          enqueueSnackbar('User already exists in the system', {
            variant: 'warning',
          });
          setIsSearching(false);
          return;
        }

        // User doesn't exist - proceed with creation
        await addCompanyUserToSeveralTeamsMutation.mutateAsync({
          companyId: paramCompanyId,
          data: {
            teams: selectedTeams.map((o) => o.id),
            user: {
              email: email.value,
              first_name: firstName.value,
              last_name: lastName.value,
              phone: phone.valueToSave,
            },
          },
        });
      } catch (error: unknown) {
        enqueueSnackbar(getErrorText(error as ErrorDual), {
          variant: 'error',
        });
      } finally {
        setIsSearching(false);
      }
    }
  }, [paramCompanyId, companyId, email, selectedTeams, firstName, lastName, phone, foundUser]);

  const teams = useMemo(
    () =>
      companyTeamsQuery.data?.results?.map((o) => ({
        id: o.id,
        name_display: o.name,
        name: o.id,
      })) || [],
    [companyTeamsQuery.data],
  );

  const setInitialValues = useCallback(() => {
    if (teams?.length === 1 || (checkIsOwner(teamRole) && teams?.length > 1)) {
      setSelectedTeams(teams);
    } else if (teams?.length) {
      setSelectedTeams([teams[0]]);
    }

    firstName.setValue('');
    lastName.setValue('');
    phone.setValue('');

    setShowAdditionalInputs(!showFindUserButton);
    setFoundUser(null);
    setTooltipText('');
  }, [teams, showFindUserButton]);

  useEffect(() => {
    setInitialValues();

    return () => {
      setSelectedTeams([]);
    };
  }, [teams, email.value]);

  const onCloseCallback = useCallback(() => {
    setInitialValues();
    email.setValue('');
    setIsOpened(false);
  }, []);

  const findUserMutation = useMutation<{ results: IUser[] }, ErrorDual, string>(findUserByEmail, {
    onSuccess: (data) => {
      if (data && data?.results?.length > 0) {
        const user = data.results[0];
        setFoundUser(user);
        if (showFindUserButton) {
          firstName.setValue(user.first_name);
          lastName.setValue(user.last_name);
          phone.setValue(user.phone || '');
          setTooltipText('User found. The user will be added to the selected teams.');
        } else {
          setTooltipText('User found. The existing user can not be added to organization.');
        }
      } else {
        setFoundUser(null);
        if (showFindUserButton)
          setTooltipText('User not found. Please enter the details below to create a new user.');
      }
      if (showFindUserButton) setShowAdditionalInputs(true);
    },
    onError: (error) => {
      enqueueSnackbar(getErrorText(error), {
        variant: 'error',
      });
      setFoundUser(null);
      setTooltipText('An error occurred while searching for the user.');
      setShowAdditionalInputs(false);
    },
    onSettled: () => {
      setIsSearching(false);
    },
  });

  const handleSearchEmailClick = useCallback(() => {
    if (!email.value?.trim()) return;
    setIsSearching(true);
    findUserMutation.mutateAsync(email.value);
  }, [email.value]);

  return {
    email,
    firstName,
    lastName,
    phone,
    handleAddCompanyUser,
    isSubmitting: addCompanyUserToSeveralTeamsMutation.isLoading || isSearching,
    teams,
    optionsLoading: companyTeamsQuery.isLoading,
    isOpened,
    setIsOpened,
    onCloseCallback,
    selectedTeams,
    setSelectedTeams,
    inviteDisabled: !teams?.length,
    isSearching,
    handleSearchEmailClick,
    foundUser,
    tooltipText,
    showAdditionalInputs,
  };
};
