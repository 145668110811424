import { useCallback, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import {
  PhoneFieldModel,
  StringFieldModel,
  usePhoneFieldModel,
  useStringFieldModel,
} from '@models';
import { getProjectsUsersV2, updateProjectFields } from '@globalService';
import { ErrorDual, IProject, IUser, QueryNamesEnums } from '@interfaces';
import { getErrorText, regexValidation } from '@utils';
import { useParams } from 'react-router';
import { useSafeSnackbar } from '@hooks';

export type ControllerInterface = {
  email: StringFieldModel;
  firstName: StringFieldModel;
  lastName: StringFieldModel;
  phone: PhoneFieldModel;
  inviteUserCompany: () => void;
  isSubmitting: boolean;
  optionsLoading: boolean;
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  onCloseCallback: () => void;
  isSearching: boolean;
  handleSearchEmailClick: () => void;
  foundUser: IUser | null;
  tooltipText: string;
};

export const useInviteCompanyUser = ({ role }): ControllerInterface => {
  const { enqueueSnackbar } = useSafeSnackbar();
  const [isOpened, setIsOpened] = useState(false);
  const { projectId } = useParams();
  const queryClient = useQueryClient();
  const [isSearching, setIsSearching] = useState(false);
  const [foundUser, setFoundUser] = useState<IUser | null>(null);
  const [tooltipText, setTooltipText] = useState<string>('Enter the email address.');

  const email = useStringFieldModel({
    initValue: '',
    validationRule: (value) => regexValidation('email', value) && Boolean(value?.trim()),
    validateOnChange: true,
  });

  const firstName = useStringFieldModel({
    initValue: '',
  });
  const lastName = useStringFieldModel({
    initValue: '',
  });
  const phone = usePhoneFieldModel({
    initValue: '',
  });

  const projectMutation = useMutation<
    Response,
    ErrorDual,
    { projectId: string; json: Partial<IProject> }
  >(updateProjectFields, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT, { projectId }]);
      queryClient.invalidateQueries([QueryNamesEnums.GET_PROJECT_COMPANIES, { projectId }]);
      queryClient.invalidateQueries([
        QueryNamesEnums.GET_PROJECT_TEAMS,
        { projectId, companyId: null },
      ]);
      onCloseCallback();
    },
    onError: (error) => {
      projectMutation.reset();
      const errorText = getErrorText(error as ErrorDual);
      setTooltipText(errorText);
    },
  });

  const inviteUserCompany = useCallback(async () => {
    const projectData = {
      companies: [
        {
          role,
          ...foundUser?.company,
        },
      ],
    } as Partial<IProject>;

    projectMutation.mutateAsync({
      projectId,
      json: {
        ...projectData,
      },
    });
  }, [projectMutation, foundUser]);

  const setInitialValues = useCallback(() => {
    setFoundUser(null);
    setTooltipText('Enter the email address.');
    email.setValue('');
    firstName.setValue('');
    lastName.setValue('');
    phone.setValue('');
  }, []);

  const onCloseCallback = useCallback(() => {
    setInitialValues();
    setIsOpened(false);
  }, []);

  const performSearch = useCallback(async () => {
    try {
      const { results } = await queryClient.fetchQuery(
        [QueryNamesEnums.GET_PROJECTS_USERS, { email: email.value }],
        () => getProjectsUsersV2({ email: email.value, team_role: role }),
      );
      if (results.length) {
        setTooltipText('Email already exists. The company will be added to the project.');
        setFoundUser(results[0]);
        firstName.setValue(results[0].first_name);
        lastName.setValue(results[0].last_name);
        phone.setValue(results[0].phone || '');
      } else {
        setFoundUser(null);
        setTooltipText('User not found');
      }
    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' });
    } finally {
      setIsSearching(false);
    }
  }, [email, queryClient, role]);

  const handleSearchEmailClick = useCallback(() => {
    if (!email.value?.trim()) return;
    setIsSearching(true);
    performSearch();
  }, [email.value]);

  return {
    email,
    inviteUserCompany,
    isSubmitting: isSearching,
    optionsLoading: false,
    isOpened,
    setIsOpened,
    onCloseCallback,
    isSearching,
    handleSearchEmailClick,
    foundUser,
    tooltipText,
    firstName,
    lastName,
    phone,
  };
};
